import React from "react";

import styles from './ArchiveItem.module.scss';

function ArchiveItem(props: ArchiveItemProps) {
    return (
        <div className={styles.archiveitem + ' ' + (props.className || '')} onClick={() => {
            props?.onClick?.();
        }}>
            <div className={styles.top}>
                <div className={styles.left}>
                    <h2>{props.title}</h2>
                    <p>{props.subtitle1}&nbsp;</p>
                    <p>{props.subtitle2}</p>
                </div>
                <div className={styles.right}>
                    <h2>{props.id}</h2>
                </div>
            </div>
            <div className={styles.bottom}>
                <div className={styles.pills}>
                    {props.pills}
                </div>
                <div className={styles.date}>
                    <p>{props.date}</p>
                </div>
                {props.locked ?
                    <div className={styles.locked}>
                        <span className="material-symbols-outlined">lock</span>
                    </div>
                : null }
            </div>
        </div>
    );
}

class ArchiveItemProps {
    public title!: string;
    public subtitle1?: string;
    public subtitle2?: string;
    public id?: string;
    public locked?: boolean = false;
    public onClick!: () => void;
    public pills?: JSX.Element[] = [];
    public date?: string;
    public className?: string;
}

export default ArchiveItem;