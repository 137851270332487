import React, { useEffect, useRef, useState } from 'react';

import styles from './SplitButton.module.scss';

function SplitButton(props: SplitButtonProps) {
    const [open, setOpen] = useState(false);

    const containerRef = useRef(null);

    useEffect(() => {
        function handleClickOutside(event: any) {
            if (containerRef.current && !(containerRef.current as HTMLElement).contains(event.target)) {
                setOpen(false);
            }
        }

        document.addEventListener("mousedown", handleClickOutside);
        return () => {
            document.removeEventListener("mousedown", handleClickOutside);
        };
    }, []);

    return (
        <div className={styles.buttoncontainer + " " + props.className} ref={containerRef}>
            <div className={styles.button}>
                <div 
                    onClick={()=>{
                        props.onClick();
                        setOpen(false);
                    }}
                    className={
                        styles.splitbutton + ' ' +
                        (props.disabled ? styles.splitbuttondisabled : '' )
                    }
                    style={{
                        borderRight: (props.altOptions?.filter(i => i != undefined)?.length ?? 0) > 0 ? undefined : "none"
                    }}
                >
                    {props.buttontext}
                </div>
                { (props.altOptions?.filter(i => i != undefined)?.length ?? 0) > 0 ?
                    <div 
                        className={styles.dropdown + ' ' + (open ? styles.dropdownopen:'') + ' ' + (props.altOptions?.filter(i => i != undefined).every(i => i.disabled == true) ? styles.arrowdisabled : '')}
                        onClick={()=>{
                            setOpen(!open);
                        }}
                    >
                        <span className="material-symbols-outlined">expand_more</span> 
                    </div>
                : null }
            </div>
            { (props.altOptions?.filter(i => i != undefined)?.length ?? 0) > 0 ?
                <div 
                    className={styles.dropdowncontent}
                    style={{
                        display:open ? "block":"none"
                    }}
                >
                    {props.altOptions?.filter(i => i != undefined).map((item, key) => {
                        return <p
                            className={(item.disabled ? styles.dropdownbuttondisabled : '')}
                            key={key}
                            onClick={()=>{
                                item.onClick();
                                setOpen(false);
                            }}
                        >{item.text}</p>
                    })}
                </div>
            : null }
        </div>
    );
}

class SplitButtonProps{
    className?: string;
    buttontext!: String;
    onClick!: Function;
    altOptions?: Array<any>;
    disabled?: boolean;
}

export default SplitButton;