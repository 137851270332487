import React, { useEffect } from "react";
import { useAuth } from "../../../Auth";

import styles from './Giosg.module.scss';
import { useLoanapplication } from "../../../LoanapplicationContext";

function Giosg(props: GiosgProps) {
    const auth = useAuth();
    const loanapplication = useLoanapplication();

    function loadGiosg(w: Window, t: Document, f: any, user: any) {
        var s: any = "script",
            o: any = "_giosg",
            h: any = "https://service.giosg.com",
            e: any,
            n: any;

        if (w[o] == undefined) {
            e = t.createElement(s);
            e.async = 1;
            e.src = h + "/live2/" + f;

            w[o] =
                w[o] ||
                function () {
                    ((w[o] as any)._e = (w[o] as any)._e || []).push(arguments);
                };

            (w[o] as any)._c = f;
            (w[o] as any)._h = h;
            n = t.getElementsByTagName(s)[0];
            n.parentNode.insertBefore(e, n);
        }

        let selectedDealer = localStorage.getItem("selectedDealerSequence");
        let dealer = user?.userdata?.Dealers?.find((d: any) => d?.Sequence == selectedDealer);

        const visitor_data = {
            username: dealer?.CompanyName,
            saksId: loanapplication?.Sequence != undefined ? loanapplication?.Sequence + " (" + new Date().getHours() + ":" + new Date().getMinutes() + ")" : "N/A",
            sakstype: loanapplication?.type != undefined ? loanapplication?.type + " (" + new Date().getHours() + ":" + new Date().getMinutes() + ")" : "N/A",
            saksvariant: loanapplication?.variant != undefined ? loanapplication?.variant + " (" + new Date().getHours() + ":" + new Date().getMinutes() + ")" : "N/A",
            kundesegment: loanapplication?.segment != undefined ? loanapplication?.segment + " (" + new Date().getHours() + ":" + new Date().getMinutes() + ")" : "N/A",
            objekttype: loanapplication?.category != undefined ? loanapplication?.category + " (" + new Date().getHours() + ":" + new Date().getMinutes() + ")" : "N/A",
        };

        (w[o] as any)('visitor', 'submit', visitor_data);
    }

    useEffect(() => {
        if (props.giosgId != "" && auth.user != undefined && auth.user.jwt != "") {
            loadGiosg(window, document, props.giosgId, auth.user);
        }
        let giosgBtn = document.getElementsByClassName("giosg-chat-button")[0];
        giosgBtn?.setAttribute("tabindex", "-1");
    }, [
        props.giosgId,
        auth.user,
        loanapplication?.Sequence,
        loanapplication?.type,
        loanapplication?.variant,
        loanapplication?.segment,
        loanapplication?.category,
    ]);

    return (
        <div className={styles.giosg}>
            
        </div>
    );
}

class GiosgProps {
    giosgId: string = "";
}

export default Giosg;