import React from "react";

import styles from './Pill.module.scss';

function Pill(props: PillProps) {
    
    function getPillClass() {
        switch (props.text.toLowerCase()) {
            case "kontroll": return styles.pillKontroll;
            case "godkjent": return styles.pillGodkjent;
            case "avvist man.": return styles.pillAvvistman;
            default: return "";
        }
    }

    return (
        <div className={styles.pill + " " + (props.text ? getPillClass() : "")} onClick={() => {
            props?.onClick?.();
        }}>
            {props.icon && <i className="material-symbols-outlined">{props.icon}</i>}
            <p>{props.text}</p>
        </div>
    );
}

class PillProps {
    public text!: string;
    public onClick?: () => void;
    public icon?: string; // Material UI icon name
}

export default Pill;