import React from "react";
import { toast } from 'react-toastify';

import styles from './FileUpload.module.scss';

function FileUpload(props: FileUploadProps) { 
    const hiddenFileInput = React.useRef(null);

    const [fileOver, setFileOver] = React.useState(false);
    
    function handleClick(event: any) {
        if (hiddenFileInput.current != null) {
            (hiddenFileInput.current as HTMLInputElement).click();
        }
    };

    function handleChange(event: any) {
        const fileUploaded = event.target.files[0];
        if (event.target.files[0].size > 25000000) {
            toast.error("Filen er for stor. Maks 25 MB");
        } else {
            props.onChange(fileUploaded);
        }
    };

    function dropHandler(ev: any) {
        console.log("File(s) dropped");
    
        ev.preventDefault();
        if (ev.dataTransfer.items) {
            if (ev?.dataTransfer?.items[0]?.kind === "file") {
                const file = ev?.dataTransfer?.items[0]?.getAsFile();
                props.onChange(file);
            }
        } else {
            props.onChange(ev?.dataTransfer?.files[0]);
        }
    }

    return (
        <div className={styles.border + " " + (fileOver ? styles.fileover : "")}>
            <div
                className={styles.fileuploadcontainer}
                onDrop={dropHandler}
                onDragOver={(e) => { e.preventDefault(); setFileOver(true); }}
                onDragEnter={(e) => { e.preventDefault(); setFileOver(true); }}
                onDragLeave={(e) => { e.preventDefault(); setFileOver(false); }}
                onDragEnd={(e) => { e.preventDefault(); setFileOver(false); }}
            >
                <div 
                    className={styles.fileuploadbutton}
                    onClick={handleClick}
                >
                    <input 
                        type="file"
                        accept=".pdf, .jpeg, image/*"
                        //accept=".pdf"
                        ref={hiddenFileInput}
                        onChange={handleChange}
                        style={{display:'none'}}
                        capture="environment"
                    />
                    <p>
                        Velg fil
                    </p>
                </div>
                <p>
                    PDF-filer. Maks 25 MB
                </p>
            </div>
        </div>
    );
}

class FileUploadProps{
    onChange!:  (file:any) => void;
}

export default FileUpload;