import React, { useState } from "react";

import styles from './SearchField.module.scss';

function SearchField(props: SearchFieldProps) {
    const [value, setValue] = useState('');

    return (
        <div className={styles.searchfieldcontainer}>
            <div className={styles.searchfield}>
                <input
                    value={props.value}
                    type="text"
                    placeholder={props.placeholder || "Søk"}
                    onChange={(event)=>{
                        props.onChange?.(event.target.value)
                        setValue(event.target.value)
                    }}
                    onKeyDown={(event)=> {
                        if (event.key === 'Enter') {
                            props.onEnter?.(value)
                        }
                    }}
                />
            </div>
            <div className={styles.symbols} onClick={() => {
                props.onClick?.(value)
            }}> 
                <span className="material-symbols-outlined">search</span>
            </div>
        </div>                    
    );
}

class SearchFieldProps{
    onClick?: (value:string) => void;
    onEnter?: (value:string) => void;
    onChange?: (value:string) => void;
    value?: string;
    placeholder?: string;
}

export default SearchField;