import Dropdown from "@sb1/ffe-dropdown-react";
import { Input, InputGroup, RadioButtonInputGroup, RadioSwitch, Checkbox } from "@sb1/ffe-form-react";
import { Tab, TabGroup } from "@sb1/ffe-tabs-react";
import React, { useEffect, useState } from "react";
import ContentCard from "../../../../Shared/ContentCard/ContentCard";
import { Insuranceapplication as InsuranceapplicationModel} from "../../../../../model/Insuranceapplication";
import { toast } from 'react-toastify';

import styles from './InsurancePricingCard.module.scss';
import { ActionButton } from "@sb1/ffe-buttons-react";
import { useAxios } from "../../../../../AxiosHandler";
import { useAuth } from "../../../../../Auth";
import general from "../../../../../functions/general";
import Tooltip from "@mui/material/Tooltip";
import { formatRegistrationNumber } from "../../../../../functions/dataSanitation";

function InsurancePricingCard(props: InsurancePricingCardProps) {
    const auth = useAuth();
    const axiosHandler = useAxios();
    const axios = axiosHandler.axios;

    const [insuranceBonusOptions, setInsuranceBonusOptions] = useState<any>([]);
    const [insuranceAnnualKilometersOptions, setInsuranceAnnualKilometersOptions] = useState<any>([]);
    const [insuranceCompaniesOptions, setInsuranceCompaniesOptions] = useState<any>([]);
    const [insuranceMortgageesOptions, setInsuranceMortgageesOptions] = useState<any>([]);

    const [calculationResult, setCalculationResult] = useState<any>(undefined);
    const [calculating, setCalculating] = useState<boolean>(false);

    const [problems, setProblems] = useState<Array<string>>([]);

    const [tooltipOpen, setTooltipOpen] = useState<any>(undefined);

    function calculatePrice() {
        setCalculating(true);
        setCalculationResult(undefined);

        props.setCurrentInsuranceapplication({
            ...props.currentInsuranceapplication || new InsuranceapplicationModel(),
            selectedInsurance: undefined,
            selectedDeductible: undefined
        });

        toast.info("Prisberegning pågår ...", { autoClose: false });
        axios.single({
            url: "/api/fremtind/bil/prisberegning",
            method: "POST",
            headers: {
                "UserAuthorization": "Bearer " + (JSON.parse(localStorage.getItem("logindata") ?? "")["JWT"]),
                "X-Request-Id": general.generateXRequestId(),
            },
            data: {
                "regnr": props.currentInsuranceapplication?.regnumber != "" ? formatRegistrationNumber(props.currentInsuranceapplication?.regnumber || "", true) : undefined,
                "merkenr": props.currentInsuranceapplication?.regnumber == undefined || props.currentInsuranceapplication?.regnumber == "" ? props.currentInsuranceapplication?.objectmake : undefined,
                "modellnr": props.currentInsuranceapplication?.regnumber == undefined || props.currentInsuranceapplication?.regnumber == "" ? props.currentInsuranceapplication?.objectmodel : undefined,
                "variantnr": props.currentInsuranceapplication?.regnumber == undefined || props.currentInsuranceapplication?.regnumber == "" ? props.currentInsuranceapplication?.objecttype : undefined,
                "chassisNummer": props.currentInsuranceapplication?.regnumber == undefined || props.currentInsuranceapplication?.regnumber == "" ? props.currentInsuranceapplication?.chassisnumber : undefined,
                "aarsModell": props.currentInsuranceapplication?.regnumber == undefined || props.currentInsuranceapplication?.regnumber == "" ? props.currentInsuranceapplication?.objectyear : undefined,
                "belaanesMedPant": props.currentInsuranceapplication?.loanLeasing == "loan",
                "bonus": props.currentInsuranceapplication?.insuranceBonus,
                "eierFodselsnummer": props.currentInsuranceapplication?.customerSocialSecurityNumber,
                "kilometerstand": props.currentInsuranceapplication?.mileage,
                "kjorelengdeAar": props.currentInsuranceapplication?.insuranceAnnualKilometers,
                "leasing": props.currentInsuranceapplication?.loanLeasing == "leasing",
                "markedsKilde": "625",
                "produkt": props.currentInsuranceapplication?.category,
                "yngreSjaafor": props.currentInsuranceapplication?.insuranceLowAge,
                "tilleggsDekninger": []
            }
        }).then((res: any) => {
            setCalculating(false);
            setCalculationResult(res.data?.dekningsPriser);
            toast.dismiss();
            if(res.data.error){
                toast.error("Fremtind: " + res.data.error.errorMessage);
            }
        }).catch((err: any) => {
            setCalculating(false);
            console.log(err);
            toast.dismiss();
            toast.error("Prisberegning feilet! " + err?.response?.data);
        });
    }

    function fetchMortgagees() {
        axios.single({
            url: "/api/fremtind/kodeverk/panthavere",
            method: "GET",
            headers: {
                "UserAuthorization": "Bearer " + (JSON.parse(localStorage.getItem("logindata") ?? "")["JWT"])
            }
        }).then((res: any) => {
            setInsuranceMortgageesOptions(res.data?.selskap?.map((x: any) => {
                return {
                    value: x.kundenummer,
                    label: x.navn
                }
            }));
        }).catch((err: any) => {
            console.log(err);
            toast.error("Innlasting av panthavere feilet!");
        });
    }

    function fetchInsuranceCompanies() {
        axios.single({
            url: "/api/company/insurancecompanies",
            method: "GET",
            headers: {
                "UserAuthorization": "Bearer " + (JSON.parse(localStorage.getItem("logindata") ?? "")["JWT"])
            }
        }).then((res: any) => {
            setInsuranceCompaniesOptions(res.data?.map((x: any) => {
                return {
                    value: x.Sequence,
                    label: x.CompanyName
                }
            }));
        }).catch((err: any) => {
            console.log(err);
            toast.error("Innlasting av forsikringsselskaper feilet!");
        });
    }

    function fetchInsuranceAnnualKilometers() {
        axios.single({
            url: "/api/fremtind/kodeverk/kjorelengder",
            method: "GET",
            headers: {
                "UserAuthorization": "Bearer " + (JSON.parse(localStorage.getItem("logindata") ?? "")["JWT"])
            }
        }).then((res: any) => {
            if (res.data.kilometerLevels != undefined) {
                setInsuranceAnnualKilometersOptions(res.data.kilometerLevels.sort((x: any, y: any) => Number(x) > Number(y) ? 0 : -1).map((x: any) => {
                    return {
                        value: x,
                        label: x + " 000"
                    }
                }));
            } else {
                toast.error("Innlasting av kjørelengder feilet!");
                if (res.data.error != undefined) {
                    toast.error("Fremtind | " + res.data.error.errorCode + ": " + res.data.error.errorMessage);
                }
            }
        }).catch((err: any) => {
            console.log(err);
            toast.error("Innlasting av kjørelengder feilet!");
        });
    }

    function fetchInsuranceBonus() {
        axios.single({
            url: "/api/fremtind/kodeverk/bonus",
            method: "GET",
            headers: {
                "UserAuthorization": "Bearer " + (JSON.parse(localStorage.getItem("logindata") ?? "")["JWT"])
            }
        }).then((res: any) => {
            if (res.data.bonusLevels != undefined) {
                setInsuranceBonusOptions(
                    res.data.bonusLevels
                    .filter((x: any) => x.isActive && x.key != 'XX')
                    .map((x: any) => {
                        return {
                            value: x.key,
                            label: x.caption
                        }
                    })
                );
            } else {
                toast.error("Innlasting av bonus feilet!");
                if (res.data.error != undefined) {
                    toast.error("Fremtind | " + res.data.error.errorCode + ": " + res.data.error.errorMessage);
                }
            }
        }).catch((err: any) => {
            console.log(err);
            toast.error("Innlasting av bonus feilet!");
        });
    }

    function getTooltipText(dekningsTypeKode: string) {
        let tooltipText = "";
        let item = calculationResult?.find((x: any) => x.dekningsTypeKode == dekningsTypeKode);
        if (item != undefined) {
            tooltipText = item.beskrivelse;
        }
        return tooltipText;
    }

    useEffect(() => {
        let tempProblems: Array<string> = [];
        
        if (props.currentInsuranceapplication?.regnumber == undefined || props.currentInsuranceapplication?.regnumber == "") {
            if (props.currentInsuranceapplication?.category == "BIL" && 
                (
                    props.currentInsuranceapplication?.objectmake == undefined || 
                    props.currentInsuranceapplication?.objectmodel == undefined ||
                    props.currentInsuranceapplication?.objecttype == undefined
                ) 
            ) tempProblems.push("Registreringsnummer eller merke, modell og variant må fylles ut");

            if (props.currentInsuranceapplication?.category == "BOBIL" && 
                (
                    props.currentInsuranceapplication?.objectmake == undefined
                ) 
            ) tempProblems.push("Registreringsnummer eller merke må fylles ut");
        }

        if (Number(props.currentInsuranceapplication?.insuranceBonus || 0) > 60) {
            if (props.currentInsuranceapplication?.insuranceBonusSource == undefined) tempProblems.push("Bonuskilde må fylles ut");
            if (
                props.currentInsuranceapplication?.insuranceBonusSourceRegnr == undefined ||
                props.currentInsuranceapplication?.insuranceBonusSourceRegnr == ""
            ) tempProblems.push("Bonuskilde registreringsnummer må fylles ut");
        }

        if (
            props.currentInsuranceapplication?.loanLeasing == "loan" || 
            props.currentInsuranceapplication?.loanLeasing == "leasing"
        ) {
            if (props.currentInsuranceapplication?.loanLeasingCompany == undefined) tempProblems.push("Panthaver må fylles ut");
        }

        if (props.currentInsuranceapplication?.category != "CAMPINGVOGN" && props.currentInsuranceapplication?.customerSocialSecurityNumber == undefined) tempProblems.push("Kunde må angis med personnummer");

        if (props.currentInsuranceapplication?.category != "CAMPINGVOGN" && props.currentInsuranceapplication?.insuranceAnnualKilometers == undefined) tempProblems.push("Ønsket kjørelengde må fylles ut");
        if (props.currentInsuranceapplication?.category != "CAMPINGVOGN" && props.currentInsuranceapplication?.insuranceBonus == undefined) tempProblems.push("Nåværende bonus må fylles ut");
        if (props.currentInsuranceapplication?.category != "CAMPINGVOGN" && props.currentInsuranceapplication?.insuranceLowAge == undefined) tempProblems.push("Yngre sjåfør må fylles ut");

        if (props.currentInsuranceapplication?.loanLeasing == undefined) tempProblems.push("Finansiering må fylles ut");

        setProblems(tempProblems);
    }, [
        props.currentInsuranceapplication?.insuranceAnnualKilometers,
        props.currentInsuranceapplication?.insuranceBonus,
        props.currentInsuranceapplication?.insuranceLowAge,
        props.currentInsuranceapplication?.loanLeasing,
        props.currentInsuranceapplication?.objectmake,
        props.currentInsuranceapplication?.objectmodel,
        props.currentInsuranceapplication?.objecttype,
        props.currentInsuranceapplication?.regnumber,
        props.currentInsuranceapplication?.customerSocialSecurityNumber,
        props.currentInsuranceapplication?.loanLeasingCompany,
        props.currentInsuranceapplication?.insuranceBonusSource,
        props.currentInsuranceapplication?.insuranceBonusSourceRegnr,
        props.currentInsuranceapplication?.category
    ]);

    useEffect(() => {
        fetchInsuranceAnnualKilometers();
        fetchInsuranceBonus();
        fetchInsuranceCompanies();
        fetchMortgagees();
    }, []);

    return(
        <ContentCard disabled={props.disabled} open={props.open} title="Forsikring" className={styles.caseinsurancecard}>
            { props.currentInsuranceapplication?.category == "CAMPINGVOGN" ? null : 
                <>
                    <InputGroup
                        extraMargin={false}
                        label="Hvor mange kilometer kjører du i året?"
                        tooltip="Hvor mange kilometer kjørelengde vil dekke kunden i året?"
                        //fieldMessage={showErrors ? 'Du må velge måned' : null}
                    >
                        <Dropdown
                            value={props.currentInsuranceapplication?.insuranceAnnualKilometers || "choose"}
                            onChange={e => {
                                props.setCurrentInsuranceapplication({
                                    ...props.currentInsuranceapplication || new InsuranceapplicationModel(),
                                    insuranceAnnualKilometers: e.target.value
                                });
                            }}
                        >
                            <option value="choose" disabled>
                                Velg kjørelengde
                            </option>
                            { insuranceAnnualKilometersOptions?.map((option: any, index: any) =>
                                <option key={index} value={option.value}>
                                    {option.label}
                                </option>
                            )}
                        </Dropdown>
                    </InputGroup>
                    &nbsp;
                    <InputGroup
                        extraMargin={false}
                        label="Hva er din bonus?"
                        tooltip="Hva er kundens nåværende forsikringsbonus?"
                        //fieldMessage={showErrors ? 'Du må velge måned' : null}
                    >
                        <Dropdown
                            value={props.currentInsuranceapplication?.insuranceBonus || "choose"}
                            onChange={e => {
                                props.setCurrentInsuranceapplication({
                                    ...props.currentInsuranceapplication || new InsuranceapplicationModel(),
                                    insuranceBonus: e.target.value
                                });
                            }}
                        >
                            <option value="choose" disabled>
                                Velg bonus
                            </option>
                            { insuranceBonusOptions?.map((option: any, index: any) =>
                                <option key={index} value={option.value}>
                                    {option.label}
                                </option>
                            )}
                        </Dropdown>
                    </InputGroup>
                    &nbsp;
                    { Number(auth.currentInsuranceapplication?.insuranceBonus || 0) > 60 ?
                        <>
                            <InputGroup
                                extraMargin={false}
                                label="Hvilket forsikringsselskap har du denne bonusen hos?"
                                tooltip="Hva er kundens nåværende forsikringsselskap med denne bonusen?"
                                //fieldMessage={showErrors ? 'Du må velge måned' : null}
                            >
                                <Dropdown
                                    value={props.currentInsuranceapplication?.insuranceBonusSource || "choose"}
                                    onChange={e => {
                                        props.setCurrentInsuranceapplication({
                                            ...props.currentInsuranceapplication || new InsuranceapplicationModel(),
                                            insuranceBonusSource: e.target.value
                                        });
                                    }}
                                >
                                    <option value="choose" disabled>
                                        Velg selskap
                                    </option>
                                    { insuranceCompaniesOptions.map((option: any, index: any) =>
                                        <option key={index} value={option.value}>
                                            {option.label}
                                        </option>
                                    )}
                                </Dropdown>
                            </InputGroup>
                            &nbsp;
                            <InputGroup
                                label="Regnummer på objektet som har denne bonusen"
                                tooltip="Registreringsnummer på objektet med bonusen"
                            >
                                {inputProps => (
                                    <Input
                                        type="text"
                                        name="regnumber"                                        
                                        value={props.currentInsuranceapplication?.insuranceBonusSourceRegnr || ""}
                                        onBlur={e => {
                                            props.setCurrentInsuranceapplication({
                                                ...props.currentInsuranceapplication || new InsuranceapplicationModel(),
                                                insuranceBonusSourceRegnr: formatRegistrationNumber(e.target.value)
                                            });
                                        }}
                                        onChange={e => {
                                            props.setCurrentInsuranceapplication({
                                                ...props.currentInsuranceapplication || new InsuranceapplicationModel(),
                                                insuranceBonusSourceRegnr: e.target.value
                                            });
                                        }}
                                        {...inputProps}
                                    />
                                )}
                            </InputGroup>
                            &nbsp;
                        </>
                    : null }
                    <RadioButtonInputGroup
                        label="Blir objektet brukt av personer under 23 år?"
                        name="lowage"
                        onChange={e => {
                            props.setCurrentInsuranceapplication({
                                ...props.currentInsuranceapplication || new InsuranceapplicationModel(),
                                insuranceLowAge: e.target.value == "true"
                            });
                        }}
                        selectedValue={props.currentInsuranceapplication?.insuranceLowAge != undefined ? props.currentInsuranceapplication?.insuranceLowAge?.toString() : ""}
                    >
                        {inputProps => (
                            <RadioSwitch
                                leftLabel="Ja"
                                leftValue="true"
                                rightLabel="Nei"
                                rightValue="false"
                                {...inputProps}
                            />
                        )}
                    </RadioButtonInputGroup>
                    &nbsp;
                </>
            }
            <legend className="ffe-form-label">Finansieres objektet med Lån eller Leasing?</legend>
            <TabGroup className={styles.carinfotoggle}>
                <Tab
                    selected={props.currentInsuranceapplication?.loanLeasing == "loan"}
                    onClick={() => {
                        props.setCurrentInsuranceapplication({
                            ...props.currentInsuranceapplication || new InsuranceapplicationModel(),
                            loanLeasing: "loan"
                        });
                    }}
                    aria-controls={"exact"}
                >
                    Lån
                </Tab>
                <Tab
                    selected={props.currentInsuranceapplication?.loanLeasing == "leasing"}
                    onClick={() => {
                        props.setCurrentInsuranceapplication({
                            ...props.currentInsuranceapplication || new InsuranceapplicationModel(),
                            loanLeasing: "leasing"
                        });
                    }}
                    aria-controls={"proofofequity"}
                >
                    Leasing
                </Tab>
                <Tab
                    selected={props.currentInsuranceapplication?.loanLeasing == "none"}
                    onClick={() => {
                        props.setCurrentInsuranceapplication({
                            ...props.currentInsuranceapplication || new InsuranceapplicationModel(),
                            loanLeasing: "none"
                        });
                    }}
                    aria-controls={"proofofequity"}
                >
                    Ingen
                </Tab>
            </TabGroup>
            { props.currentInsuranceapplication?.loanLeasing == "loan" || props.currentInsuranceapplication?.loanLeasing == "leasing" ?
                <>
                    <InputGroup
                        extraMargin={false}
                        label="Hvilket selskap har du finansieringen hos?"
                        tooltip="Hva er kundens finansieringsselskap for objektet?"
                    >
                        <Dropdown
                            value={props.currentInsuranceapplication?.loanLeasingCompany || "choose"}
                            onChange={e => {
                                props.setCurrentInsuranceapplication({
                                    ...props.currentInsuranceapplication || new InsuranceapplicationModel(),
                                    loanLeasingCompany: e.target.value
                                });
                            }}
                        >
                            <option value="choose" disabled>
                                Velg selskap
                            </option>
                            { insuranceMortgageesOptions?.map((option: any, index: any) =>
                                <option key={index} value={option.value}>
                                    {option.label}
                                </option>
                            ) ?? []}
                        </Dropdown>
                    </InputGroup>
                    &nbsp;
                </>
            : null }

            &nbsp;
            { props.currentInsuranceapplication?.category == "CAMPINGVOGN" ? null : 
                <ActionButton
                    disabled={calculating || problems.length > 0}
                    onClick={() => {
                        calculatePrice();
                    }}
                >{calculating ? "Beregner priser .." : "Beregn priser"}</ActionButton>
            }
            { problems.length > 0 ?
                <div className={styles.problems}>
                    { problems.length > 3 ?
                        <Tooltip
                            leaveDelay={200}
                            enterTouchDelay={0}
                            title={
                                problems.map((problem, key) =>
                                    <p key={key}>{problem}</p>
                                )
                            }
                            placement="top"
                            classes={{ tooltip: "problemstooltip" }}
                        >
                            <span className="material-symbols-outlined">warning</span>
                        </Tooltip>
                    : null}
                    { problems.length < 4 ? problems.map((problem, key) =>
                        <p key={key}>{problem}</p>
                    ) : null }
                </div>
            : null }
            <div className={styles.pricecontainer}>
                { calculationResult != undefined ? 
                    <>
                        <label className="ffe-form-label">Velg forsikring</label>
                        <div className={styles.pricelist}>
                            { calculationResult?.filter((item: any) => item.navn != undefined)?.map((item: any, index: any) => 
                                <div
                                    className={styles.pricelistitem + " " + (props.currentInsuranceapplication?.selectedInsurance != undefined && props.currentInsuranceapplication?.selectedInsurance.dekningsTypeKode == item.dekningsTypeKode ? styles.active : "")}
                                    key={index}
                                    onClick={() => {
                                        let tempCurrentInsuranceapplication = props.currentInsuranceapplication || new InsuranceapplicationModel();

                                        tempCurrentInsuranceapplication.selectedInsurance = item;

                                        if (props.currentInsuranceapplication?.selectedDeductible != undefined && item.prisEgenandelList?.find((i: any) => i.egenAndelsKode == props.currentInsuranceapplication?.selectedDeductible) == undefined) {
                                            tempCurrentInsuranceapplication.selectedDeductible = item.prisEgenandelList[0].egenAndelsKode;
                                            toast.info("Egenandel ikke tilgjengelig for valgt forsikring, valgt egenandel er endret");
                                        }
                                        if (props.currentInsuranceapplication?.selectedDeductible == undefined) {
                                            tempCurrentInsuranceapplication.selectedDeductible = item.prisEgenandelList[0].egenAndelsKode;
                                        }

                                        props.setCurrentInsuranceapplication({ ...tempCurrentInsuranceapplication });
                                    }}
                                >
                                    <div className={styles.pricelistitemleft}>
                                        <h3>{item.navn}</h3>
                                        { props.currentInsuranceapplication?.selectedDeductible != undefined ?
                                            item.prisEgenandelList?.find((i: any) => i.egenAndelsKode == props.currentInsuranceapplication?.selectedDeductible) != undefined ?
                                                <p>
                                                    <span className={styles.price}>{
                                                        general.formatPrice(general.getInitialInsurancePrice(calculationResult, item.prisEgenandelList?.find((i: any) => i.egenAndelsKode == props.currentInsuranceapplication?.selectedDeductible)) ?? "", true, true)
                                                    }</span>kr/mnd inkl. trafikkforsikringsavgift
                                                </p>
                                            :
                                                <p>
                                                    Ingen pris for valgt egenandel
                                                </p>
                                        :
                                            item.prisEgenandelList.length == 1 ?
                                                <p>
                                                    <span className={styles.price}>{general.formatPrice(general.getInitialInsurancePrice(calculationResult, item.prisEgenandelList[0]))}</span>kr/mnd inkl. trafikkforsikringsavgift
                                                </p>
                                            :   
                                                <p>
                                                    <span className={styles.price}>{general.formatPrice(general.getInitialInsurancePrice(calculationResult, item.prisEgenandelList.find((idx: any ) => idx.prisPerMaaned == Math.min(...item.prisEgenandelList.map((i: any) => i.prisPerMaaned)))))}</span>
                                                    &mdash;&nbsp;&nbsp;
                                                    <span className={styles.price}>{general.formatPrice(general.getInitialInsurancePrice(calculationResult, item.prisEgenandelList.find((idx: any ) => idx.prisPerMaaned == Math.max(...item.prisEgenandelList.map((i: any) => i.prisPerMaaned)))))}</span>
                                                    kr/mnd inkl. trafikkforsikringsavgift
                                                </p>
                                        }
                                    </div>
                                    <div className={styles.pricelistitemright}>
                                        { item.prisEgenandelList?.find((i: any) => i.egenAndelsKode == props.currentInsuranceapplication?.selectedDeductible) != undefined ?
                                            <>
                                                <p>
                                                    <span className={styles.price}>{
                                                        general.formatPrice(general.getInitialInsurancePrice(calculationResult, item.prisEgenandelList?.find((i: any) => i.egenAndelsKode == props.currentInsuranceapplication?.selectedDeductible), true), true, true)
                                                    }</span> kr/år
                                                </p>
                                                <button
                                                    className="ffe-tooltip__icon"
                                                    onClick={(e) => {
                                                        e.stopPropagation();
                                                        if (tooltipOpen == getTooltipText(item.dekningsTypeKode)) {
                                                            setTooltipOpen(undefined);
                                                        } else {
                                                            setTooltipOpen(getTooltipText(item.dekningsTypeKode));
                                                        }
                                                    }}
                                                >
                                                    <span>?</span>
                                                </button>
                                            </>
                                        : null }
                                    </div>
                                </div>
                            ) }
                        </div>
                        { tooltipOpen != undefined ?
                            <p className={styles.tooltip}>
                                {tooltipOpen}
                            </p>
                        : null }
                        { props.currentInsuranceapplication?.selectedInsurance != undefined ?
                            <>
                                <InputGroup
                                    extraMargin={false}
                                    label="Egenandel"
                                    tooltip="Hvor stor egenandel ønsker kunden? Egenandelen er det beløpet som kunden må betale selv ved skade."
                                >
                                    <Dropdown
                                        value={props.currentInsuranceapplication?.selectedDeductible}
                                        onChange={e => {
                                            props.setCurrentInsuranceapplication({
                                                ...props.currentInsuranceapplication || new InsuranceapplicationModel(),
                                                selectedDeductible: e.target.value
                                            });
                                        }}
                                    >
                                        <option value="choose" disabled>
                                            Velg egenandel
                                        </option>
                                        { props.currentInsuranceapplication?.selectedInsurance?.prisEgenandelList?.map((option: any, index: any) =>
                                            <option key={index} value={option.egenAndelsKode}>
                                                {option.egenAndel}
                                            </option>
                                        )}
                                    </Dropdown>
                                </InputGroup>
                                <div className={styles.ekundeSamtykke}>
                                    <hr/>
                                    <h5 className="ffe-h5">Kundens samtykke til elektronisk kommunikasjon</h5>
                                    <p>Ved å samtykke til elektronisk kommunikasjon får ikke kunden forsikringsavtalen i posten, men har den alltid tilgjengelig på nett. Ønsker kunden å trekke tilbake samtykket kan kunden gjøre det på nett eller ta kontakt med SpareBank 1 på telefon eller e-post.</p>
                                    <InputGroup
                                        className={styles.checkboxinputgroup}
                                    >
                                        <div className={styles.checkboxcontainer}>
                                            <Checkbox
                                                name="ekundeSamtykke"
                                                inline={false}
                                                checked={props.currentInsuranceapplication.ekundeSamtykke}
                                                onChange={e => {
                                                    props.setCurrentInsuranceapplication({
                                                        ...props.currentInsuranceapplication || new InsuranceapplicationModel(),
                                                        ekundeSamtykke: e.target.checked
                                                    })
                                                }}
                                            >
                                                Jeg har informert kunden og kunden har gitt sitt samtykke til elektronisk kommunikasjon
                                            </Checkbox>
                                        </div>
                                    </InputGroup>
                                    <hr/>
                                    <h5 className="ffe-h5">Forutsetninger for salg</h5>
                                    <ul>
                                        <li>Jeg har videreformidlet <a className="ffe-link-text" href="https://dokument.fremtind.no/vilkar/fremtind/pm/mobilitet/Vilkar_Toppkasko_Bil.pdf" rel="noreferrer" target="_blank">vilkårene for forsikringen</a></li>
                                        <li>Jeg har videreformidlet <a className="ffe-link-text" href="https://dokument.fremtind.no/ipid/IPID_BIL.pdf" rel="noreferrer" target="_blank">produktinformasjon (IPID) for bil (PDF)</a></li>
                                        <li>Jeg har informert kunden om at prisen forutsetter korrekte opplysninger</li>
                                        <li>Jeg er informert om at eierskiftet må fullføres innen 90 dager</li>
                                        <li>Jeg har informert kunden om at denne forsikringen leveres fra 01.01.2019 av Fremtind AS org.nr. 915 651 232 eiet av SpareBank 1 Gruppen og DNB</li>
                                    </ul>
                                    <hr/>
                                    <h5 className="ffe-h5">Per måned</h5>
                                    <p className={styles.insurancePriceLine}>
                                        <span>{general.getInsurancePricing(calculationResult, props.currentInsuranceapplication?.selectedDeductible, props.currentInsuranceapplication?.selectedInsurance).forsikring}</span> {props.currentInsuranceapplication?.selectedInsurance.navn} + 
                                        <span>{general.getInsurancePricing(calculationResult, props.currentInsuranceapplication?.selectedDeductible, props.currentInsuranceapplication?.selectedInsurance).trafikkforsikringsavgift}</span> Trafikkforsikringsavgift + 
                                        <span>{general.getInsurancePricing(calculationResult, props.currentInsuranceapplication?.selectedDeductible, props.currentInsuranceapplication?.selectedInsurance).panthavergaranti}</span> Panthavergaranti = 
                                        Totalt <span>{general.getInsurancePricing(calculationResult, props.currentInsuranceapplication?.selectedDeductible, props.currentInsuranceapplication?.selectedInsurance).total}</span>
                                    </p>
                                    <p>Kunden vil bli fakturert med en månedlig faktura.</p>
                                </div>
                            </>
                        : null }
                    </>
                : null }
            </div>
        </ContentCard>
    );
}

interface InsurancePricingCardProps {
    disabled: boolean;
    open: boolean;
    currentInsuranceapplication: InsuranceapplicationModel | undefined;
    setCurrentInsuranceapplication: (loanapplication: InsuranceapplicationModel) => void;
}

export default InsurancePricingCard;