import React from "react";

import styles from './StatisticsCompetitionTable.module.scss';

function StatisticsCompetitionTable(props: StatisticsCompetitionTableProps) {
    const [orderBy, setOrderBy] = React.useState<undefined | number>(undefined);
    const [orderDirection, setOrderDirection] = React.useState<"asc" | "desc">("asc");
    return (
        <div className={styles.statisticscompetitiontable}>
            <table>
                <thead>
                    <tr>
                        { props.columns.map((column, index) => {
                            return (
                                <th 
                                    key={index} 
                                    onClick={() => index == orderBy ? setOrderDirection(orderDirection == "asc" ? "desc" : "asc") : setOrderBy(index)}
                                >{column} {orderBy === index ? <span className="material-symbols-outlined expandicon">{orderDirection == "asc" ? "arrow_drop_up" : "arrow_drop_down"}</span> : ""}</th>
                            );
                        }) }
                    </tr>
                </thead>
                <tbody>
                    { props.data.sort((a:any, b:any) => {
                        if(orderBy !== undefined) {
                            if(orderDirection === "asc") {
                                return ((b) as any)[Object.keys(b)[orderBy]].replace(/\s+/g,'').localeCompare((a as any)[Object.keys(a)[orderBy]].replace(/\s+/g,''), undefined, {
                                    numeric: true,
                                });
                            } else { 
                                return ((a) as any)[Object.keys(a)[orderBy]].replace(/\s+/g,'').localeCompare((b as any)[Object.keys(b)[orderBy]].replace(/\s+/g,''), undefined, {
                                    numeric: true,
                                });
                            }
                        } else {
                            return 0;
                        }
                    }).map((row, index1) => {
                        return (
                            <tr key={index1}>
                                { Object.keys(row).map((key, index2) => {
                                    return (
                                        <td key={index2}>
                                            {index1 < 3 && index2 == 0 ?
                                                <div className={styles.rank + " " + (index1 == 0 ? styles.first : "")}>
                                                    <p>{index1 + 1}</p>
                                                </div>
                                            : null}
                                            {(row as any)[key]}
                                        </td>
                                    );
                                }) }
                            </tr>
                        );
                    })}
                </tbody>
            </table>
        </div>
    );
}

interface StatisticsCompetitionTableProps {
    columns: Array<string>;
    data: Array<Object>;
    defaultOrderBy?: string;
}

export default StatisticsCompetitionTable;