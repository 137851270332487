import React, { useEffect, useState } from "react";
import Tooltip from '@mui/material/Tooltip';

import styles from './SelectorList.module.scss';

function SelectorList(props: SelectorListProps) {
    const [selected, setSelected] = useState<string | Array<string> | undefined>(props.multiselect ? [] : '');

    let tooltipClasses = "disabledAgreementTooltip" + " " + localStorage.getItem("theme");
    
    useEffect(()=>{
        if (props.value != undefined){ 
            setSelected(props.value);
        }
    },[props.value]);

    function isSelected(item: Option){
        if (props.multiselect) {
            if (selected?.includes(item.value)) {
                return styles.active;
            } else {
                return '';
            }
        } else {
            if (selected == item.value) {
                return styles.active;
            } else {
                return '';
            }
        }
    }

    return (
        <div className={styles.selectorlist + " " + (props.multicolumn ? styles.multicolumn : "")}>
            {props.options?.map((item, key) => { 
                return (
                    <Tooltip
                        disableHoverListener={props.isPossibleAgreement?.(item.value) !== false}
                        disableTouchListener={props.isPossibleAgreement?.(item.value) !== false}
                        leaveDelay={0}
                        TransitionProps={{ 
                            timeout: 0
                        }}
                        title={"Ingen avtale er mulig"}
                        classes={{ tooltip: tooltipClasses }}
                        key={key}
                    >
                        <div 
                            key={key}
                            className={
                                styles.selector + " " + 
                                isSelected(item) + " " + 
                                ((props.isPossibleAgreement?.(item.value) === false) ? (styles.disabled + " ") : " ") +
                                ((props.options?.length || 0) <= 1 ? (styles.oneOption + " " + styles.disabled) : "")
                            }
                            onClick={() => {
                                if(props.isPossibleAgreement?.(item.value) === false) return;
                                if (props.multiselect) {
                                    let array = selected;

                                    if (!array?.includes(item.value)) {
                                        (array as Array<string>).push(item.value);
                                    } else {
                                        array = (array as Array<string>).filter(choice => choice !== item.value);
                                    }

                                    setSelected([...(array as Array<string>)]);
                                    props?.onChange?.(array);
                                } else {
                                    setSelected(item.value);
                                    props?.onChange?.(item.value);
                                }
                            }}
                        >
                            <div className={styles.dotcontainer}>
                                {props.multiselect?
                                    <div className={styles.square}>
                                        <div className={styles.squaredot}></div>
                                    </div>
                                :
                                    <div className={styles.circle}>
                                        <div className={styles.dot}></div>
                                    </div>
                                }
                            </div>
                            <div className={styles.button}>
                                <p>
                                    {item.label}
                                </p>
                            </div>
                        </div>
                    </Tooltip>
                );
            })}
        </div>
    );
}

class Option {
    value!: string;
    label!: string;
}

class SelectorListProps {
    onChange?: (selected: string | Array<string> | undefined) => void;
    options!: Array<Option>;
    value?: string | Array<string> | undefined;
    multiselect?: boolean;
    multicolumn?: boolean;
    isPossibleAgreement?: Function = (value: string) => { };
}

export default SelectorList;