import norwegian from "./no_NB/translations.json"
import english from "./en_US/translations.json"

function GetTexts(locale: any) {
    switch (locale) {
        case "no":
            return norwegian;
        case "en":
        default:
            return english;
    }
}

export default GetTexts;