import React, { ReactNode, useEffect, useState } from "react";
import { Input } from "@sb1/ffe-form-react";

import styles from './InputWithUsability.module.scss';

function InputWithUsability(props: InputWithUsabilityProps) {
    const canvas = document.createElement('canvas');

    const inputRef = React.createRef<HTMLInputElement>();
    const prefixRef = React.createRef<HTMLSpanElement>();
    const suffixRef = React.createRef<HTMLSpanElement>();
    const displayRef = React.createRef<HTMLSpanElement>();

    const [displayValue, setDisplayValue] = useState("");

    function updateSuffix() {
        if (inputRef.current && suffixRef.current) {
            
            const width = getTextWidth(displayValue ? displayValue : inputRef.current.value, '16px Segoe UI');
            suffixRef.current.style.left = width + 'px';
            suffixRef.current.style.paddingLeft = (inputRef.current.value != "" ? 22 : 16) + 'px';
        }
    }

    function getTextWidth(text: string, font: string): number {
        var context = canvas.getContext("2d");
        if (context) {
            context.font = font;
            var metrics = context.measureText(text);
            return metrics.width;
        } else {
            return 0;
        }
    }

    useEffect(() => {
        updateSuffix();
    }, [props.value]);
    
    useEffect(() => {
        let widthPrefix = getTextWidth(props?.prefix ?? "", "16px Segoe UI");
        if (inputRef.current){
            inputRef.current.style.paddingLeft = widthPrefix + (widthPrefix != 0 ? 22 : 16) + 'px';
        }
        if (displayRef.current){
            displayRef.current.style.left = widthPrefix + (widthPrefix != 0 ? 22 : 16) + 'px';
        }
    }, [props.prefix]);

    function numberFormat(value?: number | string, formatStyle?: string): string | ReactNode {
        if (props.customformatter) {
            return props.customformatter(value);
        } else {
            const formatter = new Intl.NumberFormat('no-NO', {
                style: formatStyle,
                currency: 'NOK',
                minimumFractionDigits: 0,
                maximumFractionDigits: 2
            });
            if (value?.toString() === "") return "";
            return formatter.format(Number(value));
        }
    }

    const handleOnChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        updateSuffix();        
        if (props.type == "numeric-text") {
            let value = Number(e.target.value.trim().replace(',', '.'));
            let textValue = e.target.value.replace(/[^0-9,]/g, '');
            setDisplayValue(textValue.indexOf(',') > -1 || isNaN(value) ? textValue : "");
            if (!isNaN(value) && (props.min === undefined || value >= props.min) && (props.max === undefined || value <= props.max)) {
                let target = { ...e.target, valueAsText: numberFormat(value, props.formatStyle) as string, valueAsNumber: value };
                let customEvent: React.ChangeEvent<HTMLInputElement> & { target: typeof target } = {
                    ...e,
                    target: target
                };                
                props.onChangeCustom?.(customEvent);
            }
        }
        props.onChange?.(e);
    };

    return (
        <div className={styles.affixinput}>
            <span ref={prefixRef} className={styles.prefix}>{props.prefix}</span>
            <span ref={suffixRef} className={styles.suffix}>{props.suffix}</span>
            <Input
                lang="no"
                ref={inputRef}
                tabIndex={props.tabIndex}
                type={props.type == "numeric-text" ? "text" : props.type}
                name={props.name}
                min={props.min}
                max={props.max}
                placeholder={props.placeholder}
                onBlur={(e) => {
                    setDisplayValue("");
                    props.onBlur?.(e);
                }}
                onKeyUp={e => e.key === "Enter" && props.onBlur?.({ target: e.target } as React.FocusEvent<HTMLInputElement>)}
                value={props.type == "numeric-text" ? displayValue || numberFormat(props.value) : props.value}
                step={props.step}
                onChange={e => handleOnChange(e)}
                disabled={props.disabled}
                aria-labelledby={props["aria-labelledby"]}
                aria-describedby={props["aria-describedby"]}
                aria-invalid={props["aria-invalid"]}
                {...props.inputProps}
            />
            {props.useseparatorformatter ?
                <span ref={displayRef} className={styles.numberdisplay}>{numberFormat(props?.value, props?.formatStyle)}</span>
            : null}
        </div>
    );
}

class InputWithUsabilityProps{
    prefix?: string;
    suffix?: string;
    tabIndex?: number;
    name?: string;
    type?: string;
    inputProps?: any;
    min?: number;
    max?: number;
    onChangeCustom?: (event: InputWithUsabilityEvent) => void;
    onChange?: React.ChangeEventHandler<HTMLInputElement> | undefined;
    onBlur?: React.FocusEventHandler<HTMLInputElement> | undefined;
    value?: number | string;
    formatStyle?: string;
    useseparatorformatter?: boolean;
    placeholder?: string;
    step?: number;
    disabled?: boolean;
    customformatter?: (value: any) => string | ReactNode;
    "aria-labelledby"?: string;
    "aria-describedby"?: string;
    "aria-invalid"?: string;
}

type InputWithUsabilityEvent = React.ChangeEvent<HTMLInputElement> & {
    target: EventTarget & HTMLInputElement & {
        valueAsText: string;
        valueAsNumber: number | undefined;
    };
}

export default InputWithUsability;