/**
 * Formats a phone number by removing all non-numeric characters except for '+'.
 * It will throw an error if the number is invalid.
 * @param phoneNumber 
 * @returns 
 */
export const formatPhoneNumber = (phoneNumber: string)  => {
    if (!phoneNumber) return "";
    // Remove all non-numeric characters except for '+'
    let cleaned = phoneNumber.replace(/[^0-9+]/g, '');
    // If the number starts with '00', replace it with '+'
    if (cleaned.startsWith('00')) {
        cleaned = '+' + cleaned.substring(2);
    }    
    // Removed '+' occurences after the first one
    cleaned = cleaned.substring(0, 1) + cleaned.substring(1).replace(/\+/g, '');
    // Check for valid length
    if ((!cleaned.startsWith('+') && cleaned.length > 8) || cleaned.length < 8) {
        throw new Error('Telefonnummeret er ugyldig');
    }    

    return cleaned;
};

/**
 * Formats a registration number by removing all non-alphanumeric characters and whitespaces
 * @param regNumber 
 * @param replaceAllWhitespaces If true, all whitespaces will be removed, otherwise whitespaces within the registration number will be kept
 * @returns 
 */
export const formatRegistrationNumber = (regNumber: string, replaceAllWhitespaces: boolean = false) => {
    if (!regNumber) return "";
    // Remove all characters except letters and numbers
    let cleaned = regNumber.toUpperCase().replace(/[^0-9A-Z\s]/g, '');
    // Remove all whitespaces
    return replaceAllWhitespaces ? cleaned.replace(/\s/g, '') : cleaned.trim();
};