import React from "react";

import styles from './DefaultLogo.module.scss';
import nord_norge_pos from '../../../Assets/Images/rgb_SB1_Finans_Nord_Norge_verti_pos.png';
import nord_norge_neg from '../../../Assets/Images/rgb_SB1_Finans_Nord_Norge_verti_neg.png';
import { config } from '../../../functions/config';

function DefaultLogo(props: DefaultLogoProps) {

    let textColor = props.white ? '#fff' : styles.colorPrimary;

    return (
        config.region == "NORD" ? 
            <img className={styles.defaultLogo} src={props.white ? nord_norge_neg : nord_norge_pos} alt="SpareBank1 Finans Nord-Norge" style={props.style} />
        : 
        (config.region == "ØST" ? 
            <svg
                version="1.1"
                id="svg2"
                width= "200"
                height="70"
                viewBox="0 0 365 100"
                xmlns="http://www.w3.org/2000/svg"
                style={props.style}
            >
                <title>SpareBank 1</title>
                <defs id="defs6" />
                <g
                    id="g8"
                    transform="matrix(1.3333333,0,0,-1.3333333,0,104.28)">
                    <g
                        id="g10"
                        transform="scale(0.1)">
                        <path
                            d="m 2441.3,668.508 c -29.33,0 -57.58,-4.578 -84.11,-13.008 111.69,24.23 222.39,-21.777 262.27,-116.016 46.1,-108.921 -19.88,-240.957 -147.37,-294.906 -114.36,-48.394 -239.31,-17.473 -297.11,68.024 33.81,-115.168 140.21,-199.289 266.32,-199.289 153.31,0 277.59,124.285 277.59,277.597 0,153.313 -124.28,277.598 -277.59,277.598"
                            style={{
                                fill: '#ed1d24',
                                fillOpacity: 1,
                                fillRule: 'nonzero',
                                stroke: 'none'
                            }}
                            id="path12" />
                        <path
                            d="m 2472.09,244.578 c 127.49,53.949 193.47,185.985 147.37,294.906 C 2579.58,633.723 2468.88,679.73 2357.19,655.5 2245,619.867 2163.7,514.898 2163.7,390.91 c 0,-27.203 3.98,-53.465 11.28,-78.308 57.8,-85.497 182.75,-116.418 297.11,-68.024"
                            style={{
                                fill: '#ae1c22',
                                fillOpacity: 1,
                                fillRule: 'nonzero',
                                stroke: 'none'
                            }}
                            id="path14" />
                        <path
                            d="m 2512.46,265.453 v 285.035 c 0,5.664 -4.62,10.274 -10.29,10.274 h -64.55 c -4.61,0 -7.19,-1.024 -11.31,-2.567 l -83.29,-42.871 c -5.12,-2.07 -7.18,-5.136 -7.18,-10.293 V 458.32 c 0,-5.484 4.45,-9.922 9.93,-9.922 h 53.3 V 224.313 c 24.33,3.382 48.91,10.062 73.02,20.265 14.25,6.027 27.73,13.035 40.37,20.875"
                            style={{
                                fill: '#ffffff',
                                fillOpacity: 1,
                                fillRule: 'nonzero',
                                stroke: 'none'
                            }}
                            id="path16" />
                        <path
                            d="m 379.703,360.168 c -48.64,0 -87.183,11.227 -94.297,13.465 -3.738,1.5 -5.609,4.492 -5.609,7.855 v 1.871 l 4.867,24.7 c 0.742,3.742 3.363,4.863 6.356,4.863 0.75,0 1.5,0 2.621,-0.371 7.859,-1.5 46.027,-12.356 81.945,-12.356 30.684,0 58,8.985 58,40.789 0,32.184 -28.059,35.543 -64.356,40.786 -40.414,5.996 -92.425,16.839 -92.425,80.457 0,65.476 48.64,82.695 106.265,82.695 43.785,0 80.078,-10.481 88.309,-12.727 4.492,-1.121 6.738,-4.117 6.738,-7.855 v -1.871 l -5.238,-24.324 c -0.375,-2.618 -2.996,-5.235 -5.988,-5.235 -0.375,0 -0.75,0.371 -1.125,0.371 -17.957,3.746 -45.649,11.227 -78.578,11.227 -27.688,0 -56.875,-5.242 -56.875,-36.668 0,-31.059 31.433,-35.551 64.734,-40.414 43.777,-6.738 93.543,-13.094 93.543,-82.699 0,-65.481 -53.133,-84.559 -108.887,-84.559"
                            style={{
                                fill: textColor,
                                fillOpacity: 1,
                                fillRule: 'nonzero',
                                stroke: 'none'
                            }}
                            id="path18" />
                        <path
                            d="m 839.797,459.691 -46.399,-7.48 c -15.343,-2.242 -29.562,-4.484 -29.562,-28.805 0,-29.191 23.574,-31.433 41.535,-31.433 14.594,0 26.567,2.613 34.426,5.242 z m 15.34,-95.043 c -13.094,-2.988 -31.059,-5.23 -52.012,-5.23 -40.41,0 -90.18,4.113 -90.18,63.609 0,48.27 30.309,53.5 60.246,58.375 l 66.606,10.852 v 11.973 c 0,10.476 -1.125,17.961 -10.481,22.074 -5.988,2.621 -14.964,3.742 -28.437,3.742 -25.445,0 -53.133,-7.484 -64.356,-10.477 -1.125,0 -1.871,-0.375 -2.621,-0.375 -1.871,0 -3.742,0.75 -4.117,3.368 l -5.238,23.199 v 1.5 c 0,3.367 2.621,4.863 4.492,5.613 8.977,3.367 43.402,12.723 81.57,12.723 26.567,0 44.528,-3.742 56.504,-10.481 19.082,-10.472 22.825,-28.062 22.825,-48.265 V 403.566 c 0,-28.062 -9.36,-32.921 -34.801,-38.918"
                            style={{
                                fill: textColor,
                                fillOpacity: 1,
                                fillRule: 'nonzero',
                                stroke: 'none'
                            }}
                            id="path20" />
                        <path
                            d="m 1023.44,527.051 c -22.83,0 -42.284,-2.246 -52.76,-4.863 V 369.895 c 0,-3.375 -2.246,-5.614 -5.614,-5.614 h -38.164 c -2.246,0 -6.363,2.239 -6.363,5.614 v 161.648 c 0,14.59 8.606,19.082 26.566,24.32 16.461,5.239 43.036,8.981 64.355,9.356 h 6.36 c 5.24,0 5.24,0 7.86,-1.121 1.5,-0.75 3.37,-2.246 3.37,-5.989 v -24.32 c 0,-3.746 -0.37,-6.738 -5.61,-6.738"
                            style={{
                                fill: textColor,
                                fillOpacity: 1,
                                fillRule: 'nonzero',
                                stroke: 'none'
                            }}
                            id="path22" />
                        <path
                            d="m 1166.53,503.852 c -4.87,20.578 -16.09,27.316 -38.17,27.316 -25.07,0 -35.55,-7.484 -39.67,-26.191 -2.61,-10.481 -2.61,-21.336 -2.99,-33.68 l 83.82,0.379 c 0,11.965 -0.38,22.07 -2.99,32.176 z m 52.76,-55.754 c 0,-4.114 -2.25,-8.606 -7.86,-8.606 h -125.35 c 0,-17.219 4.86,-26.57 14.96,-34.426 9.73,-7.851 23.95,-11.222 44.16,-11.222 23.2,0 40.78,5.613 52.38,8.98 0.75,0.371 1.13,0.371 1.87,0.371 2.25,0 4.12,-1.5 5.24,-5.242 l 4.87,-19.078 c 0,-0.367 0.37,-1.129 0.37,-1.867 0,-3.004 -1.87,-4.496 -4.49,-5.613 -12.72,-5.247 -38.17,-11.977 -67.73,-11.977 -73.34,0 -102.15,28.812 -102.15,102.145 0,70.351 19.46,104.031 92.43,104.031 37.79,0 60.99,-9.731 74.46,-27.692 12.72,-16.839 16.84,-40.785 16.84,-71.839 v -17.965"
                            style={{
                                fill: textColor,
                                fillOpacity: 1,
                                fillRule: 'nonzero',
                                stroke: 'none'
                            }}
                            id="path24" />
                        <path
                            d="m 1629.62,459.691 -46.4,-7.48 c -15.34,-2.242 -29.56,-4.484 -29.56,-28.805 0,-29.191 23.57,-31.433 41.53,-31.433 14.59,0 26.57,2.613 34.43,5.242 z m 15.34,-95.043 c -13.1,-2.988 -31.06,-5.23 -52.01,-5.23 -40.42,0 -90.18,4.113 -90.18,63.609 0,48.27 30.31,53.5 60.24,58.375 l 66.61,10.852 v 11.973 c 0,10.476 -1.13,17.961 -10.48,22.074 -5.99,2.621 -14.97,3.742 -28.44,3.742 -25.45,0 -53.14,-7.484 -64.36,-10.477 -1.12,0 -1.87,-0.375 -2.61,-0.375 -1.88,0 -3.75,0.75 -4.13,3.368 l -5.23,23.199 v 1.5 c 0,3.367 2.61,4.863 4.48,5.613 8.99,3.367 43.41,12.723 81.58,12.723 26.56,0 44.53,-3.742 56.5,-10.481 19.09,-10.472 22.83,-28.062 22.83,-48.265 V 403.566 c 0,-28.062 -9.36,-32.921 -34.8,-38.918"
                            style={{
                                fill: textColor,
                                fillOpacity: 1,
                                fillRule: 'nonzero',
                                stroke: 'none'
                            }}
                            id="path26" />
                        <path
                            d="m 1879.87,364.281 h -38.55 c -3.37,0 -5.98,2.239 -5.98,5.614 V 501.98 c 0,21.704 -8.24,29.188 -28.07,29.188 -22.45,0 -46.77,-6.363 -46.77,-6.363 v -154.91 c 0,-3.375 -2.25,-5.614 -5.61,-5.614 h -38.17 c -3.75,0 -6.36,2.239 -6.36,5.614 v 161.273 c 0,14.215 7.11,19.082 28.07,24.695 19.07,5.239 50.51,9.731 74.46,9.731 46.77,0 72.59,-11.977 72.59,-56.875 V 369.895 c 0,-3.375 -2.24,-5.614 -5.61,-5.614"
                            style={{
                                fill: textColor,
                                fillOpacity: 1,
                                fillRule: 'nonzero',
                                stroke: 'none'
                            }}
                            id="path28" />
                        <path
                            d="m 2096.84,367.652 c 0,-1.504 -1.49,-3.371 -3.74,-3.371 h -50.14 c -1.87,0 -3.74,0 -5.61,2.239 l -58,78.964 h -12.72 v -75.589 c 0,-3.375 -1.87,-5.614 -5.61,-5.614 h -38.54 c -3.36,0 -5.99,2.239 -5.99,5.614 v 261.554 c 0,4.489 1.87,6.735 6.37,7.856 l 36.66,6.363 h 1.5 c 3.37,0 5.61,-2.246 5.61,-6.363 V 479.16 h 12.72 l 59.13,78.949 c 1.49,2.246 2.99,2.618 4.86,2.618 l 45.65,-1.122 c 2.62,0 3.74,-1.871 3.74,-3.367 0,-0.75 -0.37,-1.125 -0.75,-1.871 l -70.72,-89.062 74.84,-95.782 c 0.37,-0.378 0.74,-1.132 0.74,-1.871"
                            style={{
                                fill: textColor,
                                fillOpacity: 1,
                                fillRule: 'nonzero',
                                stroke: 'none'
                            }}
                            id="path30" />
                        <path
                            d="m 601.367,393.844 c -20.203,0 -35.543,4.48 -35.543,4.48 V 524.43 c 0,0 22.074,5.988 39.285,5.988 28.438,0 40.789,-6.359 40.789,-66.984 0,-56.868 -10.48,-69.59 -44.531,-69.59 z m 14.223,171.75 c -25.07,0 -53.883,-4.117 -76.332,-11.977 -16.844,-5.238 -23.574,-10.847 -23.574,-30.308 V 308.266 c 0,-3.743 2.933,-6.801 8.324,-5.828 5.394,0.976 35.453,6.531 35.453,6.531 4.492,0.75 6.363,2.992 6.363,7.48 v 48.66 c 13.281,-3.234 30.024,-5.691 48.641,-5.691 69.601,0 82.695,43.777 82.695,102.902 0,59.493 -9.355,103.274 -81.57,103.274"
                            style={{
                                fill: textColor,
                                fillOpacity: 1,
                                fillRule: 'nonzero',
                                stroke: 'none'
                            }}
                            id="path32" />
                        <path
                            d="m 1377,485.895 -70.35,2.238 V 402.824 H 1377 c 37.79,0 50.14,16.461 50.14,39.289 0,30.676 -10.1,42.66 -50.14,43.782 z m 1.87,116.367 h -72.22 v -80.074 l 69.6,2.992 c 28.44,1.121 43.03,11.226 43.03,39.664 0,26.195 -12.72,37.418 -40.41,37.418 z m 4.87,-237.981 h -124.61 c -3.37,0 -6.36,2.621 -6.36,5.981 v 264.554 c 0,3.368 2.99,5.989 6.36,5.989 h 124.98 c 63.27,0 89.06,-24.813 89.06,-69.746 0,-39.661 -22.03,-53.86 -56.13,-63.621 37.85,-6.243 65.48,-26.786 65.48,-70.938 0,-50.141 -35.17,-72.219 -98.78,-72.219"
                            style={{
                                fill: textColor,
                                fillOpacity: 1,
                                fillRule: 'nonzero',
                                stroke: 'none'
                            }}
                            id="path34" />
                        <path
                            d="m 548.734,252.414 h -7.222 L 528.07,209.406 h 34.442 z M 606.523,147.25 c 0,-1.344 -0.839,-2.016 -2.183,-2.016 h -19.824 c -1.512,0 -2.52,0.84 -3.024,2.352 l -13.941,44.016 h -44.52 L 509.254,147.25 c -0.336,-1.176 -1.68,-2.016 -2.856,-2.016 h -19.824 c -1.176,0 -2.183,0.672 -2.183,1.848 0,0.168 0,0.504 0.168,1.176 l 40.152,118.941 c 0.336,1.176 1.848,2.184 3.023,2.184 h 35.954 c 1.175,0 2.687,-1.008 3.023,-2.184 l 39.644,-118.773 c 0.168,-0.504 0.168,-1.008 0.168,-1.176"
                            style={{
                                fill: textColor,
                                fillOpacity: 1,
                                fillRule: 'nonzero',
                                stroke: 'none'
                            }}
                            id="path36" />
                        <path
                            d="M 249.363,250.902 H 194.262 V 210.75 h 49.726 c 1.512,0 2.688,-1.008 2.688,-2.52 v -13.437 c 0,-1.512 -1.176,-2.688 -2.688,-2.688 h -49.726 v -44.183 c 0,-1.512 -1.344,-2.688 -2.86,-2.688 h -18.476 c -1.512,0 -2.856,1.176 -2.856,2.688 v 118.773 c 0,1.512 1.344,2.688 2.856,2.688 h 76.437 c 1.68,0 2.688,-1.176 2.688,-2.52 V 253.59 c 0,-1.512 -1.008,-2.688 -2.688,-2.688"
                            style={{
                                fill: textColor,
                                fillOpacity: 1,
                                fillRule: 'nonzero',
                                stroke: 'none'
                            }}
                            id="path38" />
                        <path
                            d="m 305.957,145.234 h -18.648 c -1.512,0 -2.688,1.008 -2.688,2.52 v 118.941 c 0,1.512 1.008,2.184 2.52,2.352 l 19.152,2.519 c 1.68,0.168 2.52,-1.175 2.52,-2.351 V 147.754 c 0,-1.512 -1.176,-2.52 -2.856,-2.52"
                            style={{
                                fill: textColor,
                                fillOpacity: 1,
                                fillRule: 'nonzero',
                                stroke: 'none'
                            }}
                            id="path40" />
                        <path
                            d="m 457.512,145.234 h -38.473 c -1.512,0 -2.687,1.344 -3.191,2.688 l -41.328,102.98 h -5.543 l 1.511,-103.148 c 0,-1.512 -1.175,-2.52 -2.855,-2.52 h -18.481 c -1.511,0 -2.687,1.008 -2.687,2.52 v 119.109 c 0,1.344 1.344,2.52 2.855,2.52 h 40.321 c 1.511,0 2.855,-1.008 3.359,-2.352 l 42.168,-107.183 h 2.352 l -1.008,106.847 c 0,2.184 1.511,2.688 3.359,2.688 h 17.137 c 2.015,0 3.359,-0.672 3.359,-2.688 V 147.754 c 0,-1.512 -1.176,-2.52 -2.855,-2.52"
                            style={{
                                fill: textColor,
                                fillOpacity: 1,
                                fillRule: 'nonzero',
                                stroke: 'none'
                            }}
                            id="path42" />
                        <path
                            d="m 742.617,145.234 h -38.469 c -1.511,0 -2.687,1.344 -3.191,2.688 l -41.328,102.98 h -5.543 l 1.512,-103.148 c 0,-1.512 -1.176,-2.52 -2.856,-2.52 h -18.48 c -1.512,0 -2.688,1.008 -2.688,2.52 v 119.109 c 0,1.344 1.344,2.52 2.856,2.52 h 40.316 c 1.516,0 2.859,-1.008 3.363,-2.352 l 42.164,-107.183 h 2.356 l -1.012,106.847 c 0,2.184 1.516,2.688 3.363,2.688 h 17.133 c 2.02,0 3.364,-0.672 3.364,-2.688 V 147.754 c 0,-1.512 -1.176,-2.52 -2.86,-2.52"
                            style={{
                                fill: textColor,
                                fillOpacity: 1,
                                fillRule: 'nonzero',
                                stroke: 'none'
                            }}
                            id="path44" />
                        <path
                            d="m 824.289,143.387 c -21.84,0 -39.144,4.871 -42.336,6.047 -1.848,0.671 -3.023,2.015 -2.519,4.367 l 2.183,11.09 c 0.336,2.011 2.016,2.347 4.031,2.011 3.532,-0.672 20.665,-5.543 36.793,-5.543 13.778,0 26.039,4.032 26.039,18.313 0,14.449 -12.601,15.961 -28.894,18.312 -18.145,2.688 -41.496,7.559 -41.496,36.118 0,29.402 21.84,37.128 47.711,37.128 19.656,0 35.953,-4.535 39.648,-5.71 2.016,-0.672 3.528,-2.184 3.024,-4.368 l -2.352,-10.922 c -0.504,-2.183 -1.848,-2.519 -3.695,-2.183 -8.067,1.68 -19.992,5.039 -34.778,5.039 -12.429,0 -25.535,-2.352 -25.535,-16.465 0,-13.941 14.114,-15.957 29.063,-18.141 19.656,-3.023 42,-5.878 42,-37.128 0,-29.399 -23.856,-37.965 -48.887,-37.965"
                            style={{
                                fill: textColor,
                                fillOpacity: 1,
                                fillRule: 'nonzero',
                                stroke: 'none'
                            }}
                            id="path46" />
                        <path
                            d="m 1515.59,252.414 h -7.23 l -13.44,-43.008 h 34.44 z m 57.79,-105.164 c 0,-1.344 -0.84,-2.016 -2.19,-2.016 h -19.82 c -1.51,0 -2.52,0.84 -3.02,2.352 l -13.95,44.016 h -44.52 l -13.77,-44.352 c -0.34,-1.176 -1.68,-2.016 -2.86,-2.016 h -19.82 c -1.18,0 -2.19,0.672 -2.19,1.848 0,0.168 0,0.504 0.17,1.176 l 40.15,118.941 c 0.34,1.176 1.85,2.184 3.03,2.184 h 35.95 c 1.17,0 2.69,-1.008 3.02,-2.184 l 39.65,-118.773 c 0.17,-0.504 0.17,-1.008 0.17,-1.176"
                            style={{
                                fill: textColor,
                                fillOpacity: 1,
                                fillRule: 'nonzero',
                                stroke: 'none'
                            }}
                            id="path48" />
                        <path
                            d="m 1803.22,252.078 h -26.04 v -89.375 h 26.04 c 25.2,0 35.11,7.227 35.11,44.856 0,37.632 -9.91,44.519 -35.11,44.519 z m 2.86,-106.844 h -50.4 c -1.51,0 -2.69,1.176 -2.69,2.688 v 118.773 c 0,1.512 1.18,2.688 2.69,2.688 h 50.4 c 41.16,0 56.95,-15.793 56.95,-61.992 0,-46.368 -15.79,-62.157 -56.95,-62.157"
                            style={{
                                fill: textColor,
                                fillOpacity: 1,
                                fillRule: 'nonzero',
                                stroke: 'none'
                            }}
                            id="path50" />
                        <path
                            d="m 1980.96,145.234 h -80.47 c -1.52,0 -2.86,1.176 -2.86,2.688 v 118.773 c 0,1.512 1.34,2.688 2.86,2.688 h 80.3 c 1.68,0 2.69,-1.176 2.69,-2.52 v -13.105 c 0,-1.512 -1.01,-2.688 -2.69,-2.688 h -59.14 v -32.59 h 56.62 c 1.51,0 2.69,-1.175 2.69,-2.687 V 202.52 c 0,-1.344 -1.18,-2.52 -2.69,-2.52 h -56.62 v -36.621 h 59.31 c 1.51,0 2.68,-1.18 2.68,-2.524 v -12.933 c 0,-1.512 -1.17,-2.688 -2.68,-2.688"
                            style={{
                                fill: textColor,
                                fillOpacity: 1,
                                fillRule: 'nonzero',
                                stroke: 'none'
                            }}
                            id="path52" />
                        <path
                            d="m 1430.76,145.234 h -77.95 c -1.51,0 -2.69,1.008 -2.69,2.52 v 118.941 c 0,1.512 1.01,2.184 2.52,2.352 l 19.15,2.519 c 1.68,0.168 2.52,-1.175 2.52,-2.351 V 164.219 h 56.45 c 1.51,0 2.69,-1.18 2.69,-2.692 v -13.605 c 0,-1.512 -1.18,-2.688 -2.69,-2.688"
                            style={{
                                fill: textColor,
                                fillOpacity: 1,
                                fillRule: 'nonzero',
                                stroke: 'none'
                            }}
                            id="path54" />
                        <path
                            d="M 1709.47,145.234 H 1671 c -1.51,0 -2.69,1.344 -3.19,2.688 l -41.33,102.98 h -5.54 l 1.51,-103.148 c 0,-1.512 -1.18,-2.52 -2.86,-2.52 h -18.48 c -1.51,0 -2.69,1.008 -2.69,2.52 v 119.109 c 0,1.344 1.35,2.52 2.86,2.52 h 40.32 c 1.51,0 2.86,-1.008 3.36,-2.352 l 42.17,-107.183 h 2.35 l -1.01,106.847 c 0,2.184 1.51,2.688 3.36,2.688 h 17.14 c 2.01,0 3.36,-0.672 3.36,-2.688 V 147.754 c 0,-1.512 -1.18,-2.52 -2.86,-2.52"
                            style={{
                                fill: textColor,
                                fillOpacity: 1,
                                fillRule: 'nonzero',
                                stroke: 'none'
                            }}
                            id="path56" />
                        <path
                            d="m 1150.7,143.387 c -21.84,0 -39.14,4.871 -42.33,6.047 -1.85,0.671 -3.02,2.015 -2.52,4.367 l 2.18,11.09 c 0.34,2.015 2.02,2.351 4.04,2.015 3.52,-0.672 20.66,-5.547 36.79,-5.547 13.77,0 26.04,4.036 26.04,18.313 0,14.449 -12.6,15.961 -28.9,18.312 -18.14,2.688 -41.49,7.559 -41.49,36.118 0,29.402 21.83,37.128 47.71,37.128 19.65,0 35.95,-4.535 39.64,-5.71 2.02,-0.672 3.53,-2.184 3.03,-4.368 l -2.35,-10.922 c -0.51,-2.183 -1.85,-2.519 -3.7,-2.183 -8.06,1.68 -19.99,5.039 -34.78,5.039 -12.43,0 -25.53,-2.352 -25.53,-16.465 0,-13.941 14.11,-15.957 29.06,-18.141 19.66,-3.023 42,-5.878 42,-37.128 0,-29.399 -23.85,-37.965 -48.89,-37.965"
                            style={{
                                fill: textColor,
                                fillOpacity: 1,
                                fillRule: 'nonzero',
                                stroke: 'none'
                            }}
                            id="path58" />
                        <path
                            d="m 1321.06,251.07 h -38.63 V 147.754 c 0,-1.512 -1.18,-2.52 -2.69,-2.52 h -18.65 c -1.51,0 -2.69,1.008 -2.69,2.52 V 251.07 h -38.81 c -1.51,0 -2.68,1.176 -2.68,2.688 v 13.105 c 0,1.344 1.17,2.52 2.68,2.52 h 101.47 c 1.68,0 2.69,-1.176 2.69,-2.688 v -12.937 c 0,-1.512 -1.01,-2.688 -2.69,-2.688"
                            style={{
                                fill: textColor,
                                fillOpacity: 1,
                                fillRule: 'nonzero',
                                stroke: 'none'
                            }}
                            id="path60" />
                        <path
                            d="m 2106.11,251.07 h -38.64 V 147.754 c 0,-1.512 -1.17,-2.52 -2.68,-2.52 h -18.65 c -1.51,0 -2.69,1.008 -2.69,2.52 V 251.07 h -38.81 c -1.51,0 -2.69,1.176 -2.69,2.688 v 13.105 c 0,1.344 1.18,2.52 2.69,2.52 h 101.47 c 1.68,0 2.69,-1.176 2.69,-2.688 v -12.937 c 0,-1.512 -1.01,-2.688 -2.69,-2.688"
                            style={{
                                fill: textColor,
                                fillOpacity: 1,
                                fillRule: 'nonzero',
                                stroke: 'none'
                            }}
                            id="path62" />
                        <path
                            d="m 1047.23,233.766 -56.285,-65.684 c 5.043,-4.871 12.935,-7.394 25.035,-7.394 30.57,0 34.1,16.296 34.1,46.703 0,10.586 -0.5,19.488 -2.85,26.375 z m -31.25,20.16 c -30.578,0 -33.937,-16.125 -33.937,-46.535 0,-10.246 0.504,-18.817 2.52,-25.703 l 55.947,65.351 c -5.21,4.703 -12.77,6.887 -24.53,6.887 z m 48.05,-0.504 c 8.39,-11.254 10.75,-27.047 10.75,-46.199 0,-38.469 -9.91,-64.172 -58.8,-64.172 -17.304,0 -29.738,3.359 -38.472,9.406 L 966.59,139.688 c -0.504,-0.672 -1.012,-0.84 -1.516,-0.84 -0.504,0 -1.008,0.168 -1.511,0.672 l -7.559,5.882 c -0.672,0.504 -0.84,1.008 -0.84,1.344 0,0.504 0.168,1.008 0.672,1.512 l 11.758,13.605 c -8.063,11.258 -10.414,26.879 -10.414,45.36 0,38.64 10.082,64.343 58.8,64.343 16.97,0 29.23,-3.191 37.97,-8.902 l 11.08,13.102 c 0.51,0.504 1.01,0.672 1.52,0.672 0.5,0 1,-0.168 1.51,-0.504 l 7.56,-5.879 c 0.5,-0.504 0.84,-1.008 0.84,-1.512 0,-0.504 -0.34,-1.008 -0.67,-1.512 l -11.76,-13.609"
                            style={{
                                fill: textColor,
                                fillOpacity: 1,
                                fillRule: 'nonzero',
                                stroke: 'none'
                            }}
                            id="path64" />
                    </g>
                </g>
            </svg>
        : null)
    );
}

class DefaultLogoProps {
    white?: boolean;
    style?: React.CSSProperties;
}

export default DefaultLogo;