export class Dealer {
    constructor(dealer?: Dealer) {
        Object.assign(this,dealer)
    }

    public Address!: string;
    public CompanyName!: string;
    public Email!: string;
    public ID!: string;
    public OrgNumber!: string;
    public IsCustomer!: boolean;
    public IsDealer!: boolean;
    public Mobile!: string;
    public Sequence!: number;
    public DealerGroupAgreementConnection?: Array<any>;
    public insuranceEnabled?: boolean;
    public allowInterestChange?: boolean;
}
