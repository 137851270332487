import React from "react";

import styles from './Top10Dealers.module.scss';
import ContentCard from "../../../../Shared/ContentCard/ContentCard";
import StatisticsCompetitionTable from "../../../../Shared/StatisticsCompetitionTable/StatisticsCompetitionTable";

function Top10Dealers(props: Top10DealersProps) {
    const columns = [ 'Forhandler', 'Tot. poeng' ];

    const data = [
        { 0: "Albjerk Bil AS", 2: 28 },
        { 0: "Midtstranda Auto AS", 2: 24 },
        { 0: "Bilhuset AS", 2: 21 },
        { 0: "Autohuset AS", 2: 17 },
        { 0: "Autoimport AS", 2: 12 },
    ];

    return (
        <div className={styles.top10dealers}>
            <ContentCard
                title="Ti på topp forhandlere"
                open={true}
                disableClick={true}
            >
                <StatisticsCompetitionTable
                    columns={columns}
                    data={props.data}
                />
            </ContentCard>
        </div>
    );
}

interface Top10DealersProps {
    data: any;
}

export default Top10Dealers