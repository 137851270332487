import React, { useEffect, useState } from "react";

import styles from './CategorySelector.module.scss';
import SelectorList from "../SelectorList/SelectorList";

function CategorySelector(props: CategorySelectorProps) {
    const [selected, setSelected] = useState(props.selectedCategory || "");

    useEffect(() => {
        if (props.categories?.length == 1 && (selected == "" || selected == undefined)) {
            setSelected(props.categories[0].value);
            props?.onChange?.(props.categories[0].value);
        }
    }, [props.categories]);

    useEffect(() => {
        setSelected(props.selectedCategory || "");
    }, [props.selectedCategory]);

    return (
        <div className={styles.categoryselector}>
            { props.onChange != undefined /* && (props.categories?.length || 0) > 1 */ ?
                <SelectorList
                    options={props.categories?.sort(
                        (a: any, b: any) => a.name.localeCompare(b.name)
                    )?.map((i: any) => {
                        return {value: i.value, label: i.name};
                    }) || []}
                    multicolumn={props.multicolumn || false}
                    onChange={(value) => {
                        setSelected(value as string);
                        props?.onChange?.(value as string);
                    }}
                    value={selected}
                    isPossibleAgreement={props.isPossibleAgreement}
                ></SelectorList>
            : null }
        </div>
    );
}

class CategorySelectorProps {
    onChange?: (category: string) => void;
    categories?: any[];
    selectedCategory?: string;
    multicolumn?: boolean;
    isPossibleAgreement?: Function = (category: string) => { };
}

export default CategorySelector;