import React, { useEffect } from "react";

import styles from './Volume.module.scss';
import ContentCard from "../../../../Shared/ContentCard/ContentCard";

import {
    Chart as ChartJS,
    CategoryScale,
    LinearScale,
    Title,
    Tooltip,
    Legend,
    PointElement,
    LineElement,
    ArcElement,
} from 'chart.js';
import { Line } from "react-chartjs-2";
import general from "../../../../../functions/general";

ChartJS.register(
    CategoryScale,
    LinearScale,
    PointElement,
    LineElement,
    ArcElement,
    Title,
    Tooltip,
    Legend,
);

function Volume(props: VolumeProps) {
    const labels = ['Jan', 'Feb', 'Mar', 'Apr', 'Mai', 'Jun', 'Jul', 'Aug', 'Sep', 'Okt', 'Nov', 'Des'];
    const targetDefault = [0,0,0,0,0,0,0,0,0,0,0,0];
    const valueDefault = [0,0,0,0,0,0,0,0,0,0,0,0];

    const [redraw, setRedraw] = React.useState(false);

    useEffect(() => {
        setRedraw(!redraw);
    }, [(window as any).colorPrimary, (window as any).colorSecondary]);

    return (
        <div className={styles.volume}>
            <ContentCard
                title="Volum"
                open={true}
                disableClick={true}
                loading={props.data === undefined}
            >
                <div className={styles.linechart}>
                    <Line
                        redraw={redraw}
                        data={{
                            labels,
                            datasets: [
                                {
                                    label: 'I år',
                                    data: (props.data?.value ?? valueDefault),
                                    fill: {
                                        target: 'origin',
                                        /* above: general.getTheme() == "light" ? '#D3D3EAAA' : '#2D2D2DAA', */
                                    },
                                },
                                {
                                    label: 'Fjoråret',
                                    data: (props.data?.target ?? targetDefault),
                                    fill: false,
                                    borderWidth: 2,
                                    borderColor: general.getTheme() == "light" ? 'darkgrey' : 'darkgrey',
                                    pointRadius: 2,
                                    pointBorderColor: general.getTheme() == "light" ? 'darkgrey' : 'darkgrey',
                                    pointBackgroundColor: general.getTheme() == "light" ? 'darkgrey' : 'darkgrey',
                                    pointBorderWidth: 1,
                                },
                            ]
                        }}
                        options={{
                            maintainAspectRatio: false,
                            interaction: {
                                mode: 'index',
                                axis: 'y'
                            },
                            plugins: {
                                legend: {
                                    position: "top",
                                    align: "end",
                                    labels: {
                                        pointStyle: 'circle',
                                        // usePointStyle: true,
                                        color: general.getTheme() == "light" ? (window as any).colorPrimary : '#ffffff',
                                        padding: 20,
                                        boxWidth: 10,
                                        boxHeight: 10,
                                        useBorderRadius: true,
                                        borderRadius: 5,
                                        font: {
                                            size: 14,
                                            family: 'SpareBank1-regular',
                                            weight: 'bold',
                                        },
                                    },
                                },
                            },
                            elements: {
                                line: {
                                    borderColor: (window as any).colorSecondary,
                                    borderWidth: 4,
                                },
                                point: {
                                    radius: 5,
                                    borderColor: "#ffffff",
                                    borderWidth: 3,
                                    backgroundColor: (window as any).colorPrimary,
                                    hoverRadius: 5,
                                },
                            },
                            scales: {
                                y: {
                                    beginAtZero: true,
                                    suggestedMax: Math.max(...(props.data?.value ?? valueDefault)) + 1000,
                                    border: {
                                        display: false,
                                    },
                                    ticks: {
                                        color: general.getTheme() == "light" ? (window as any).colorPrimary : '#ffffff',
                                        font: {
                                            size: 14,
                                            family: 'SpareBank1-regular',
                                            weight: 'bold',
                                        },
                                    },
                                },
                                x: {
                                    grid: {
                                        display: false,
                                    },
                                    ticks: {
                                        color: general.getTheme() == "light" ? (window as any).colorPrimary : '#ffffff',
                                        font: {
                                            size: 14,
                                            family: 'SpareBank1-regular',
                                            weight: 'bold',
                                        },
                                    },
                                }
                            }
                        }}
                    />
                </div>
            </ContentCard>
        </div>
    );
}

interface VolumeProps {
    data: {
        target: Array<number>,
        value: Array<number>
    } | undefined
}

export default Volume;