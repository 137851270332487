export class Insuranceapplication {
    constructor(insuranceapplication?: Insuranceapplication) {
        Object.assign(this, insuranceapplication);
    }

    public ID?: string | undefined = undefined; // ID for "Kladd"
    public sequence?: number | undefined = undefined;
    public UpdateDate?: Date | undefined = undefined;
    public updateDate?: Date | undefined = undefined;
    public UpdatedBy?: string | undefined = undefined;
    public CustomerSequence?: string | undefined = undefined;

    public category?: string | undefined = undefined;

    public exactCar?: boolean | undefined = undefined;
    public regnrknown?: boolean | undefined = true;

    public salgsAgentId?: string | undefined = undefined;

    public offerDetail?: OfferDetail | undefined = undefined;

    public Status?: string | undefined = undefined;

    // Fremtind-verdier
    public avtaleNummer?: string = undefined;
    public avtaleVersjon?: string = undefined;
    public kundeNummer?: string = undefined;
    public eierMobil?: string = undefined;
    public eierEpost?: string = undefined;
    public eierFodselsnummer?: string = undefined;
    public ekundeSamtykke?: boolean = undefined;
    public merkenr?: string = undefined;
    public modellnr?: string = undefined;
    public variantnr?: string = undefined;
    public regnr?: string = undefined;
    public merkenavn?: string = undefined;
    public modellnavn?: string = undefined;
    public chassisNummer?: string = undefined;
    public kjorelengdeAar?: string = undefined;
    public bonus?: string = undefined;
    public yngreSjaafor?: boolean = undefined;
    public registreringsAar?: string = undefined;
    public produkt?: string = undefined;
    public bonusFraSelskap?: string = undefined;
    public bonusFraRegnr?: string = undefined;
    public belaanesMedPant?: boolean = undefined;
    public leasing?: boolean = undefined;
    public kilometerstand?: string = undefined;
    public panthaverKundenummer?: string = undefined;

    public selectedInsurance?: any | undefined = undefined;
    public selectedDeductible?: any | undefined = undefined;

    public purchaseSum?: number | undefined = undefined;

    public regnumber?: string | undefined = undefined;
    public chassisnumber?: string | undefined = undefined;
    public objectyear?: number | undefined = undefined;
    public objectmake?: string | undefined = undefined;
    public objectmakeCode?: string | undefined = undefined; //Used for "campingvogn"
    public objectmakeName?: string | undefined = undefined;
    public objectmodel?: string | undefined = undefined;
    public objectmodelName?: string | undefined = undefined;
    public objecttype?: string | undefined = undefined;
    public objecttypeName?: string | undefined = undefined;
    public mileage?: number | undefined = undefined;
    public useableKm?: number | undefined = undefined;

    public requestingInsurance?: boolean | undefined = undefined;
    public insuranceCompany?: string | undefined = undefined;
    public insuranceBonus?: string | undefined = undefined;
    public insuranceAnnualKilometers?: string | undefined = undefined;
    public insuranceBonusSource?: string | undefined = undefined;
    public insuranceBonusSourceRegnr?: string | undefined = undefined;
    public insuranceLowAge?: boolean | undefined = undefined;
    public loanLeasing?: string | undefined = undefined;
    public loanLeasingCompany?: string | undefined = undefined;

    public customerCoborrower?: boolean | undefined = undefined;

    public customerSocialSecurityNumber?: string | undefined = undefined;
    public customerSector?: string | undefined = undefined;
    public customerSequence?: number | undefined = undefined;
    public customerEmail?: string | undefined = undefined;
    public customerPhone?: string | undefined = undefined;
    public customerFirstName?: string | undefined = undefined;
    public customerLastName?: string | undefined = undefined;

    public coCustomerSocialSecurityNumber?: string | undefined = undefined;
    public coCustomerSector?: string | undefined = undefined;
    public coCustomerSequence?: number | undefined = undefined;
    public coCustomerEmail?: string | undefined = undefined;
    public coCustomerPhone?: string | undefined = undefined;
    public coCustomerFirstName?: string | undefined = undefined;
    public coCustomerLastName?: string | undefined = undefined;
}

interface OfferDetail {
    monthlyFee: string | undefined;
    yearlyFee: string | undefined;
    coverageTypes: CoverageType[] | undefined;
    periodFee: string | undefined;
    startDate: string | undefined;
    status: string | undefined;
}

interface CoverageType {
    deductibleCode: string | undefined;
    deductibleDescription: string | undefined;
    description: string | undefined;
    id: string | undefined;
    insurancesum: number | undefined;
    premiumDetails: PremiumDetail | undefined;
}

interface PremiumDetail {
    monthlyPremium: number | undefined;
    periodPremium: number | undefined;
    premium: number | undefined;
    premiumTermAddition: number | undefined;
}