import React, { useState, useContext, createContext } from "react";
import { CaseNote } from "./model/CaseNote";
import { config } from "./functions/config";

let loanapplicationContext = createContext({
    clearForm: () => { },
    setAll: (loanapplication: any) => { },

    ID: undefined as any,
    setID: (ID: number | undefined) => {},
    agreementId: undefined as any,
    setAgreementId: (agreementId: string | undefined) => {},
    Sequence: undefined as any,
    setSequence: (Sequence: string | undefined) => {},

    status: undefined as any,
    setStatus: (status: string | undefined) => {},

    invoiceAddress: undefined as any,
    setInvoiceAddress: (invoiceAddress: string | undefined) => {},
    coInvoiceAddress: undefined as any,
    setCoInvoiceAddress: (coInvoiceAddress: string | undefined) => {},

    SchemaCustomerType: undefined as any,
    setSchemaCustomerType: (SchemaCustomerType: string | undefined) => {},
    SchemaObjectVariant: undefined as any,
    setSchemaObjectVariant: (SchemaObjectVariant: string | undefined) => {},
    SchemaAgreementVariant: undefined as any,
    setSchemaAgreementVariant: (SchemaAgreementVariant: string | undefined) => {},

    calculationResult: undefined as any,
    setCalculationResult: (calculationResult: any) => {},

    freeinterestcalc: undefined as any,
    setFreeinterestcalc: (freeinterestcalc: boolean | undefined) => {},

    dealerGroupAgreementConnectionID: undefined as any,
    setDealerGroupAgreementConnectionID: (dealerGroupAgreementConnectionID: string | undefined) => {},
    dealerGroupAgreementConnection: undefined as any,
    setDealerGroupAgreementConnection: (dealerGroupAgreementConnection: any | undefined) => {},

    segment: undefined as any,
    setSegment: (segment: string | undefined) => {},
    type: undefined as any,
    setType: (type: string | undefined) => {},
    variant: undefined as any,
    setVariant: (variant: string | undefined) => {},
    category: undefined as any,
    setCategory: (category: string | undefined) => {},

    downPaymentSum: undefined as any,
    setDownPaymentSum: (downPaymentSum: number | undefined) => {},
    downPaymentPercentage: undefined as any,
    setDownPaymentPercentage: (downPaymentPercentage: number | undefined) => {},
    downPaymentSource: undefined as any,
    setDownPaymentSource: (downPaymentSource: number | undefined) => {},
    startRent: undefined as any,
    setStartRent: (startRent: number | undefined) => {},
    startRentPct: undefined as any,
    setStartRentPct: (startRentPct: number | undefined) => {},
    startRentInclMva: undefined as any,
    setStartRentInclMva: (startRentInclMva: number | undefined) => {},
    startRentInclMvaPct: undefined as any,
    setStartRentInclMvaPct: (startRentInclMvaPct: number | undefined) => {},
    startRentExclMva: undefined as any,
    setStartRentExclMva: (startRentExclMva: number | undefined) => {},
    startRentExclMvaPct: undefined as any,
    setStartRentExclMvaPct: (startRentExclMvaPct: number | undefined) => {},
    purchaseSum: undefined as any,
    setPurchaseSum: (purchaseSum: number | undefined) => {},
    purchaseSumExclMva: undefined as any,
    setPurchaseSumExclMva: (purchaseSumExclMva: number | undefined) => {},
    purchaseSumInclMva: undefined as any,
    setPurchaseSumInclMva: (purchaseSumInclMva: number | undefined) => {},
    nominalInterestRate: undefined as any,
    setNominalInterestRate: (nominalInterestRate: number | undefined) => {},
    contractlengthmonths: undefined as any,
    setContractlengthmonths: (contractlengthmonths: number | undefined) => {},
    dueday: undefined as any,
    setDueday: (dueday: number | undefined) => {},
    onetimeFee: undefined as any,
    setOnetimeFee: (onetimeFee: number | undefined) => {},
    caseObjectNew: undefined as any,
    setCaseObjectNew: (caseObjectNew: boolean | undefined) => {},
    remainingValue: undefined as any,
    setRemainingValue: (remainingValue: number | undefined) => {},
    remainingValuePct: undefined as any,
    setRemainingValuePct: (remainingValuePct: number | undefined) => {},
    exactCar: undefined as any,
    setExactCar: (exactCar: boolean | undefined) => {},
    regnrknown: undefined as any,
    setRegnrknown: (regnrknown: boolean | undefined) => {},
    regnumber: undefined as any,
    setRegnumber: (regnumber: string | undefined) => {},

    objectSequence: undefined as any,
    setObjectSequence: (objectSequence: string | undefined) => {},
    objectID: undefined as any,
    setObjectID: (objectID: number | undefined) => {},
    objectmakeName: undefined as any,
    setObjectmakeName: (objectmakename: string | undefined) => {},
    objectmodelName: undefined as any,
    setObjectmodelName: (objectmodelname: string | undefined) => {},
    objecttypeName: undefined as any,
    setObjecttypeName: (objecttypename: string | undefined) => {},
    objectmake: undefined as any,
    setObjectmake: (objectmake: string | undefined) => {},
    objectmakeCode: undefined as any,
    setObjectmakeCode: (objectmakeCode: string | undefined) => {},
    objectmodel: undefined as any,
    setObjectmodel: (objectmodel: string | undefined) => {},
    objecttype: undefined as any,
    setObjecttype: (objecttype: string | undefined) => {},
    chassisnumber: undefined as any,
    setChassisnumber: (chassisnumber: string | undefined) => {},
    objectyear: undefined as any,
    setObjectyear: (objectyear: number | undefined) => {},
    useableKm: undefined as any,
    setUseableKm: (useableKm: number | undefined) => {},
    mileage: undefined as any,
    setMileage: (mileage: number | undefined) => {},
    deliveryDate: undefined as any,
    setDeliveryDate: (deliveryDate: string | undefined) => {},
    priceExtraKmExclVAT: undefined as any,
    setPriceExtraKmExclVAT: (priceExtraKmExclVAT: number | undefined) => {},
    priceExtraKmInclVAT: undefined as any,
    setPriceExtraKmInclVAT: (priceExtraKmInclVAT: number | undefined) => {},

    customerSequence: undefined as any,
    setCustomerSequence: (customerSequence: string | undefined) => {},
    customerSector: undefined as any,
    setCustomerSector: (customerSector: string | undefined) => {},
    customerSocialSecurityNumber: undefined as any,
    setCustomerSocialSecurityNumber: (customerSocialSecurityNumber: string | undefined) => {},
    customerAddresses: undefined as any,
    setCustomerAddresses: (customerAddresses: Array<any> | undefined) => {},
    customerPhone: undefined as any,
    setCustomerPhone: (customerPhone: string | undefined) => {},
    customerEmail: undefined as any,
    setCustomerEmail: (customerEmail: string | undefined) => {},
    customerLastName: undefined as any,
    setCustomerLastName: (customerLastName: string | undefined) => {},
    customerFirstName: undefined as any,
    setCustomerFirstName: (customerFirstName: string | undefined) => {},
    customerCoborrower: undefined as any,
    setCustomerCoborrower: (customerCoborrower: boolean | undefined) => {},
    coCustomerSequence: undefined as any,
    setCoCustomerSequence: (coCustomerSequence: string | undefined) => {},
    coCustomerSector: undefined as any,
    setCoCustomerSector: (coCustomerSector: string | undefined) => {},
    coCustomerSocialSecurityNumber: undefined as any,
    setCoCustomerSocialSecurityNumber: (coCustomerSocialSecurityNumber: string | undefined) => {},
    coCustomerAddresses: undefined as any,
    setCoCustomerAddresses: (coCustomerAddresses: Array<any> | undefined) => {},
    coCustomerPhone: undefined as any,
    setCoCustomerPhone: (coCustomerPhone: string | undefined) => {},
    coCustomerEmail: undefined as any,
    setCoCustomerEmail: (coCustomerEmail: string | undefined) => {},
    coCustomerLastName: undefined as any,
    setCoCustomerLastName: (coCustomerLastName: string | undefined) => {},
    coCustomerFirstName: undefined as any,
    setCoCustomerFirstName: (coCustomerFirstName: string | undefined) => {},

    requestingInsurance: undefined as any,
    setRequestingInsurance: (requestingInsurance: boolean | undefined) => {},
    insuranceCompany: undefined as any,
    setInsuranceCompany: (insuranceCompany: string | undefined) => {},
    insuranceBonus: undefined as any,
    setInsuranceBonus: (insuranceBonus: string | undefined) => {},
    insuranceLowAge: undefined as any,
    setInsuranceLowAge: (insuranceLowAge: boolean | undefined) => {},
    insuranceAnnualKilometers: undefined as any,
    setInsuranceAnnualKilometers: (insuranceAnnualKilometers: string | undefined) => {},
    insuranceCoverageTypeCode: undefined as any,
    setInsuranceCoverageTypeCode: (insuranceCoverageTypeCode: string | undefined) => {},
    insuranceDeductibleCode: undefined as any,
    setInsuranceDeductibleCode: (insuranceDeductibleCode: string | undefined) => {},
    insuranceBonusSource: undefined as any,
    setInsuranceBonusSource: (insuranceBonusSource: string | undefined) => {},
    insuranceBonusSourceRegnr: undefined as any,
    setInsuranceBonusSourceRegnr: (insuranceBonusSourceRegnr: string | undefined) => {},
    loanLeasingCompany: undefined as any,
    setLoanLeasingCompany: (loanLeasingCompany: string | undefined) => {},

    comment: undefined as any,
    setComment: (comment: string | undefined) => {},
    salesPersonSequence: undefined as any,
    setSalesPersonSequence: (salesPersonSequence: string | undefined) => {},

    CaseNotes: undefined as any,
    setCaseNotes: (CaseNotes: Array<CaseNote> | undefined) => {},

    updateDate: undefined as any,
    setUpdateDate: (updateDate: string | undefined) => {},

    ekundeSamtykke: undefined as any,
    setEkundeSamtykke: (ekundeSamtykke: boolean | undefined) => {},

    oneMonthFreeInsurance: undefined as any,
    setOneMonthFreeInsurance: (oneMonthFreeInsurance: boolean | undefined) => {},

    sendToEsign: undefined as any,
    setSendToEsign: (sendToEsign: boolean | undefined) => {},
});

export function ProvideLoanapplication({ children }: any) {
    const loanapplication = useProvideLoanapplication();
    return <loanapplicationContext.Provider value={loanapplication}>{children}</loanapplicationContext.Provider>;
}

export const useLoanapplication = () => {
    return useContext(loanapplicationContext);
};

function useProvideLoanapplication() {
    const [Sequence, setSequence] = useState<string | undefined>(undefined);
    const [ID, setID] = useState<number | undefined>(undefined);
    const [agreementId, setAgreementId] = useState<string | undefined>(undefined);

    const [status, setStatus] = useState<string | undefined>(undefined);

    const [invoiceAddress, setInvoiceAddress] = useState<string | undefined>(undefined);
    const [coInvoiceAddress, setCoInvoiceAddress] = useState<string | undefined>(undefined);

    const [SchemaCustomerType, setSchemaCustomerType] = useState<string | undefined>(undefined);
    const [SchemaObjectVariant, setSchemaObjectVariant] = useState<string | undefined>(undefined);
    const [SchemaAgreementVariant, setSchemaAgreementVariant] = useState<string | undefined>(undefined);

    const [calculationResult, setCalculationResult] = useState<any | undefined>(undefined);

    const [freeinterestcalc, setFreeinterestcalc] = useState<boolean | undefined>(false);

    const [dealerGroupAgreementConnectionID, setDealerGroupAgreementConnectionID] = useState<string | undefined>(undefined);
    const [dealerGroupAgreementConnection, setDealerGroupAgreementConnection] = useState<any | undefined>(undefined);

    const [segment, setSegment] = useState<string | undefined>(undefined);
    const [type, setType] = useState<string | undefined>(undefined);
    const [variant, setVariant] = useState<string | undefined>("Standard");
    const [category, setCategory] = useState<string | undefined>(undefined);

    const [downPaymentSum, setDownPaymentSum] = useState<number | undefined>(undefined);
    const [downPaymentPercentage, setDownPaymentPercentage] = useState<number | undefined>(undefined);
    const [downPaymentSource, setDownPaymentSource] = useState<number | undefined>(undefined);
    const [startRent, setStartRent] = useState<number | undefined>(undefined);
    const [startRentPct, setStartRentPct] = useState<number | undefined>(undefined);
    const [startRentInclMva, setStartRentInclMva] = useState<number | undefined>(undefined);
    const [startRentInclMvaPct, setStartRentInclMvaPct] = useState<number | undefined>(undefined);
    const [startRentExclMva, setStartRentExclMva] = useState<number | undefined>(undefined);
    const [startRentExclMvaPct, setStartRentExclMvaPct] = useState<number | undefined>(undefined);
    const [purchaseSum, setPurchaseSum] = useState<number | undefined>(undefined);
    const [purchaseSumExclMva, setPurchaseSumExclMva] = useState<number | undefined>(undefined);
    const [purchaseSumInclMva, setPurchaseSumInclMva] = useState<number | undefined>(undefined);
    const [nominalInterestRate, setNominalInterestRate] = useState<number | undefined>(undefined);
    const [contractlengthmonths, setContractlengthmonths] = useState<number | undefined>(undefined);
    const [dueday, setDueday] = useState<number | undefined>(undefined);
    const [onetimeFee, setOnetimeFee] = useState<number | undefined>(undefined);
    const [caseObjectNew, setCaseObjectNew] = useState<boolean | undefined>(undefined);
    const [remainingValue, setRemainingValue] = useState<number | undefined>(undefined);
    const [remainingValuePct, setRemainingValuePct] = useState<number | undefined>(undefined);
    const [exactCar, setExactCar] = useState<boolean | undefined>(true);
    const [regnrknown, setRegnrknown] = useState<boolean | undefined>(true);
    const [regnumber, setRegnumber] = useState<string | undefined>(undefined);

    const [objectSequence, setObjectSequence] = useState<string | undefined>(undefined);
    const [objectID, setObjectID] = useState<number | undefined>(undefined);
    const [objectmakeName, setObjectmakeName] = useState<string | undefined>(undefined);
    const [objectmodelName, setObjectmodelName] = useState<string | undefined>(undefined);
    const [objecttypeName, setObjecttypeName] = useState<string | undefined>(undefined);
    const [objectmake, setObjectmake] = useState<string | undefined>(undefined);
    const [objectmakeCode, setObjectmakeCode] = useState<string | undefined>(undefined);
    const [objectmodel, setObjectmodel] = useState<string | undefined>(undefined);
    const [objecttype, setObjecttype] = useState<string | undefined>(undefined);
    const [chassisnumber, setChassisnumber] = useState<string | undefined>(undefined);
    const [objectyear, setObjectyear] = useState<number | undefined>(undefined);
    const [useableKm, setUseableKm] = useState<number | undefined>(undefined);
    const [mileage, setMileage] = useState<number | undefined>(undefined);
    const [deliveryDate, setDeliveryDate] = useState<string | undefined>(undefined);
    const [priceExtraKmExclVAT, setPriceExtraKmExclVAT] = useState<number | undefined>(undefined);
    const [priceExtraKmInclVAT, setPriceExtraKmInclVAT] = useState<number | undefined>(undefined);

    const [customerSequence, setCustomerSequence] = useState<string | undefined>(undefined);
    const [customerSector, setCustomerSector] = useState<string | undefined>(undefined);
    const [customerSocialSecurityNumber, setCustomerSocialSecurityNumber] = useState<string | undefined>(undefined);
    const [customerAddresses, setCustomerAddresses] = useState<Array<any> | undefined>(undefined);
    const [customerPhone, setCustomerPhone] = useState<string | undefined>(undefined);
    const [customerEmail, setCustomerEmail] = useState<string | undefined>(undefined);
    const [customerFirstName, setCustomerFirstName] = useState<string | undefined>(undefined);
    const [customerLastName, setCustomerLastName] = useState<string | undefined>(undefined);
    const [customerCoborrower, setCustomerCoborrower] = useState<boolean | undefined>(false);
    const [coCustomerSequence, setCoCustomerSequence] = useState<string | undefined>(undefined);
    const [coCustomerSector, setCoCustomerSector] = useState<string | undefined>(undefined);
    const [coCustomerSocialSecurityNumber, setCoCustomerSocialSecurityNumber] = useState<string | undefined>(undefined);
    const [coCustomerAddresses, setCoCustomerAddresses] = useState<Array<any> | undefined>(undefined);
    const [coCustomerPhone, setCoCustomerPhone] = useState<string | undefined>(undefined);
    const [coCustomerEmail, setCoCustomerEmail] = useState<string | undefined>(undefined);
    const [coCustomerFirstName, setCoCustomerFirstName] = useState<string | undefined>(undefined);
    const [coCustomerLastName, setCoCustomerLastName] = useState<string | undefined>(undefined);

    const [requestingInsurance, setRequestingInsurance] = useState<boolean | undefined>(false);
    const [insuranceCompany, setInsuranceCompany] = useState<string | undefined>(undefined);
    const [insuranceBonus, setInsuranceBonus] = useState<string | undefined>(undefined);
    const [insuranceLowAge, setInsuranceLowAge] = useState<boolean | undefined>(false);
    const [insuranceAnnualKilometers, setInsuranceAnnualKilometers] = useState<string | undefined>(undefined);
    const [insuranceCoverageTypeCode, setInsuranceCoverageTypeCode] = useState<string | undefined>(undefined);
    const [insuranceDeductibleCode, setInsuranceDeductibleCode] = useState<string | undefined>(undefined);
    const [insuranceBonusSource, setInsuranceBonusSource] = useState<string | undefined>(undefined);
    const [insuranceBonusSourceRegnr, setInsuranceBonusSourceRegnr] = useState<string | undefined>(undefined);
    const [loanLeasingCompany, setLoanLeasingCompany] = useState<string | undefined>(config.loanLeasingCompany);

    const [comment, setComment] = useState<string | undefined>(undefined);
    const [salesPersonSequence, setSalesPersonSequence] = useState<string | undefined>(undefined);

    const [CaseNotes, setCaseNotes] = useState<Array<CaseNote> | undefined>(undefined);

    const [updateDate, setUpdateDate] = useState<string | undefined>(undefined);
    const [ekundeSamtykke, setEkundeSamtykke] = useState<boolean | undefined>(false);
    const [oneMonthFreeInsurance, setOneMonthFreeInsurance] = useState<boolean | undefined>(false);
    const [sendToEsign, setSendToEsign] = useState<boolean | undefined>(false);

    function setAll(loanapplication: object) {
        
    }

    function clearForm() {
        // Set all values to undefined
        setSequence(undefined);
        setID(undefined);
        setAgreementId(undefined);
        setStatus(undefined);
        setInvoiceAddress(undefined);
        setCoInvoiceAddress(undefined);
        setSchemaCustomerType(undefined);
        setSchemaObjectVariant(undefined);
        setSchemaAgreementVariant(undefined);
        setCalculationResult(undefined);
        setFreeinterestcalc(false);
        setDealerGroupAgreementConnectionID(undefined);
        setDealerGroupAgreementConnection(undefined);
        setSegment(undefined);
        setType(undefined);
        setVariant("Standard");
        setCategory(undefined);
        setDownPaymentSum(undefined);
        setDownPaymentPercentage(undefined);
        setDownPaymentSource(undefined);
        setStartRent(undefined);
        setStartRentPct(undefined);
        setStartRentInclMva(undefined);
        setStartRentInclMvaPct(undefined);
        setStartRentExclMva(undefined);
        setStartRentExclMvaPct(undefined);
        setPurchaseSum(undefined);
        setPurchaseSumExclMva(undefined);
        setPurchaseSumInclMva(undefined);
        setNominalInterestRate(undefined);
        setContractlengthmonths(undefined);
        setDueday(undefined);
        setOnetimeFee(undefined);
        setCaseObjectNew(undefined);
        setRemainingValue(undefined);
        setRemainingValuePct(undefined);
        setExactCar(true);
        setRegnrknown(true);
        setRegnumber(undefined);
        setObjectSequence(undefined);
        setObjectID(undefined);
        setObjectmakeName(undefined);
        setObjectmodelName(undefined);
        setObjecttypeName(undefined);
        setObjectmake(undefined);
        setObjectmakeCode(undefined);
        setObjectmodel(undefined);
        setObjecttype(undefined);
        setChassisnumber(undefined);
        setObjectyear(undefined);
        setUseableKm(undefined);
        setMileage(undefined);
        setDeliveryDate(undefined);
        setPriceExtraKmExclVAT(undefined);
        setPriceExtraKmInclVAT(undefined);
        setCustomerSequence(undefined);
        setCustomerSector(undefined);
        setCustomerSocialSecurityNumber(undefined);
        setCustomerAddresses(undefined);
        setCustomerPhone(undefined);
        setCustomerEmail(undefined);
        setCustomerFirstName(undefined);
        setCustomerLastName(undefined);
        setCustomerCoborrower(false);
        setCoCustomerSequence(undefined);
        setCoCustomerSector(undefined);
        setCoCustomerSocialSecurityNumber(undefined);
        setCoCustomerAddresses(undefined);
        setCoCustomerPhone(undefined);
        setCoCustomerEmail(undefined);
        setCoCustomerFirstName(undefined);
        setCoCustomerLastName(undefined);
        setRequestingInsurance(false);
        setInsuranceCompany(undefined);
        setInsuranceBonus(undefined);
        setInsuranceLowAge(false);
        setInsuranceAnnualKilometers(undefined);
        setInsuranceCoverageTypeCode(undefined);
        setInsuranceDeductibleCode(undefined);
        setInsuranceBonusSource(undefined);
        setInsuranceBonusSourceRegnr(undefined);
        setLoanLeasingCompany(config.loanLeasingCompany);
        setComment(undefined);
        setSalesPersonSequence(undefined);
        setCaseNotes(undefined);
        setUpdateDate(undefined);
        setEkundeSamtykke(false);
        setOneMonthFreeInsurance(false);
        setSendToEsign(false);
    }

    return {
        clearForm,
        setAll,

        Sequence,
        setSequence,
        ID,
        setID,
        agreementId,
        setAgreementId,

        status,
        setStatus,

        invoiceAddress,
        setInvoiceAddress,
        coInvoiceAddress,
        setCoInvoiceAddress,

        SchemaCustomerType,
        setSchemaCustomerType,
        SchemaObjectVariant,
        setSchemaObjectVariant,
        SchemaAgreementVariant,
        setSchemaAgreementVariant,

        calculationResult,
        setCalculationResult,

        freeinterestcalc,
        setFreeinterestcalc,

        dealerGroupAgreementConnectionID,
        setDealerGroupAgreementConnectionID,
        dealerGroupAgreementConnection,
        setDealerGroupAgreementConnection,

        segment,
        setSegment,
        type,
        setType,
        variant,
        setVariant,
        category,
        setCategory,

        downPaymentSum,
        setDownPaymentSum,
        downPaymentPercentage,
        setDownPaymentPercentage,
        downPaymentSource,
        setDownPaymentSource,
        startRent,
        setStartRent,
        startRentPct,
        setStartRentPct,
        startRentInclMva,
        setStartRentInclMva,
        startRentInclMvaPct,
        setStartRentInclMvaPct,
        startRentExclMva,
        setStartRentExclMva,
        startRentExclMvaPct,
        setStartRentExclMvaPct,
        purchaseSum,
        setPurchaseSum,
        purchaseSumExclMva,
        setPurchaseSumExclMva,
        purchaseSumInclMva,
        setPurchaseSumInclMva,
        nominalInterestRate,
        setNominalInterestRate,
        contractlengthmonths,
        setContractlengthmonths,
        dueday,
        setDueday,
        onetimeFee,
        setOnetimeFee,
        caseObjectNew,
        setCaseObjectNew,
        remainingValue,
        setRemainingValue,
        remainingValuePct,
        setRemainingValuePct,
        exactCar,
        setExactCar,
        regnrknown,
        setRegnrknown,
        regnumber,
        setRegnumber,

        objectSequence,
        setObjectSequence,
        objectID,
        setObjectID,
        objectmakeName,
        setObjectmakeName,
        objectmodelName,
        setObjectmodelName,
        objecttypeName,
        setObjecttypeName,
        objectmake,
        setObjectmake,
        objectmakeCode,
        setObjectmakeCode,
        objectmodel,
        setObjectmodel,
        objecttype,
        setObjecttype,
        chassisnumber,
        setChassisnumber,
        objectyear,
        setObjectyear,
        useableKm,
        setUseableKm,
        mileage,
        setMileage,
        deliveryDate,
        setDeliveryDate,
        priceExtraKmExclVAT,
        setPriceExtraKmExclVAT,
        priceExtraKmInclVAT,
        setPriceExtraKmInclVAT,

        customerSequence,
        setCustomerSequence,
        customerSector,
        setCustomerSector,
        customerSocialSecurityNumber,
        setCustomerSocialSecurityNumber,
        customerAddresses,
        setCustomerAddresses,
        customerPhone,
        setCustomerPhone,
        customerEmail,
        setCustomerEmail,
        customerFirstName,
        setCustomerFirstName,
        customerLastName,
        setCustomerLastName,
        customerCoborrower,
        setCustomerCoborrower,
        coCustomerSequence,
        setCoCustomerSequence,
        coCustomerSector,
        setCoCustomerSector,
        coCustomerSocialSecurityNumber,
        setCoCustomerSocialSecurityNumber,
        coCustomerAddresses,
        setCoCustomerAddresses,
        coCustomerPhone,
        setCoCustomerPhone,
        coCustomerEmail,
        setCoCustomerEmail,
        coCustomerFirstName,
        setCoCustomerFirstName,
        coCustomerLastName,
        setCoCustomerLastName,

        requestingInsurance,
        setRequestingInsurance,
        insuranceCompany,
        setInsuranceCompany,
        insuranceBonus,
        setInsuranceBonus,
        insuranceLowAge,
        setInsuranceLowAge,
        insuranceAnnualKilometers,
        setInsuranceAnnualKilometers,
        insuranceCoverageTypeCode,
        setInsuranceCoverageTypeCode,
        insuranceDeductibleCode,
        setInsuranceDeductibleCode,
        insuranceBonusSource,
        setInsuranceBonusSource,
        insuranceBonusSourceRegnr,
        setInsuranceBonusSourceRegnr,
        loanLeasingCompany,
        setLoanLeasingCompany,

        comment,
        setComment,
        salesPersonSequence,
        setSalesPersonSequence,

        CaseNotes,
        setCaseNotes,

        updateDate,
        setUpdateDate,

        ekundeSamtykke,
        setEkundeSamtykke,
        
        oneMonthFreeInsurance,
        setOneMonthFreeInsurance,
        
        sendToEsign,
        setSendToEsign,
    };
}