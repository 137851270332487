export const config = {
    title: process.env.REACT_APP_TITLE || "${REACT_APP_TITLE}",
    meta: {
        name: process.env.REACT_APP_META_NAME || "${REACT_APP_META_NAME}",
        content: process.env.REACT_APP_META_CONTENT || "${REACT_APP_META_CONTENT}"
    },
    region: process.env.REACT_APP_REGION || "${REACT_APP_REGION}",
    giosg: process.env.REACT_APP_GIOSG || "${REACT_APP_GIOSG}",
    loanLeasingCompany: process.env.REACT_APP_LOANLEASINGCOMPANY || "${REACT_APP_LOANLEASINGCOMPANY}"
}

export const replaceEnvVariables = () => {
    try {
        let element = document.getElementById('title-variable');
        if (element) element.innerHTML = config.title || 'Sparebank1 Forhandlerportal';
        element = document.getElementById('meta-variables');
        if (element) {
            element.setAttribute('name', config.meta.name || 'Sparebank1 Forhandlerportal');
            element.setAttribute('content', config.meta.content || 'Sparebank1 Forhandlerportal');
        }
    } catch (err) {
        console.log('Could not replace environment variables: ', err);
    }
}