import React from "react";

import styles from './SalesmanSalesYear.module.scss';
import ContentCard from "../../../../Shared/ContentCard/ContentCard";
import StatisticsCompetitionTable from "../../../../Shared/StatisticsCompetitionTable/StatisticsCompetitionTable";

function SalesmanSalesYear(props: SalesmanSalesYearProps) {
    const columns = [ 'Selger', 'Volum', 'Snitt nysalgsrente' ];

    const data = [
        { 0: 'Sivert Volden', 1: 17, 2: 2.95 },
        { 0: 'Knut Midtstrand', 1: 14, 2: 3.14 },
        { 0: 'Tor Andre Frostheim', 1: 14, 2: 3.25 },
        { 0: 'Nils Roar Vestvik', 1: 9, 2: 3.14 },
        { 0: 'Birgitte Rauland Schmidt', 1: 6, 2: 2.95 },
        { 0: 'Erik Høgset', 1: 5, 2: 3.14 },
        { 0: 'Thomas Pedersen', 1: 5, 2: 2.96 },
        { 0: 'Bjørn Roar Rønning', 1: 5, 2: 3.02 },
        { 0: 'Erik Bøe', 1: 4, 2: 3.14 },
        { 0: 'Bjørn Arne Høgset', 1: 3, 2: 3.12 },
        { 0: 'Morten Bøe', 1: 3, 2: 3.14 },
        { 0: 'Tore Løken', 1: 3, 2: 3.14 },
        { 0: 'Erik Høgset', 1: 3, 2: 3.14 },
        { 0: 'Rune Sørensen', 1: 3, 2: 3.14 },
        { 0: 'Sveinung Rønning', 1: 3, 2: 3.14 },
        { 0: 'Morten Bøe', 1: 3, 2: 3.14 },
        { 0: 'Tore Løken', 1: 3, 2: 3.14 },
        { 0: 'Erik Høgset', 1: 3, 2: 3.14 },
        { 0: 'Rune Sørensen', 1: 3, 2: 3.14 },
        { 0: 'Sveinung Rønning', 1: 3, 2: 3.14 },
    ];

    return (
        <div className={styles.salesmansalesyear}>
            <ContentCard
                title="Salg per selger inneværende år"
                open={true}
                disableClick={true}
            >
                <StatisticsCompetitionTable
                    columns={columns}
                    data={props.data}
                />
            </ContentCard>
        </div>
    );
}

interface SalesmanSalesYearProps {
    data: any
}

export default SalesmanSalesYear;