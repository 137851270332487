import React, { useEffect, useRef, useState } from "react";
import { useNavigate, useLocation, useSearchParams } from "react-router-dom";
import { useAuth } from "../../../Auth";
import { useAxios } from '../../../AxiosHandler';
import Tooltip from '@mui/material/Tooltip';

import styles from './InsuranceOverview.module.scss';
import ContentCard from "../../Shared/ContentCard/ContentCard";
import { Tab, TabGroup } from "@sb1/ffe-tabs-react";
import { Grid, GridCol, GridRow } from "@sb1/ffe-grid-react";
import ArchiveItem from "../../Shared/ArchiveItem/ArchiveItem";
import { Insuranceapplication } from "../../../model/Insuranceapplication";
import SplitButton from "../../Shared/SplitButton/SplitButton";
import SearchField from "../../Shared/SearchField/SearchField";
import Spinner from "@sb1/ffe-spinner-react";
import { toast } from 'react-toastify';
import moment from "moment";
import general from "../../../functions/general";
import Pill from "../../Shared/Pill/Pill";
import customerFunctions from "../../../functions/customerFunctions";
import { ActionButton } from "@sb1/ffe-buttons-react";
import { Input, InputGroup, Tooltip as SB1Tooltip } from "@sb1/ffe-form-react";
import Dropdown from "@sb1/ffe-dropdown-react";
import { formatRegistrationNumber } from "../../../functions/dataSanitation";

function Insuranceoverview() {
    const auth = useAuth();
    const axiosHandler = useAxios();
    const axios = axiosHandler.axios;
    const navigate = useNavigate();
    const location = useLocation();
    const [searchParams, setSearchParams] = useSearchParams();

    const [allCases, setAllCases] = useState<Array<Insuranceapplication>>([]);
    const [filteredCases, setFilteredCases] = useState<Array<Insuranceapplication>>([]);
    const [shownCases, setShownCases] = useState<Array<Insuranceapplication>>([]);
    const [showAllCases, setShowAllCases] = useState<boolean>(false);
    const [selectedCase, setSelectedCase] = useState<Insuranceapplication | null>(null);
    const [selectedCaseHasError, setSelectedCaseHasError] = useState<boolean>(false);
    const [selectedCaseErrorMessage, setSelectedCaseErrorMessage] = useState<string>("");
    const [selectedCaseErrorCode, setSelectedCaseErrorCode] = useState<string>("");
    const [archiveOpen, setArchiveOpen] = useState<boolean>(true);
    const [activeItem, setActiveItem] = useState<string>("");
    const [activatingInsurance, setActivatingInsurance] = useState<boolean>(false);

    const [selectedCaseChassisNumber, setSelectedCaseChassisNumber] = useState<string | undefined>(undefined);
    const [selectedCaseInsuranceBonusSource, setSelectedCaseInsuranceBonusSource] = useState<string | undefined>(undefined);
    const [selectedCaseInsuranceBonusSourceRegnr, setSelectedCaseInsuranceBonusSourceRegnr] = useState<string | undefined>(undefined);
    
    const [insuranceBonusOptions, setInsuranceBonusOptions] = useState<Array<any>>([]);
    const [insuranceCompaniesOptions, setInsuranceCompaniesOptions] = useState<Array<any>>([]);

    const [objectInfo, setObjectInfo] = useState<any>(undefined);
    const [loadingObject, setLoadingObject] = useState<boolean>(false);

    const [customerInfo, setCustomerInfo] = useState<any>(undefined);

    const [searchValue, setSearchValue] = useState<string>("");
    const [showMore, setShowMore] = useState<number>(0);

    const [loading, setLoading] = useState<boolean>(false);
    const [loadingCase, setLoadingCase] = useState<boolean>(false);
    const [loadingCustomer, setLoadingCustomer] = useState<boolean>(false);
    
    const fetchWaitingTimer = useRef<number>(0);

    function filterCases() {
        let tempfilter = allCases.filter((item) => {
            let textMatch = false;
            if (searchValue == "") textMatch = true;
            if (item.avtaleNummer?.toString().includes(searchValue)) textMatch = true;
            if (item.eierMobil?.toString().includes(searchValue)) textMatch = true;
            if (item.kundeNummer?.toString().includes(searchValue)) textMatch = true;
            if (item.regnr?.toString().includes(searchValue)) textMatch = true;
            if (item.eierFodselsnummer?.toString().includes(searchValue)) textMatch = true;
            if (item.chassisNummer?.toString().toLowerCase().includes(searchValue.toLowerCase())) textMatch = true;

            if (textMatch) return true;
            return false;
        });

        setFilteredCases(tempfilter);
        setShownCases(tempfilter.slice(0, 15 + showMore));
    }

    function fetchInsuranceBonuses() {
        axios.single({
            url: "/api/fremtind/kodeverk/bonus",
            method: "GET",
            headers: {
                "UserAuthorization": "Bearer " + (JSON.parse(localStorage.getItem("logindata") ?? "")["JWT"])
            }
        }).then((res: any) => {
            if (res.data.bonusLevels != undefined) {
                setInsuranceBonusOptions(res.data.bonusLevels.filter((x: any) => x.isActive).map((x: any) => {
                    return {
                        value: x.key,
                        label: x.caption
                    }
                }));
            } else {
                toast.error("Innlasting av bonuser feilet!");
                if (res.data.error != undefined) {
                    toast.error("Fremtind | " + res.data.error.errorCode + ": " + res.data.error.errorMessage);
                }
            }
        }).catch((err: any) => {
            console.log(err);
            toast.error("Innlasting av bonus feilet!");
        });
    }

    function fetchInsuranceCompanies() {
        axios.single({
            url: "/api/company/insurancecompanies",
            method: "GET",
            headers: {
                "UserAuthorization": "Bearer " + (JSON.parse(localStorage.getItem("logindata") ?? "")["JWT"])
            }
        }).then((res: any) => {
            setInsuranceCompaniesOptions(res.data?.map((x: any) => {
                return {
                    value: x.Sequence,
                    label: x.CompanyName
                }
            }));
        }).catch((err: any) => {
            console.log(err);
            toast.error("Innlasting av forsikringsselskaper feilet!");
        });
    }

    function activateInsurance(caseId: string | undefined) {
        setActivatingInsurance(true);
        if (caseId == undefined) return;
        axios.single({
            url: "/api/fremtind/bil/kjop",
            method: "POST",
            headers: {
                "UserAuthorization": "Bearer " + (JSON.parse(localStorage.getItem("logindata") ?? "")["JWT"]),
                "X-Request-Id": general.generateXRequestId(),
            },
            data: {
                "ID": caseId,
                "dealerSequence": auth.selectedDealer?.Sequence,
                "chassisNummer": selectedCase?.chassisnumber,
                "bonusFraSelskap": selectedCase?.insuranceBonusSource,
                "bonusFraRegnr": formatRegistrationNumber(selectedCase?.insuranceBonusSourceRegnr || "", true),
            }
        }).then((res: any) => {
            toast.dismiss();
            if(res.data?.Message != undefined){
                toast.error(res.data?.Message)
            } else {
                toast.success("Aktivering sendt!");
            }
            setActivatingInsurance(false);
        }).catch((err: any) => {
            toast.dismiss();
            console.log(err);
            toast.error("Kunne ikke aktivere forsikring!");
            toast.error(err.response.data);
            setActivatingInsurance(false);
        });
    }

    function fetchObjectInfoFremtind(year: string, makeId: string, modelId: string, variantId: string) {
        setObjectInfo(undefined);
        setLoadingObject(true);
        axios.single({
            url: "/api/fremtind/custom/object/infobytypeids/" + year + "/" + makeId + "/" + modelId + "/" + variantId,
            method: "GET",
            headers: {
                "UserAuthorization": "Bearer " + (JSON.parse(localStorage.getItem("logindata") ?? "")["JWT"])
            }
        }).then((res: any) => {
            setObjectInfo(res.data);
            setLoadingObject(false);
        }).catch((err: any) => {
            console.log(err);
            toast.error("Innlasting av objektinfo feilet!");
        });
    }

    async function searchRegistrationNumber(regnum: string) {
        setObjectInfo(undefined);
        setLoadingObject(true);
        toast("Søker etter objekt ..", {
            autoClose: false
        });
        axios.single({
            url: "/api/caseobjects/" + formatRegistrationNumber(regnum, true),
            method: "GET",
            headers: {
                "Content-Type": "application/json",
                "UserAuthorization": "Bearer " + (JSON.parse(localStorage.getItem("logindata") ?? "")["JWT"])
            }
        }).then((res: any) => {
            let objectmake = res.data.kjoretoydataListe[0].godkjenning.tekniskGodkjenning.tekniskeData.generelt.merke[0].merke;
            let objectmodel = res.data.kjoretoydataListe[0].godkjenning.tekniskGodkjenning.tekniskeData.generelt.handelsbetegnelse[0];

            setObjectInfo({
                make: {
                    merkenavn: objectmake
                },
                model: {
                    modellnavn: objectmodel
                },
                variant: {
                    variantnavn: ""
                },
            });

            setLoadingObject(false);
            toast.dismiss();
        }).catch((err: any) => {
            toast.dismiss();
            console.log(err);
            toast.error("Søk på registreringsnummeret feilet! Vennligst prøv igjen");
        });
    }

    function fetchCustomerInfo(customerSequence: string) {
        setLoadingCustomer(true);
        customerFunctions.searchPerson(toast, axios, auth, customerSequence).then((data: any) => {
            setCustomerInfo(data);
            toast.dismiss();
            setLoadingCustomer(false);
        }).catch((err: any) => {
            console.log(err);
            toast.dismiss();
            setLoadingCustomer(false);
        });
    }

    function fetchInsuranceCases(all: boolean = true) {
        setLoading(true);
        setAllCases([]);
        setFilteredCases([]);
        setShownCases([]);
        let fetchingTimer = window.setTimeout(() => {
            toast("Henting av saker pågår. Vennligst vent ...", { autoClose: false });
        }, 4000);
        fetchWaitingTimer.current = fetchingTimer;
        axios.single({
            url: "/api/fremtind/bil/insurancecase" + ("?dealerSequence=" + auth.selectedDealer.Sequence) + (all ? "" : ("&userSequence=" + auth.user.userdata?.Sequence)),
            method: "GET",
            headers: {
                "X-Request-Id": general.generateXRequestId(),
                "UserAuthorization": "Bearer " + (JSON.parse(localStorage.getItem("logindata") ?? "")["JWT"])
            }
        }).then((res: any) => {
            setLoading(false);
            window.clearTimeout(fetchWaitingTimer.current);
            toast.dismiss();

            if (res.data == undefined) { toast.error("Henting av saker feilet. Vennligst prøv igjen"); return; }
            if (res.data.error != undefined) { 
                if (res.data.error.errorMessage != undefined && res.data.error.errorMessage != "")
                    toast.error("Henting av saker feilet. Error: " + res.data.error.errorMessage);
                else
                    toast.error("Henting av saker feilet. Vennligst prøv igjen");
                return;
            }
            if (res.data != undefined && res.data == "") { toast.info("Fant ingen saker"); return; }

            setAllCases(res.data.sort((a: any, b: any) => {
                if (new Date(a.UpdateDate) > new Date(b.UpdateDate)) return -1;
                if (new Date(a.UpdateDate) < new Date(b.UpdateDate)) return 1;
                return 0;
            }));
        }).catch((err: any) => {
            console.log(err);
            setLoading(false);
            window.clearTimeout(fetchWaitingTimer.current);
            toast.dismiss();
            toast.error("Henting av saker feilet. Vennligst prøv igjen");
        });
    }

    function fetchSingleInsuranceCase(caseId: string | undefined) {
        setSelectedCaseHasError(false);
        if (caseId == undefined) return;
        setObjectInfo(undefined);
        setCustomerInfo(undefined);
        setLoadingCase(true);
        setSelectedCase(null);
        axios.single({
            url: "/api/fremtind/bil/insurancecase/" + caseId,
            method: "GET",
            headers: {
                "X-Request-Id": general.generateXRequestId(),
                "UserAuthorization": "Bearer " + (JSON.parse(localStorage.getItem("logindata") ?? "")["JWT"])
            }
        }).then((res: any) => {
            toast.dismiss();

            if (res.data == undefined) { toast.error("Henting av sak feilet. Vennligst prøv igjen"); return; }
            if (res.data.error != undefined) { 
                if (res.data.error.errorMessage != undefined && res.data.error.errorMessage != "")
                    toast.error("Henting av sak feilet. Error: " + res.data.error.errorMessage);
                else
                    toast.error("Henting av sak feilet. Vennligst prøv igjen");
                return;
            }
            if (res.data != undefined && res.data == "") { toast.info("Fant ingen saker"); return; }

            setSelectedCaseChassisNumber(res.data.chassisNummer);
            setSelectedCaseInsuranceBonusSource(res.data.insuranceBonusSource);
            setSelectedCaseInsuranceBonusSourceRegnr(res.data.insuranceBonusSourceRegnr);
            setLoadingCase(false);
            setSelectedCase(res.data);

            if(res.data.ErrorResponse != undefined){
                if(/^(FIP|fip)-.*/.test(res.data.ErrorResponse.Code)){
                    toast.error("Feil ved henting av sak fra Fremtind. Feilkode: " + res.data.ErrorResponse.Code);
                    setSelectedCaseErrorMessage(res.data.ErrorResponse.Message);
                    setSelectedCaseErrorCode(res.data.ErrorResponse.Code);
                    setSelectedCaseHasError(true);
                    return;
                }
            }

            if (res.data.regnr == undefined || res.data.regnr == "") {
                fetchObjectInfoFremtind(res.data.registreringsAar, res.data.merkenr, res.data.modellnr, res.data.variantnr);
            } else {
                searchRegistrationNumber(res.data.regnr);
            }
            fetchCustomerInfo(res.data.eierFodselsnummer)
        }).catch((err: any) => {
            console.log(err);
            setLoadingCase(false);
            toast.dismiss();
            toast.error("Henting av saker feilet. Vennligst prøv igjen");
        });
    }

    function openInsuranceapplication() {
        auth.setCurrentInsuranceapplication({
            ...new Insuranceapplication(),
            ...selectedCase,
            category: selectedCase?.produkt,
            purchaseSum: undefined,
            regnrknown: selectedCase?.regnr != undefined && selectedCase?.regnr != "",
            regnumber: selectedCase?.regnr,
            objectyear: Number(selectedCase?.registreringsAar),
            objectmake: selectedCase?.merkenr,
            objectmakeName: objectInfo?.make?.merkenavn,
            objectmodel: selectedCase?.modellnr,
            objectmodelName: objectInfo?.model?.modellnavn,
            objecttype: selectedCase?.variantnr,
            objecttypeName: objectInfo?.variant?.variantnavn,
            chassisnumber: selectedCase?.chassisNummer,
            mileage: Number(selectedCase?.kilometerstand),
            insuranceAnnualKilometers: selectedCase?.kjorelengdeAar,
            insuranceBonus: selectedCase?.bonus,
            insuranceBonusSource: selectedCase?.bonusFraSelskap ?? undefined,
            insuranceBonusSourceRegnr: selectedCase?.bonusFraRegnr ?? undefined,
            insuranceLowAge: selectedCase?.yngreSjaafor,
            loanLeasing: selectedCase?.leasing ? "leasing" : selectedCase?.belaanesMedPant ? "loan" : "none",
            loanLeasingCompany: selectedCase?.panthaverKundenummer,
            customerPhone: selectedCase?.eierMobil,
            customerEmail: selectedCase?.eierEpost,
        });

        navigate("/insuranceapplication");
    }

    useEffect(() => {
        filterCases();
    }, [searchValue, allCases]);

    useEffect(() => {
        fetchInsuranceBonuses();
        fetchInsuranceCompanies();

        let insuranceId = searchParams?.get("insuranceid");
        if (insuranceId != undefined) {
            setActiveItem(insuranceId as any);
            fetchSingleInsuranceCase(insuranceId as any);
            if (window.innerWidth < 768) {
                setArchiveOpen(false);
            }
        }
    }, []);

    useEffect(() => {
        if (auth.selectedDealer?.Sequence != undefined) {
            fetchInsuranceCases(showAllCases);
        }
    }, [showAllCases, auth.selectedDealer?.Sequence]);

    useEffect(() => {
        if (showMore != 0) {
            setShownCases(filteredCases.slice(0, 15 + showMore));
        }
    }, [showMore]);

    useEffect(() => {
        setSelectedCase(null);
    }, []);

    return (
        <div className={styles.insuranceoverview}>
            <Grid className={styles.grid}>
                <GridRow>
                    <GridCol lg="6" md="12" sm="12">
                        <ContentCard
                            open={archiveOpen}
                            onClick={(val) => setArchiveOpen(val)}
                            title="Forsikringssoversikt"
                        >
                            <div className={styles.tabgroup}>
                                <TabGroup>
                                    <Tab
                                        selected={!showAllCases}
                                        onClick={() => {
                                            setShowAllCases(false);
                                        }}
                                        aria-controls={"mycases"}
                                    >
                                        Mine saker
                                    </Tab>
                                    <Tab
                                        selected={showAllCases}
                                        onClick={() => {
                                            setShowAllCases(true);
                                        }}
                                        aria-controls={"allcases"}
                                    >
                                        Alle saker
                                    </Tab>
                                </TabGroup>
                            </div>
                            &nbsp;
                            <SearchField
                                onClick={(value) => {
                                    fetchInsuranceCases();
                                }}
                                onEnter={(value) => {
                                    
                                }}
                                onChange={(value) => {
                                    setSearchValue(value);
                                }}
                                placeholder="Søkefelt"
                            ></SearchField>
                            &nbsp;
                            <div className={styles.loanitemslist}>
                                <hr/>
                                {loading ? 
                                    <Spinner large={true} />
                                : null}
                                { shownCases.map((item, key) => {
                                    return <>
                                        <ArchiveItem
                                            key={key}
                                            className={activeItem == item.sequence?.toString() || activeItem == item.ID ? styles.activeloanitem : '' }
                                            date={moment(item.UpdateDate || "").format("DD.MM.YYYY - HH:mm")}
                                            pills={item.bonus ? [
                                                <Pill
                                                    key={"pill" + 1}
                                                    text={insuranceBonusOptions?.find(i => i.value == item.bonus)?.label || (item.bonus + "% bonus")}
                                                ></Pill>
                                            ] : []}
                                            title={
                                                item.kundeNummer != undefined ?
                                                    (item.kundeNummer + (item.eierMobil != undefined ?
                                                        (" (" + item.eierMobil + ")")
                                                    : ""))
                                                : "Ingen kunde"
                                            }
                                            subtitle1={ 
                                                (item?.merkenavn ? (item.merkenavn + " ") : "") + 
                                                (item?.modellnavn ? (item.modellnavn + " ") : "") + 
                                                (
                                                    item.regnr != undefined ?
                                                        item.regnr
                                                    :
                                                        (item.chassisNummer != undefined ?
                                                            item.chassisNummer
                                                        : "")
                                                )
                                            }
                                            id={item.avtaleNummer != undefined ? (item.avtaleNummer + " " + (general.capitalize(item?.Status) ?? "")) : "Ikke sendt til Fremtind"}
                                            onClick={() => {
                                                if (window.innerWidth < 768) {
                                                    setArchiveOpen(false);
                                                }

                                                setActiveItem(item.sequence?.toString() || item.ID || "");
                                                // Smooth scroll to top
                                                window.scrollTo({
                                                    top: 0,
                                                    behavior: 'smooth'
                                                });

                                                fetchSingleInsuranceCase(item.ID);
                                                setSearchParams(new URLSearchParams({insuranceid: (item.sequence?.toString() || item.ID || "").toString()}));
                                            }}
                                        ></ArchiveItem>
                                        <hr/>
                                    </>;
                                })}
                                { shownCases.length == 0 && !loading ?
                                    <p
                                        className={styles.nocases}
                                    >
                                        Ingen saker funnet
                                    </p>
                                : null }
                                { shownCases.length < filteredCases.length ?
                                    <p
                                        className={styles.showmore}
                                        onClick={() => {
                                            setShowMore(showMore + 15);
                                        }}
                                    >Vis flere ..</p>
                                : null }
                            </div>
                        </ContentCard>
                    </GridCol>
                    <GridCol className={styles.rightColumn} lg="6" md="12" sm="12">
                        <div className={selectedCase && selectedCaseHasError ? styles.blur : ""}>
                            <ContentCard open={selectedCase != null} disabled={selectedCase == null} title="Oppsummering">
                                {loadingCase ? 
                                    <Spinner large={true} />
                                : null}
                                <div className={styles.loanactions}>
                                    <SplitButton
                                        className={styles.editloanbutton}
                                        buttontext={"Rediger"}
                                        onClick={() => {
                                            openInsuranceapplication();
                                        }}
                                        disabled={selectedCase?.produkt == "CAMPINGVOGN"}
                                        // altOptions={[
                                        //     {
                                        //         text: "Kanseller",
                                        //         onClick: () => {
                                        //             alert("Not yet implemented");
                                        //         }
                                        //     }
                                        // ]}
                                    ></SplitButton>
                                </div>
                                <div className={styles.loaninfoarea}>
                                    <h2>{
                                        selectedCase?.kundeNummer != undefined ?
                                            (selectedCase?.kundeNummer + (selectedCase?.eierMobil != undefined ?
                                                (" (" + selectedCase?.eierMobil + ")")
                                            : ""))
                                        : "Ingen kunde"
                                    }</h2>
                                    <p><span>Saksnummer Fremtind:</span><span>{selectedCase?.avtaleNummer != undefined ? (selectedCase?.avtaleNummer + " " + general.capitalize(selectedCase?.Status)) : "Ikke sendt"}</span></p>
                                    <p><span>Sist endret:</span><span>{moment(selectedCase?.updateDate || "").format("DD.MM.YYYY - HH:mm")}</span></p>
                                    {/* <p><span>Av:</span><span>{selectedCase?.salgsAgentId}</span></p> */}
                                </div>
                                <hr/>
                                <div className={styles.loaninfoarea}>
                                    <p><span>Kundens navn:</span><span>{customerInfo != undefined ? ((customerInfo?.LastName ?? "N/A") + ", " + (customerInfo?.FirstName ?? "N/A")) : loadingCustomer ? <Spinner /> : ""}</span></p>
                                    <p><span>Bonus:</span><span>{insuranceBonusOptions?.find(i => i.value == selectedCase?.bonus)?.label || ""}</span></p>
                                    <p><span>Kjørelengde:</span><span>{selectedCase?.kjorelengdeAar ? selectedCase?.kjorelengdeAar + " 000 km" : "Ikke oppgitt"}</span></p>
                                    <p><span>Fører under 23 år:</span><span>{selectedCase?.yngreSjaafor ? "Ja" : "Nei"}</span></p>
                                </div>
                                { selectedCase?.Status == "tilbud" ?
                                    <>
                                        { selectedCase?.regnr == null ? 
                                            <InputGroup
                                                label="Chassisnummer (kreves for forsikring)"
                                                tooltip="Chassisnummer på finansieringsobjektet. 9 tegn. Kreves for bestilling av forsikring uten regnummer."
                                                fieldMessage={
                                                    selectedCaseChassisNumber != undefined &&
                                                    selectedCaseChassisNumber != "" &&
                                                    selectedCaseChassisNumber.length != 9
                                                        ? "Ugyldig chassisnummer. Må være 9 tegn."
                                                        : null
                                                }
                                            >
                                                {inputProps => (
                                                    <Input
                                                        type="text"
                                                        name="chassisnumber"
                                                        value={selectedCaseChassisNumber || ""}
                                                        onBlur={() => {
                                                            let tempSelectedCase = JSON.parse(JSON.stringify(selectedCase));
                                                            setSelectedCase({
                                                                ...tempSelectedCase,
                                                                chassisnumber: selectedCaseChassisNumber
                                                            });
                                                        }}
                                                        onChange={e => {
                                                            let value: string | undefined = e.target.value;
                                                            if(value.trim().length == 0){
                                                                value = undefined;
                                                            }
                                                            setSelectedCaseChassisNumber(value);
                                                        }}
                                                        {...inputProps}
                                                    />
                                                )}
                                            </InputGroup>
                                        : null }
                                        { Number(selectedCase?.bonus || 0) > 60 ?
                                            <>
                                                <InputGroup
                                                    extraMargin={false}
                                                    label="Hvilket forsikringsselskap har du bonusen hos?"
                                                    tooltip="Hva er kundens nåværende forsikringsselskap med bonusen?"
                                                >
                                                    <Dropdown
                                                        value={selectedCase?.insuranceBonusSource || "choose"}
                                                        onBlur={() => {
                                                            let tempSelectedCase = JSON.parse(JSON.stringify(selectedCase));
                                                            setSelectedCase({
                                                                ...tempSelectedCase,
                                                                insuranceBonusSource: selectedCaseInsuranceBonusSource
                                                            });
                                                        }}
                                                        onChange={e => {
                                                            let value: string | undefined = e.target.value;
                                                            if(value.trim().length == 0){
                                                                value = undefined;
                                                            }
                                                            setSelectedCaseInsuranceBonusSource(value);
                                                        }}
                                                    >
                                                        <option value="choose" disabled>
                                                            Velg selskap
                                                        </option>
                                                        { insuranceCompaniesOptions?.map((option: any, index: any) =>
                                                            <option key={index} value={option.value}>
                                                                {option.label}
                                                            </option>
                                                        )}
                                                    </Dropdown>
                                                </InputGroup>
                                                &nbsp;
                                                <InputGroup
                                                    label="Regnummer på objektet som har bonusen"
                                                    tooltip="Registreringsnummer på objektet med bonusen"
                                                >
                                                    {inputProps => (
                                                        <Input
                                                            type="text"
                                                            name="regnumber"
                                                            value={selectedCaseInsuranceBonusSourceRegnr || ""}
                                                            onBlur={ () => {
                                                                let tempSelectedCase = JSON.parse(JSON.stringify(selectedCase));
                                                                setSelectedCase({
                                                                    ...tempSelectedCase,
                                                                    insuranceBonusSourceRegnr: formatRegistrationNumber(selectedCaseInsuranceBonusSourceRegnr || "")
                                                                })
                                                            }}
                                                            onChange={e => {
                                                                let value: string | undefined = e.target.value;
                                                                if(value.trim().length == 0){
                                                                    value = undefined;
                                                                }
                                                                setSelectedCaseInsuranceBonusSourceRegnr(value);
                                                            }}
                                                            {...inputProps}
                                                        />
                                                    )}
                                                </InputGroup>
                                                &nbsp;
                                            </>
                                        : null }
                                        <hr/>
                                        <div className={styles.insuranceactivation}>
                                            <ActionButton
                                                disabled={activatingInsurance}
                                                onClick={() => {
                                                    activateInsurance(selectedCase?.ID);
                                                }}
                                            >Aktiver forsikring</ActionButton>
                                        </div>
                                    </>
                                : null }
                            </ContentCard>
                            <ContentCard disabled={selectedCase == null} title="Forsikringsdetaljer">
                                <div className={styles.loandetails}>
                                    <hr/>
                                    <p>
                                        <span>
                                            Månedsbeløp
                                            <Tooltip
                                                title={"Månedlige betalinger varierer litt fra måned til måned, men årsprisen blir den samme. Beløpet er inkl trafikkforsikringsavgift."}
                                                placement="top"
                                                enterTouchDelay={0}
                                            >
                                                <span className={"material-symbols-outlined"}>
                                                    help
                                                </span>
                                            </Tooltip>
                                        </span>
                                        <span>{general.formatPrice(Number(selectedCase?.offerDetail?.monthlyFee), false, true) ?? ""}</span>
                                    </p>
                                    <hr/>
                                    <p>
                                        <span>
                                            Årlig kostnad
                                            <Tooltip
                                                title={"Årlig kostnad inkl trafikkforsikringsavgift."}
                                                placement="top"
                                                enterTouchDelay={0}
                                            >
                                                <span className={"material-symbols-outlined"}>
                                                    help
                                                </span>
                                            </Tooltip>
                                        </span>
                                        <span>{general.formatPrice(Number(selectedCase?.offerDetail?.yearlyFee), false, true) ?? ""}</span>
                                    </p>
                                    <hr/>
                                    <p>
                                        <span>Trafikkforsikringsavgift/år</span>
                                        <span>{general.formatPrice(Number(selectedCase?.offerDetail?.coverageTypes?.find((i: any) => i.id == "990")?.premiumDetails?.premium ?? 0), false, true) ?? ""}</span>
                                    </p>
                                    <hr/>
                                    <p>
                                        <span>Produkt</span>
                                        <span>{general.getReadableFremtindProductname(selectedCase?.produkt ?? "")}</span>
                                    </p>
                                    <hr/>
                                    <p>
                                        <span>Bonus</span>
                                        <span>{insuranceBonusOptions?.find(i => i.value == selectedCase?.bonus)?.label || (selectedCase?.bonus ? selectedCase?.bonus + "% bonus" : "")}</span>
                                    </p>
                                    <hr/>
                                    <p>
                                        <span>Bonus fra selskap</span>
                                        <span>{insuranceCompaniesOptions?.find(i => i.value == selectedCase?.bonusFraSelskap)?.label || "Ikke oppgitt"}</span>
                                    </p>
                                    <hr/>
                                    <p>
                                        <span>Kjørelengde</span>
                                        <span>{selectedCase?.kjorelengdeAar ? selectedCase?.kjorelengdeAar + " 000" : "Ikke funnet"}</span>
                                    </p>
                                    <hr/>
                                    <p>
                                        <span>Fører under 23 år</span>
                                        <span>{selectedCase?.yngreSjaafor ? "Ja" : "Nei"}</span>
                                    </p>
                                    <hr/>
                                    <p>
                                        <span>Belånes med pant</span>
                                        <span>{selectedCase?.belaanesMedPant ? "Ja" : "Nei"}</span>
                                    </p>
                                    <hr/>
                                    <p>
                                        <span>Leasing</span>
                                        <span>{selectedCase?.leasing ? "Ja" : "Nei"}</span>
                                    </p>
                                </div>
                            </ContentCard>
                            <ContentCard disabled={selectedCase == null} title="Objekt">
                                <div className={styles.vehicle}>
                                    <hr/>
                                    <p>
                                        <span>Merke</span>
                                        <span>{ !loadingObject ?
                                            objectInfo?.make?.merkenavn != undefined && objectInfo?.make?.merkenavn != "" ?
                                                objectInfo?.make?.merkenavn + (selectedCase?.regnr != undefined && selectedCase?.regnr != "" ? " *" : "")
                                            :
                                                "Ikke funnet"
                                        :
                                            "Laster inn .."
                                        }</span>
                                    </p>
                                    <hr/>
                                    <p>
                                        <span>Modell</span>
                                        <span>{ !loadingObject ?
                                            objectInfo?.model?.modellnavn != undefined && objectInfo?.model?.modellnavn != "" ?
                                                objectInfo?.model?.modellnavn + (selectedCase?.regnr != undefined && selectedCase?.regnr != "" ? " *" : "")
                                            :
                                                "Ikke funnet"
                                        :
                                            "Laster inn .."
                                        }</span>
                                    </p>
                                    <hr/>
                                    <p>
                                        <span>Registeringsnummer</span>
                                        <span>{selectedCase?.regnr}</span>
                                    </p>
                                    <hr/>
                                    <p>
                                        <span>Chassisnummer</span>
                                        <span>{selectedCase?.chassisNummer}</span>
                                    </p>
                                    <hr/>
                                    <p>
                                        <span>Årsmodell</span>
                                        <span>{selectedCase?.registreringsAar}</span>
                                    </p>
                                    <hr/>
                                    <p>
                                        <span>Kilometerstand</span>
                                        <span>{selectedCase?.kilometerstand ?? "Ikke oppgitt"}</span>
                                    </p>
                                </div>
                                { selectedCase?.regnr != undefined && selectedCase?.regnr != "" ?
                                    <>
                                        <hr/>
                                        <p>* Verdi hentet basert på regnummer</p>
                                    </>
                                : null }
                            </ContentCard>
                            <ContentCard disabled={selectedCase == null} title="Kundeopplysninger">
                                <div className={styles.personalinfo}>
                                    <hr/>
                                    <p>
                                        <span>Kundenummer (Fremtind)</span>
                                        <span>{selectedCase?.kundeNummer}</span>
                                    </p>
                                    <hr/>
                                    {/* <p>
                                        <span>Kundenummer (SB1)</span>
                                        <span>{selectedCase?.CustomerSequence}</span>
                                    </p>
                                    <hr/> */}
                                    <p>
                                        <span>Navn</span>
                                        <span>{customerInfo != undefined ? ((customerInfo?.LastName ?? "N/A") + ", " + (customerInfo?.FirstName ?? "N/A")) : ""}</span>
                                    </p>
                                    <hr/>
                                    {/* <p>
                                        <span>Personnummer</span>
                                        <span style={{
                                            filter: customerInfo != undefined ? undefined : "blur(3px)"
                                        }}>{customerInfo?.Personid ?? "12345678923"}</span>
                                    </p>
                                    <hr/> */}
                                    <p>
                                        <span>E-post</span>
                                        <span>{customerInfo?.Email || selectedCase?.eierEpost || "Ikke funnet"}</span>
                                    </p>
                                    <hr/>
                                    <p>
                                        <span>Telefonnummer</span>
                                        <span>{customerInfo?.Mobile || selectedCase?.eierMobil || "Ikke funnet"}</span>
                                    </p>
                                    {selectedCase?.Status != "Utbetalt" ?
                                        /* selectedCase?.CustomerSector === "8500" ? */
                                            <>
                                                <hr/>
                                                <p>
                                                    <span>Personnummer</span>
                                                    <span>{customerInfo?.Personid}</span>
                                                </p>
                                            </>
                                        /* : 
                                            <>
                                                <hr/>
                                                <p>
                                                    <span>Organisasjonsnummer</span>
                                                    <span>{customerInfo?.CustomerOrganizationNumber}</span>
                                                </p> 
                                            </>*/
                                    : null}
                                </div>
                            </ContentCard>
                        </div>
                        {selectedCase && selectedCaseHasError ?
                            <div className={styles.blurtext}>
                                <p>
                                    Feil ved henting av sak fra Fremtind. Feilkode: {selectedCaseErrorCode}
                                    <SB1Tooltip>{selectedCaseErrorCode}: {selectedCaseErrorMessage}.</SB1Tooltip>
                                </p>
                                
                            </div>
                        : null}
                        
                    </GridCol>
                </GridRow>
            </Grid>
        </div>
    );
}

export default Insuranceoverview;