import React, { useEffect } from "react";
import { Input, InputGroup } from "@sb1/ffe-form-react";
import ContentCard from "../../../../Shared/ContentCard/ContentCard";
import { Insuranceapplication as InsuranceapplicationModel} from "../../../../../model/Insuranceapplication";
import { toast } from 'react-toastify';

import styles from './InsuranceCustomerCard.module.scss';
import InputWithUsability from "../../../../Shared/InputWithUsability/InputWithUsability";
import { Grid, GridCol, GridRow } from "@sb1/ffe-grid-react";
import { useAuth } from "../../../../../Auth";
import { useAxios } from "../../../../../AxiosHandler";
import customerFunctions from "../../../../../functions/customerFunctions";

function InsuranceCustomerCard(props: InsuranceCustomerCardProps) {
    const auth = useAuth();
    const axiosHandler = useAxios();
    const axios = axiosHandler.axios;

    useEffect(() => {
        if (props.currentInsuranceapplication?.CustomerSequence != undefined && props.currentInsuranceapplication?.CustomerSequence != "") {
            customerFunctions.searchPerson(toast, axios, auth, props.currentInsuranceapplication?.CustomerSequence).then((res: any) => {
                let temp = props.currentInsuranceapplication;

                if (temp.customerPhone == undefined || temp.customerPhone == "") temp.customerPhone = res.Mobile;
                if (temp.customerEmail == undefined || temp.customerEmail == "") temp.customerEmail = res.Email;

                props.setCurrentInsuranceapplication({
                    ...props.currentInsuranceapplication || new InsuranceapplicationModel(),
                    ...temp,
                    customerSocialSecurityNumber: res.Personid,
                    customerFirstName: res.FirstName,
                    customerLastName: res.LastName,
                    customerSector: res.Sector,
                });
            }).catch((err: any) => {
                console.log(err);
            });
        }
    }, [props.currentInsuranceapplication?.CustomerSequence]);
    
    return(
        <ContentCard disabled={props.disabled} open={props.open} title="Kundeopplysninger" className={styles.casecustomercard}>
            <InputGroup
                label="Personnummer (11 siffer)"
                tooltip="Forsikring kan kun selges til privatkunder. Legg inn personnummer 11 siffer. Resten av feltene vil bli fylt ut automatisk."
                //TEMP COMMENTED OUT COMPANY SEARCH FOR INSURANCE, WILL BE READDED LATER
                /* label={ props.currentInsuranceapplication?.customerSocialSecurityNumber?.length == 11 ?
                        "Personnummer (11 siffer)"
                    : props.currentInsuranceapplication?.customerSocialSecurityNumber?.length == 9 ?
                        "Organisasjonsnummer (9 siffer)"
                    :
                        "Organisasjonsnummer eller Personnummer"
                }
                tooltip={ props.currentInsuranceapplication?.customerSocialSecurityNumber?.length == 11 ?
                        "Personnummer på 11 siffer. Resten av feltene vil bli fylt ut automatisk."
                    :
                        "Organisasjonsnummer på 9 siffer eller personnummer på 11 siffer dersom enkeltpersonforetak. Resten av feltene vil bli fylt ut automatisk."
                } 
                fieldMessage={
                    props.currentInsuranceapplication?.customerSocialSecurityNumber?.length == 9 &&
                    props.currentInsuranceapplication?.customerSector == "8200"
                ?
                    "Denne kunden er enkeltpersonforetak og krever personnummer"
                : null } */
            >
                {inputProps => (
                    <InputWithUsability
                        type="text"
                        name="socialsecuritynumber"
                        useseparatorformatter
                        customformatter={(value: number): string => {
                            if (value != 0) {
                                return value.toString().replace(/(\d{6})(\d{5})/, "$1 $2");
                            } else {
                                return "";
                            }
                        }}
                        /* customformatter={
                            props.currentInsuranceapplication?.customerSocialSecurityNumber?.length != 9 ?
                                (value: number): string => {
                                    if (value != 0) {
                                        return value.toString().replace(/(\d{6})(\d{5})/, "$1 $2");
                                    } else {
                                        return "";
                                    }
                                }
                            :
                                (value: number): string => {
                                    if (value != 0) {
                                        return value.toString().replace(/(\d{3})(\d{3})(\d{3})/, "$1 $2 $3");
                                    } else {
                                        return "";
                                    }
                                }
                        } */
                        value={props.currentInsuranceapplication?.customerSocialSecurityNumber || ""}
                        onChange={e => {
                            if (props.currentInsuranceapplication?.coCustomerSocialSecurityNumber != e.target.value.replace(/\s/g, '') || e.target.value.replace(/\s/g, '') == "") {
                                props.setCurrentInsuranceapplication({
                                    ...props.currentInsuranceapplication || new InsuranceapplicationModel(),
                                    customerSocialSecurityNumber: e.target.value.replace(/\s/g, '')
                                });
                            } else {
                                toast.error("Finansieringsansvarlig kan ikke være samme som samskyldner");
                            }
                        }}
                        onBlur={(e) => {
                            if (e.target.value.replace(/\s/g, '').length == 11) {
                                customerFunctions.searchPerson(toast, axios, auth, e.target.value.replace(/\s/g, '')).then((res: any) => {
                                    props.setCurrentInsuranceapplication({
                                        ...props.currentInsuranceapplication || new InsuranceapplicationModel(),
                                        customerSequence: res.Sequence,
                                        customerEmail: res.Email,
                                        customerPhone: res.Mobile,
                                        customerFirstName: res.FirstName,
                                        customerLastName: res.LastName,
                                        customerSector: res.Sector,
                                    });
                                }).catch((err: any) => {
                                    props.setCurrentInsuranceapplication({
                                        ...props.currentInsuranceapplication || new InsuranceapplicationModel(),
                                        customerSequence: undefined,
                                        customerEmail: undefined,
                                        customerPhone: undefined,
                                        customerFirstName: undefined,
                                        customerLastName: undefined,
                                        customerSector: undefined,
                                    });
                                });
                            /* } else if (e.target.value.replace(/\s/g, '').length == 9) {
                                customerFunctions.searchCompany(toast, axios, auth, e.target.value.replace(/\s/g, '')).then((res: any) => {
                                    props.setCurrentInsuranceapplication({
                                        ...props.currentInsuranceapplication || new InsuranceapplicationModel(),
                                        customerSequence: res.Sequence,
                                        customerLastName: res.CompanyName,
                                        customerFirstName: undefined,
                                        customerEmail: res.Email,
                                        customerPhone: res.Mobile,
                                        customerSector: res.Sector,
                                    });
                                }).catch((err: any) => {
                                    props.setCurrentInsuranceapplication({
                                        ...props.currentInsuranceapplication || new InsuranceapplicationModel(),
                                        customerSequence: undefined,
                                        customerEmail: undefined,
                                        customerPhone: undefined,
                                        customerFirstName: undefined,
                                        customerLastName: undefined,
                                        customerSector: undefined,
                                    });
                                }); */
                            } else {
                                props.setCurrentInsuranceapplication({
                                    ...props.currentInsuranceapplication || new InsuranceapplicationModel(),
                                    customerSequence: undefined,
                                    customerEmail: "",
                                    customerPhone: "",
                                    customerFirstName: "",
                                    customerLastName: "",
                                });
                            }
                        }}
                        {...inputProps}
                    />
                )}
            </InputGroup>
            &nbsp;
            <Grid className={styles.grid}>
                <GridRow>
                    <GridCol sm="6" style={{
                        padding: "0 8px 16px"
                    }}>
                        <InputGroup
                            label="Fornavn"
                            //label={ props.currentInsuranceapplication?.customerSocialSecurityNumber?.length != 9 ? "Fornavn" : "Kontaktperson" }
                            //tooltip="Fornavn på kunden. Inkludert dobbeltnavn."
                            //fieldMessage={showErrors ? 'Ugyldig telefonnummer' : null}
                        >
                            {inputProps => (
                                <Input
                                    type="text"
                                    name="firstname"
                                    disabled={true}
                                    value={props.currentInsuranceapplication?.customerFirstName || ""}
                                    onChange={e => {
                                        props.setCurrentInsuranceapplication({
                                            ...props.currentInsuranceapplication || new InsuranceapplicationModel(),
                                            customerFirstName: e.target.value
                                        });
                                    }}
                                    {...inputProps}
                                />
                            )}
                        </InputGroup>
                    </GridCol>
                    <GridCol sm="6" style={{
                        padding: "0 8px 16px"
                    }}>
                        <InputGroup
                            label="Etternavn"
                            //label={ props.currentInsuranceapplication?.customerSocialSecurityNumber?.length != 9 ? "Etternavn" : "Organisasjon" }
                            //tooltip="REPLACE ME PLEASE - Etternavn"
                            //fieldMessage={showErrors ? 'Ugyldig telefonnummer' : null}
                        >
                            {inputProps => (
                                <Input
                                    type="text"
                                    name="lastname"
                                    disabled={true}
                                    value={props.currentInsuranceapplication?.customerLastName || ""}
                                    onChange={e => {
                                        props.setCurrentInsuranceapplication({
                                            ...props.currentInsuranceapplication || new InsuranceapplicationModel(),
                                            customerLastName: e.target.value
                                        });
                                    }}
                                    {...inputProps}
                                />
                            )}
                        </InputGroup>
                    </GridCol>
                </GridRow>
            </Grid>
            <InputGroup
                label="Kundens e-post"
                //label={ props.currentInsuranceapplication?.customerSocialSecurityNumber?.length != 9 ? "Kundens e-post" : "E-post - kontaktperson" }
                //tooltip="REPLACE ME PLEASE - E-post"
                //fieldMessage={showErrors ? 'Ugyldig telefonnummer' : null}
            >
                {inputProps => (
                    <Input
                        type="text"
                        name="email"
                        value={props.currentInsuranceapplication?.customerEmail || ""}
                        onChange={e => {
                            props.setCurrentInsuranceapplication({
                                ...props.currentInsuranceapplication || new InsuranceapplicationModel(),
                                customerEmail: e.target.value
                            });
                        }}
                        {...inputProps}
                    />
                )}
            </InputGroup>
            &nbsp;
            <InputGroup
                label="Kundens telefon"
                //label={ props.currentInsuranceapplication?.customerSocialSecurityNumber?.length != 9 ? "Kundens telefon" : "Telefon - kontaktperson" }
                //tooltip="REPLACE ME PLEASE - Telefon"
                //fieldMessage={showErrors ? 'Ugyldig telefonnummer' : null}
            >
                {inputProps => (
                    <Input
                        type="text"
                        name="phone"
                        value={props.currentInsuranceapplication?.customerPhone || ""}
                        onChange={e => {
                            props.setCurrentInsuranceapplication({
                                ...props.currentInsuranceapplication || new InsuranceapplicationModel(),
                                customerPhone: e.target.value
                            });
                        }}
                        {...inputProps}
                    />
                )}
            </InputGroup>
        </ContentCard>
    );
}

interface InsuranceCustomerCardProps {
    disabled: boolean;
    open: boolean;
    currentInsuranceapplication: InsuranceapplicationModel;
    setCurrentInsuranceapplication: (currentInsuranceapplication: InsuranceapplicationModel) => void;
}

export default InsuranceCustomerCard;