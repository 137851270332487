import React, { useEffect, useState } from 'react';

import contentcardstyles from './ContentCard.module.scss';

function ContentCard(props: ContentCardProps) {
    const [open, setOpen] = useState(false);

    useEffect(() => {
        setOpen(props.open || false);
    }, [props.open]);
    
    useEffect(() => {
        if (props.closeCard != undefined && props.closeCard != null) {
            setOpen(false);
        }
    }, [props.closeCard]);
    
    useEffect(() => {
        if (props.openCard != undefined && props.openCard != null && props.openCard != 0) {
            setOpen(true);
        }
    }, [props.openCard]);

    return (
        <div
            className={
                contentcardstyles.card + " " +
                props.className + " " +
                (props.disabled ? "disabled" : "") + " " +
                (props.disableClick ? "disableclick" : "") + " " +
                ((props.title == undefined) ? "notitle" : "") + " " +
                (props.loading ? "loading" : "")
            }
        >
            { props.disableClick ? null :
                <span
                    className={"material-symbols-outlined " + contentcardstyles.materialicon}
                    onClick={() => {
                        if (!props.disabled) {
                            props.onClick?.(!open);
                            setOpen(!open);
                        }
                    }}
                    style={
                        props.onClick != undefined && props.open != undefined ?
                            props.open ? {} : {
                                transform: "rotate(180deg)"
                            }
                        :
                            open ? {} : {
                                transform: "rotate(180deg)"
                            }
                    }
                >
                    expand_less
                </span>
            }
            { props.title != undefined ?
                <h4
                    className="ffe-h4"
                    onClick={() => {
                        if (!props.disabled && !props.disableClick) {
                            props.onClick?.(!open);
                            setOpen(!open);
                        }
                    }}
                >{props.closedTitle != undefined ? open ? props.title : props.closedTitle : props.title}</h4>
            : null }
            { props.topLeftButton != undefined ?
                <div className={contentcardstyles.topleftbutton}>
                    {props.topLeftButton}
                </div>
            : null }
            <div
                className={contentcardstyles.content}
                style={
                    props.onClick != undefined && props.open != undefined ?
                        props.open ? {
                            
                        } : {
                            display: "none"
                        }
                    : 
                        open ? {

                        } : {
                            display: "none"
                        }
                }
            >
                {props.children}
            </div>
            { props.loading ?
                <div className={contentcardstyles.loadingtext}>
                    Laster inn data...
                </div>
            : null }
        </div>
    );
}

class ContentCardProps {
    children: React.ReactNode;
    open?: boolean;
    disabled?: boolean;
    title?: string;
    closedTitle?: string;
    className?: string;
    onClick?: (val: boolean) => void;
    disableClick?: boolean;
    topLeftButton?: React.ReactNode;
    closeCard?: number;
    openCard?: number;
    loading?: boolean;
}

export default ContentCard;