import React from "react";

import styles from './PointsOverall.module.scss';
import ContentCard from "../../../../Shared/ContentCard/ContentCard";
import StatisticsCompetitionTable from "../../../../Shared/StatisticsCompetitionTable/StatisticsCompetitionTable";

function PointsOverall(props: PointsOverallProps) {
    const columns = [ 'Selger', 'Forhandler', 'Tot. poeng' ];

    const data = [
        { 0: 'Nils Roar Vestvik', 1: "Albjerk Bil AS", 2: 27 },
        { 0: 'Tor Andre Frostheim', 1: "Albjerk Bil AS", 2: 8 },
        { 0: 'Knut Midtstrand', 1: "Albjerk Bil AS", 2: 7 },
        { 0: 'Sivert Volden', 1: "Midtstranda Auto AS", 2: 7 },
        { 0: 'Birgitte Rauland Schmidt', 1: "Midtstranda Auto AS", 2: 5 },
    ];

    return (
        <div className={styles.pointsoverall}>
            <ContentCard
                title="Poengoversikt hittil i år"
                open={true}
                disableClick={true}
            >
                <StatisticsCompetitionTable
                    columns={columns}
                    data={props.data}
                />
            </ContentCard>
        </div>
    );
}

interface PointsOverallProps {
    data: any;
}

export default PointsOverall