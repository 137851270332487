import React, { useEffect, useState } from "react";
import { Checkbox, Input, InputGroup } from "@sb1/ffe-form-react";
import { Grid, GridRow, GridCol } from "@sb1/ffe-grid-react";
import ContentCard from "../ContentCard/ContentCard";

import './CalculatorOverrides.scss';
import styles from './Calculator.module.scss';
import Dropdown from "@sb1/ffe-dropdown-react";
import { ActionButton, PrimaryButton, SecondaryButton } from "@sb1/ffe-buttons-react";
import { Agreement } from "../../../model/Agreement";
import { useAuth } from "../../../Auth";
import { useAxios } from "../../../AxiosHandler";
import { toast } from 'react-toastify';
import InputWithUsability from "../InputWithUsability/InputWithUsability";
import general from "../../../functions/general";
import { useLoanapplication } from "../../../LoanapplicationContext";
import Tooltip from '@mui/material/Tooltip';

var aborted = false;
let lastToastMsg: string | undefined = undefined;

function Calculator(props: CalculatorProps) {
    const auth = useAuth();
    const loanapplication = useLoanapplication();
    const axiosHandler = useAxios();
    const axios = axiosHandler.axios;

    const toastId = React.useRef(null);

    const [calculating, setCalculating] = useState(false);
    const [changesMade, setChangesMade] = useState(false);
    const [calculationResultID, setCalculationResultID] = useState(loanapplication?.calculationResult?.ID);

    const [autoRefresh, setAutoRefresh] = useState(localStorage.getItem("autoRefresh") != undefined ? (localStorage.getItem("autoRefresh") == "true") : true);

    const [problems, setProblems] = useState<Array<string>>([]);

    const [showExtraInfo, setShowExtraInfo] = useState(false);

    const notifyStartRentInclMva = (text: string) => {
        if(! toast.isActive(toastId?.current as any)) {
            (toastId.current as any) = toast.info(text);
        }
    }

    function showArea(area:string) {
        return auth.selectedAgreement != undefined &&
            auth.selectedDealer?.DealerGroupAgreementConnection != undefined &&
            general.getAgreement(auth.selectedDealer, loanapplication)?.agreement?.schema?.fields?.includes(area);
    }

    function getDownPaymentValue(overrides: any = undefined) {
        if (showArea("egenkapital"))
            return overrides?.downPaymentSum ? overrides.downPaymentSum : loanapplication?.downPaymentSum;

        if (showArea("startleie"))
            return overrides?.startRent ? overrides.startRent : loanapplication?.startRent;

        if (showArea("startleieInklMva"))
            return (overrides?.startRentInclMva ? overrides.startRentInclMva : loanapplication?.startRentInclMva) * 0.8;

        if (showArea("startleieEksMva"))
            return overrides?.startRentExclMva ? overrides.startRentExclMva : loanapplication?.startRentExclMva;

        return 0;
    }

    function getCaseObjectPriceValue() {
        if (showArea("kjopesum"))
            return loanapplication?.purchaseSum;

        if (showArea("kjopesumFritattMva"))
            return loanapplication?.purchaseSum;

        if (showArea("kjopesumInklMva"))
            return loanapplication?.purchaseSum;

        if (showArea("kjopesumEksMva"))
            return loanapplication?.purchaseSumExclMva;

        return 0;
    }

    /* function getBudgetClassName(expense: any) {
        switch (expense.BudgetClassSequence) {
            case 5:
                return "Korreksjon mva. pr mnd";
            default:
                return expense.BudgetClassName;
        }
    } */

    function calculateLoan(overrides: any = undefined, newOldNominal: any = undefined) {
        if (problems.length > 0) return; 

        setCalculating(true);
        aborted = false;

        let purchaseSumExclMva = loanapplication?.purchaseSumExclMva;

        axios.single({
            url: "/api/calculation",
            data: {
                // "CalculateInFO": true, //Selector for hvilket Genus miljø Calculate skal bruke.
                "IsLoan": auth.selectedAgreement?.IsLoan,
                "AgreementSequence": Number(auth.selectedAgreement?.Sequence),
                "CaseObjectPrice": getCaseObjectPriceValue(),
                "CaseObjectPriceExVAT": purchaseSumExclMva,
                "CaseObjectVATExempt": showArea("kjopesumFritattMva") ? true : undefined,
                "DownPayment": getDownPaymentValue(overrides ?? undefined),
                "Interest": overrides?.["nominalInterestRate"] != undefined ? overrides["nominalInterestRate"] : loanapplication?.nominalInterestRate ?? 0,
                "Duration": Number(loanapplication?.contractlengthmonths) ?? auth.selectedAgreement?.ContractDurationDefault,
                "DoCalculateCommissionSubsidies": true,
                "DueDay": Number(loanapplication?.dueday) ?? 15,
                "Periodicity": 1,
                "CaseObjectObjectGroup": loanapplication?.category.split("|")[0] ?? "",
                "CaseObjectRegistryFee": showArea("engangsavgiftOgEvVrakpant") ? loanapplication?.onetimeFee : undefined,
                "IsCaseObjectNew": loanapplication?.caseObjectNew,
                "CaseObjectMakeName": "",
                "ResidualValue": showArea("restverdi") && loanapplication?.remainingValue != 0 && loanapplication?.remainingValue != null ? loanapplication?.remainingValue : undefined,
                "FinanceCase": [
                    {
                        "DealerSequence": auth.selectedDealer?.Sequence
                    }
                ]
            },
            method: "POST",
            headers: {
                "Content-Type": "application/json",
                "UserAuthorization": "Bearer " + (JSON.parse(localStorage.getItem("logindata") ?? "")["JWT"])
            }
        }).then((res: any) => {
            if (!aborted) {
                loanapplication.setCalculationResult({ID: calculationResultID, ...res.data});

                if(general.isError(res).isError === true){
                    loanapplication.setCalculationResult(undefined);
                    toast.error(general.isError(res).errorMessage);
                }

                if (res.data == "") {
                    loanapplication.setCalculationResult(undefined);
                    toast.error("Kalkulering feilet.");
                }
                
                if (props.nominalInterestRateIsDefault) {
                    if (
                        overrides?.["nominalInterestRate"] == undefined &&
                        loanapplication.nominalInterestRate != (res.data.MarginDefault + res.data.BaseInterest) &&
                        !isNaN(res.data.MarginDefault + res.data.BaseInterest)
                    ) {
                        let validMargin = res.data.MarginDefault;
                        if(validMargin < res.data.MarginMin) validMargin = res.data.MarginMin;
                        if(validMargin > res.data.MarginMax) validMargin = res.data.MarginMax;
                        const interestRateMsg = "Nominell rente ble endret til " + (validMargin + res.data.BaseInterest) + "%.";
                        if(lastToastMsg != interestRateMsg){
                            lastToastMsg = interestRateMsg;
                            toast.info(interestRateMsg);
                        }
                        props.nominalInterestRateChanged?.(validMargin + res.data.BaseInterest);
                        loanapplication.setNominalInterestRate(validMargin + res.data.BaseInterest);
                        calculateLoan({ nominalInterestRate: (validMargin + res.data.BaseInterest) }, (validMargin + res.data.BaseInterest));
                    }
                }

                if (showArea("startleie")) {
                    if (
                        (loanapplication.startRentInclMva == undefined || loanapplication.startRentInclMva == null) && 
                        loanapplication.startRentInclMva != (res.data.DownPayment + res.data.DownPaymentVAT) && 
                        (res.data.DownPayment + res.data.DownPaymentVAT) != 0
                    ) {
                        loanapplication.setStartRentInclMva(res.data.DownPayment + res.data.DownPaymentVAT);
                        loanapplication.setStartRent(res.data.DownPayment + res.data.DownPaymentVAT);
                        notifyStartRentInclMva("Startleie ble endret til " + general.formatPrice(res.data.DownPayment + res.data.DownPaymentVAT));
                    }
                }

                setShowExtraInfo(false);
                setChangesMade(false);
                props.onChangesMade?.(false);
                setCalculating(false);
            }
        }).catch((err: any) => {
            console.log(err);
            loanapplication.setCalculationResult(undefined);
            toast.error("Kalkulering av finansiering feilet! Vennligst prøv igjen");
            setCalculating(false);
        });
    }

    function reCalculateLoan(overrides: any = undefined) {
        if (
            autoRefresh &&
            changesMade &&
            loanapplication?.purchaseSum !== undefined &&
            loanapplication?.purchaseSum > 0
        ) {
            calculateLoan(overrides);
        }
    }

    function getInterestMin() {
        let agreementMin = (auth.selectedAgreement?.InterestMarginMin ?? 0) + (auth.selectedAgreement?.BaseInterest ?? 0);
        let calculateMin = (loanapplication?.calculationResult?.MarginMin ?? 0) + (loanapplication?.calculationResult?.BaseInterest ?? 0);
        return Math.max(agreementMin, calculateMin);
    }

    function getInterestMax() {
        let agreementMax = (auth.selectedAgreement?.InterestMarginMax ?? 100) + (auth.selectedAgreement?.BaseInterest ?? 0);
        let calculateMax = (loanapplication?.calculationResult?.MarginMax ?? 100) + (loanapplication?.calculationResult?.BaseInterest ?? 0);
        return Math.min(agreementMax, calculateMax);
    }

    function getPurchaseSumMax() {
        let agreementMax = auth.selectedAgreement?.PriceMax ?? 100000000;
        return agreementMax;
    }

    function toggleAutoRefresh() {
        localStorage.setItem("autoRefresh", (!autoRefresh).toString());
        setAutoRefresh(!autoRefresh);
    }

    function abortReCalculateLoan() {
        if (calculating) {
            aborted = true;
            setCalculating(false);
        }
    }

    function getDefaultNominalInterestRate() {
        let defaultNominalInterestRate;
        if(loanapplication?.calculationResult?.MarginDefault){
            defaultNominalInterestRate = loanapplication?.calculationResult?.MarginDefault + loanapplication?.calculationResult?.BaseInterest;
        } else if(auth.selectedAgreement){
            defaultNominalInterestRate = auth.selectedAgreement?.InterestMarginDefault + auth.selectedAgreement?.BaseInterest;
        }

        return defaultNominalInterestRate;
    }

    function calculateOneTimeFee(oneTimeFeeValue: number){
        if(showArea("kjopesumFritattMva")){
            loanapplication.setPurchaseSum(Math.round(((Number(loanapplication.purchaseSumExclMva) - Number(oneTimeFeeValue)) * 1) + Number(oneTimeFeeValue)));
        } else {
            loanapplication.setPurchaseSum(Math.round(((Number(loanapplication.purchaseSumExclMva) - Number(oneTimeFeeValue)) * 1.25) + Number(oneTimeFeeValue)));
        }
    }

    useEffect(() => {
        if (loanapplication?.nominalInterestRate != undefined) {
            let tempProblems = [];

            if (loanapplication?.nominalInterestRate < getInterestMin() && loanapplication?.freeinterestcalc != true)
                tempProblems.push("Rente kan ikke være lavere enn " + getInterestMin() + " %. Anbefalt rente på søknaden er " + (loanapplication?.calculationResult?.MarginDefault) + " %.");
            
            if (loanapplication?.nominalInterestRate > getInterestMax() && loanapplication?.freeinterestcalc != true)
                tempProblems.push("Rente kan ikke være høyere enn " + getInterestMax() + " %. Anbefalt rente på søknaden er " + (loanapplication?.calculationResult?.MarginDefault) + " %.");

            if (((showArea("ingenMvaVedBrukt") && !loanapplication?.caseObjectNew) || showArea("kjopesum")) && (loanapplication?.purchaseSum == undefined || loanapplication?.purchaseSum < 1 || loanapplication?.purchaseSum == ""))
                tempProblems.push("Kjøpesum må være høyere enn 0");
                
            if (((showArea("ingenMvaVedBrukt") && !loanapplication?.caseObjectNew) || showArea("kjopesum")) &&
                loanapplication?.purchaseSum != undefined &&
                loanapplication?.purchaseSum != "" &&
                loanapplication?.purchaseSum > getPurchaseSumMax())
                tempProblems.push("Kjøpesum må være mindre enn " + getPurchaseSumMax());

            if (showArea("restverdi") && Number(loanapplication?.remainingValue || 0) > Number(loanapplication?.purchaseSum))
                tempProblems.push("Restverdi kan ikke være høyere enn kjøpesum");

            if (showArea("startleie") && Number(loanapplication?.startRent || 0) > Number(loanapplication?.purchaseSumExclMva))
                tempProblems.push("Startleie kan ikke være høyere enn kjøpesum");

            if ((showArea("startleieEksMva")) && (Number(loanapplication?.startRentExclMva || 0) > Number(loanapplication?.purchaseSumExclMva)))
                tempProblems.push("Startleie eks mva kan ikke være høyere enn kjøpesum");
            
            if (showArea("startleieInklMva") && Number(loanapplication?.startRentInclMva || 0) > Number(loanapplication?.purchaseSum))
                tempProblems.push("Startleie inkl mva kan ikke være høyere enn kjøpesum");

            if (showArea("egenkapital") && loanapplication?.downPaymentPercentage > 100)
                tempProblems.push("Egenandel kan ikke være høyere enn kjøpesum");

            setProblems(tempProblems);
        }
    }, [
        loanapplication?.nominalInterestRate,
        loanapplication?.purchaseSum,
        loanapplication?.freeinterestcalc,
        loanapplication?.remainingValue,
        loanapplication?.purchaseSumExclMva,
        loanapplication?.startRent,
        loanapplication?.startRentExclMva,
        loanapplication?.startRentInclMva,
        loanapplication?.downPaymentSum,
        loanapplication?.variant,
        auth.selectedAgreement
    ]);

    useEffect(() => {
        setChangesMade(true);
        props.onChangesMade?.(true);
    }, [
        loanapplication?.purchaseSum,
        loanapplication?.downPaymentSum,
        loanapplication?.downPaymentPercentage,
        loanapplication?.nominalInterestRate,
        loanapplication?.contractlengthmonths,
        loanapplication?.dueday,
        loanapplication?.purchaseSumExclMva,
        loanapplication?.purchaseSumInclMva,
        loanapplication?.remainingValue,
        loanapplication?.onetimeFee,
        loanapplication?.startRent,
        loanapplication?.startRentExclMva,
        loanapplication?.startRentInclMva,
    ]);

    useEffect(() => {
        if(loanapplication?.calculationResult) calculateLoan();
    }, [
        loanapplication?.variant
    ]);

    useEffect(() => {
        if(
            showArea("engangsavgiftOgEvVrakpant") &&
            !(
                showArea("ingenMvaVedBrukt") && 
                !loanapplication.caseObjectNew
            ) && 
            loanapplication.onetimeFee
        ){ 
            calculateOneTimeFee(loanapplication.onetimeFee);
        }
    }, [auth.selectedAgreement]);

    useEffect(() => {
        if(lastToastMsg != undefined){
            setTimeout(() => {
                lastToastMsg = undefined;
            }, 8000);
        }
    }, [lastToastMsg]);

    return (
        <div className={styles.calculator}>
            <Grid className={styles.grid}>
                <GridRow>
                    <GridCol lg="6" md="12" sm="12" className="maincol">
                        <ContentCard
                            open={props.open}
                            disabled={props.disabled}
                            title={(loanapplication?.segment || "") + " " + (loanapplication?.type || "") + " detaljer"
                        }>
                            { (showArea("ingenMvaVedBrukt") && !loanapplication?.caseObjectNew) || showArea("kjopesum") ?
                                <InputGroup
                                    label="Kjøpesum"
                                    tooltip="Kjøpesum framkommer som pris i kjøpekontrakt avtalt mellom selger og kjøper."
                                    fieldMessage={loanapplication?.purchaseSum > getPurchaseSumMax() ? 'For høy kjøpesum' : null}
                                >
                                    {inputProps => (
                                        <>
                                            <InputWithUsability
                                                prefix="Kr"
                                                tabIndex={1}
                                                type="number"
                                                name="purchasesum"
                                                max={getPurchaseSumMax()}
                                                useseparatorformatter
                                                value={loanapplication?.purchaseSum != undefined ? loanapplication?.purchaseSum : ""}
                                                onBlur={(e) => {
                                                    let downPaymentSum;
                                                    if (
                                                        loanapplication?.downPaymentSum == undefined &&
                                                        loanapplication?.downPaymentPercentage != undefined
                                                    ) {
                                                        downPaymentSum = Number(
                                                            (Number(e.target.value) * loanapplication?.downPaymentPercentage) / 100
                                                        );

                                                        loanapplication.setDownPaymentSum(downPaymentSum);
                                                    }

                                                    reCalculateLoan({downPaymentSum: downPaymentSum});
                                                }}
                                                onChange={e => {
                                                    abortReCalculateLoan();
                                                    let downpaymentpercentage =
                                                        loanapplication?.downPaymentSum != undefined &&
                                                        loanapplication?.downPaymentSum > 0 ? Number(loanapplication?.downPaymentSum ?
                                                            (loanapplication?.downPaymentSum / Number(e.target.value)) * 100 : 0).toFixed(2)
                                                        : undefined;

                                                    loanapplication.setPurchaseSum(Number(e.target.value));

                                                    if (downpaymentpercentage != undefined) {
                                                        loanapplication.setDownPaymentPercentage(Number(downpaymentpercentage));
                                                    }
                                                    
                                                    props.fieldChanged?.("purchaseSum", Number(e.target.value));
                                                }}
                                                {...inputProps}
                                            />
                                        </>
                                    )}
                                </InputGroup>
                            : null }
                            { showArea("ingenMvaVedBrukt") && !loanapplication?.caseObjectNew ? null :
                              showArea("kjopesumEksMva") ?
                                <InputGroup
                                    label="Kjøpesum (eks. mva)"
                                    tooltip="Kjøpesum framkommer som pris i kjøpekontrakt avtalt mellom selger og kjøper. (eks. mva)"
                                    //fieldMessage={showErrors ? 'Ugyldig telefonnummer' : null}
                                >
                                    {inputProps => (
                                        <>
                                            <InputWithUsability
                                                prefix="Kr"
                                                tabIndex={3}
                                                type="number"
                                                name="purchasesumexclmva"
                                                max={100000000}
                                                useseparatorformatter
                                                value={loanapplication?.purchaseSumExclMva != undefined ? loanapplication?.purchaseSumExclMva : ""}
                                                onBlur={(e) => {
                                                    let startRentExclMva;
                                                    if (
                                                        loanapplication?.startRentExclMvaPct != undefined &&
                                                        !isNaN(loanapplication.purchaseSumExclMva)
                                                    ) {
                                                        startRentExclMva = Number(
                                                            (Number(loanapplication.purchaseSumExclMva) * loanapplication?.startRentExclMvaPct) / 100
                                                        );
                                                        loanapplication.setStartRentExclMva(startRentExclMva);
                                                    }

                                                    reCalculateLoan({startRentExclMva: startRentExclMva});
                                                }}
                                                onChange={e => {
                                                    abortReCalculateLoan();
                                                    let startRentExclMvaPct =
                                                        loanapplication?.downPaymentSum != undefined &&
                                                        loanapplication?.downPaymentSum > 0 ? Number(loanapplication?.downPaymentSum ?
                                                            (loanapplication?.downPaymentSum / Number(e.target.value)) * 100 : 0).toFixed(2)
                                                        : undefined;

                                                    loanapplication.setPurchaseSumExclMva(Number(e.target.value));
                                                    loanapplication.setPurchaseSum(Math.round(((Number(e.target.value) - (loanapplication.onetimeFee ?? 0)) * 1.25) + (loanapplication.onetimeFee ?? 0)));
                                                    loanapplication.setRemainingValue(loanapplication?.remainingValuePct ? (loanapplication.purchaseSum / ((1 / loanapplication?.remainingValuePct) * 100)) : 0);

                                                    if (startRentExclMvaPct != undefined) {
                                                        loanapplication.setStartRentExclMvaPct(Number(startRentExclMvaPct));
                                                    }
                                                }}
                                                {...inputProps}
                                            />
                                        </>
                                    )}
                                </InputGroup>
                            : null }
                            { showArea("ingenMvaVedBrukt") && !loanapplication?.caseObjectNew ? null :
                              showArea("kjopesumInklMva") ?
                                <InputGroup
                                    label="Kjøpesum (inkl. mva)"
                                    tooltip="Kjøpesum framkommer som pris i kjøpekontrakt avtalt mellom selger og kjøper. (inkl. mva)"
                                    //fieldMessage={showErrors ? 'Ugyldig telefonnummer' : null}
                                >
                                    {inputProps => (
                                        <>
                                            <InputWithUsability
                                                prefix="Kr"
                                                tabIndex={2}
                                                type="number"
                                                name="purchasesum"
                                                max={100000000}
                                                useseparatorformatter
                                                value={loanapplication?.purchaseSum != undefined ? loanapplication?.purchaseSum : ""}
                                                onBlur={(e) => {
                                                    let startRentExclMva;
                                                    if (
                                                        loanapplication?.startRentExclMvaPct != undefined &&
                                                        !isNaN(loanapplication.purchaseSumExclMva)
                                                    ) {
                                                        startRentExclMva = Number(
                                                            (Number(loanapplication.purchaseSumExclMva) * loanapplication?.startRentExclMvaPct) / 100
                                                        )
                                                        loanapplication.setStartRentExclMva(startRentExclMva);
                                                    }
                                                    
                                                    let startRentInclMva = undefined;
                                                    if(showArea("startleieInklMva")){
                                                        if(loanapplication?.purchaseSum && loanapplication?.startRentInclMvaPct){
                                                            startRentInclMva = Number(Math.round((Number(loanapplication?.startRentInclMvaPct) / 100) * loanapplication?.purchaseSum));
                                                            loanapplication.setStartRentInclMva(Number(startRentInclMva));
                                                        }
                                                    }

                                                    let overrideObject = {startRentExclMva: startRentExclMva, startRentInclMva: startRentInclMva};
                                                    reCalculateLoan(overrideObject);
                                                }}
                                                onChange={e => {
                                                    abortReCalculateLoan();
                                                    let startRentExclMvaPct =
                                                        loanapplication?.downPaymentSum != undefined &&
                                                        loanapplication?.downPaymentSum > 0 ? Number(loanapplication?.downPaymentSum ?
                                                            (loanapplication?.downPaymentSum / Number(e.target.value)) * 100 : 0).toFixed(2)
                                                        : undefined;

                                                    loanapplication.setPurchaseSum(Number(e.target.value));
                                                    loanapplication.setPurchaseSumExclMva(Math.round(((Number(e.target.value) - (loanapplication.onetimeFee ?? 0)) * 0.8) + (loanapplication.onetimeFee ?? 0)));
                                                    loanapplication.setRemainingValue(loanapplication?.remainingValuePct ? (Number(e.target.value) / ((1 / loanapplication?.remainingValuePct) * 100)) : 0);

                                                    if (startRentExclMvaPct != undefined) {
                                                        loanapplication.setStartRentExclMvaPct(Number(startRentExclMvaPct));
                                                    }
                                                }}
                                                {...inputProps}
                                            />
                                        </>
                                    )}
                                </InputGroup>
                            : null }
                            { showArea("kjopesumFritattMva") ?
                                <InputGroup
                                    label="Kjøpesum (fritatt mva)"
                                    tooltip="Kjøpesum framkommer som pris i kjøpekontrakt avtalt mellom selger og kjøper. (fritatt mva)"
                                    //fieldMessage={showErrors ? 'Ugyldig telefonnummer' : null}
                                >
                                    {inputProps => (
                                        <>
                                            <InputWithUsability
                                                prefix="Kr"
                                                tabIndex={4}
                                                type="number"
                                                name="purchasesum"
                                                max={100000000}
                                                useseparatorformatter
                                                value={loanapplication?.purchaseSum != undefined ? loanapplication?.purchaseSum : ""}
                                                onBlur={(e) => {
                                                    let startRentExclMva;
                                                    if (
                                                        loanapplication?.startRentExclMvaPct != undefined &&
                                                        !isNaN(loanapplication.purchaseSum) &&
                                                        showArea("startleieEksMva")
                                                    ) {
                                                        startRentExclMva = Number(
                                                            (Number(loanapplication.purchaseSum) * loanapplication?.startRentExclMvaPct) / 100
                                                        );
                                                        loanapplication.setStartRentExclMva(startRentExclMva);
                                                    }

                                                    reCalculateLoan({startRentExclMva: startRentExclMva});
                                                }}
                                                onChange={e => {
                                                    abortReCalculateLoan();
                                                    let downpaymentpercentage =
                                                        loanapplication?.downPaymentSum != undefined &&
                                                        loanapplication?.downPaymentSum > 0 ? Number(loanapplication?.downPaymentSum ?
                                                            (loanapplication?.downPaymentSum / Number(e.target.value)) * 100 : 0).toFixed(2)
                                                        : undefined;

                                                    loanapplication.setPurchaseSum(Number(e.target.value));
                                                    loanapplication.setPurchaseSumExclMva(Number(e.target.value));

                                                    if (downpaymentpercentage != undefined) {
                                                        loanapplication.setDownPaymentPercentage(Number(downpaymentpercentage));
                                                    }
                                                }}
                                                {...inputProps}
                                            />
                                        </>
                                    )}
                                </InputGroup>
                            : null }
                            { showArea("restverdi") ?
                                <Grid className={styles.grid}>
                                    <GridRow>
                                        <GridCol sm="9">
                                            <InputGroup
                                                label="Restverdi"
                                                tooltip="Restverdi legges inn i henhold til sum oppgitt av forhandler/leverandør"
                                                fieldMessage={
                                                    Number(loanapplication?.remainingValue || 0) > Number(loanapplication?.purchaseSum) ? 
                                                        "Restverdi kan ikke være høyere enn kjøpesum" : null
                                                }
                                            >
                                                {inputProps => (
                                                    <>
                                                        <InputWithUsability
                                                            prefix="Kr"
                                                            tabIndex={5}
                                                            type="number"
                                                            name="remainingvalue"
                                                            max={Math.min(Number(loanapplication?.purchaseSum), 100000000)}
                                                            useseparatorformatter
                                                            onBlur={(e) => {
                                                                reCalculateLoan();
                                                            }}
                                                            //disabled={!showArea("remainingvalue")}
                                                            value={loanapplication?.remainingValue != undefined ? loanapplication?.remainingValue : ""}
                                                            onChange={e => {
                                                                abortReCalculateLoan();
                                                                let remainingvaluepct =
                                                                    loanapplication?.purchaseSum != undefined &&
                                                                    loanapplication?.purchaseSum > 0 ?
                                                                        Number(loanapplication?.purchaseSum ? (Number(e.target.value) / loanapplication?.purchaseSum) * 100 : 0).toFixed(2)
                                                                    : undefined;
                
                                                                loanapplication.setRemainingValue(Number(e.target.value));
                
                                                                if (remainingvaluepct != undefined) {
                                                                    loanapplication.setRemainingValuePct(Number(remainingvaluepct));
                                                                }
                                                            }}
                                                            {...inputProps}
                                                        />
                                                    </>
                                                )}
                                            </InputGroup>
                                        </GridCol>
                                        <GridCol sm="3">
                                            <InputGroup
                                                label="&nbsp;"
                                            >
                                                {inputProps => (
                                                    <>
                                                        <InputWithUsability
                                                            suffix="%"
                                                            tabIndex={6}
                                                            type="numeric-text"
                                                            name="remainingvaluepercentage"
                                                            min={auth.selectedAgreement?.RemainingValuePctMin != undefined ? auth.selectedAgreement?.RemainingValuePctMin : 0}
                                                            max={auth.selectedAgreement?.RemainingValuePctMin != undefined ? auth.selectedAgreement?.RemainingValuePctMin : 100}
                                                            onBlur={(e) => {
                                                                reCalculateLoan();
                                                            }}
                                                            value={loanapplication?.remainingValuePct}
                                                            onChangeCustom={e => {
                                                                abortReCalculateLoan();
                                                                loanapplication.setRemainingValuePct(e.target.valueAsNumber);
                                                                loanapplication.setRemainingValue(Number(loanapplication?.purchaseSum ? Math.round((e.target.valueAsNumber / 100) * loanapplication?.purchaseSum) : 0));
                                                            }}
                                                            {...inputProps}
                                                        />
                                                    </>
                                                )}
                                            </InputGroup>
                                        </GridCol>
                                    </GridRow>
                                </Grid>
                            : null }
                            { showArea("engangsavgiftOgEvVrakpant") &&
                            !(
                                showArea("ingenMvaVedBrukt") && 
                                !loanapplication.caseObjectNew
                            ) ?
                                <InputGroup
                                    label="Engangsavgift (og ev. vrakpant)"
                                    tooltip="Engangsavgift framkommer i kjøpekontrakt og gjelder bare for nye objekter"
                                    //fieldMessage={showErrors ? 'Ugyldig telefonnummer' : null}
                                >
                                    {inputProps => (
                                        <>
                                            <InputWithUsability
                                                prefix="Kr"
                                                tabIndex={7}
                                                type="number"
                                                name="onetimefee"
                                                max={100000000}
                                                useseparatorformatter
                                                value={loanapplication?.onetimeFee != undefined ? loanapplication?.onetimeFee : ""}
                                                onBlur={(e) => {
                                                    reCalculateLoan();
                                                }}
                                                onChange={e => {
                                                    abortReCalculateLoan();
                                                    loanapplication.setOnetimeFee(Number(e.target.value));
                                                    
                                                    calculateOneTimeFee(Number(e.target.value));
                                                    
                                                    //loanapplication.setPurchaseSumExclMva(Math.round(((Number(loanapplication.purchaseSum) - Number(e.target.value)) * 0.8) + Number(e.target.value)));
                                                }}
                                                {...inputProps}
                                            />
                                        </>
                                    )}
                                </InputGroup>
                            : null }
                            { showArea("startleie") ?
                                <Grid className={styles.grid}>
                                    <GridRow>
                                        <GridCol sm="9">
                                            <InputGroup
                                                label="Startleie"
                                                tooltip="Startleie kan sammenlignes med egenkapital ved lån, og er et innskudd i oppstart av leasingperioden."
                                                fieldMessage={
                                                    Number(loanapplication?.startRent || 0) > Number(loanapplication?.purchaseSum) ?
                                                        "Startleie kan ikke være høyere enn kjøpesum" 
                                                    : 
                                                        loanapplication?.startRent < 0 ? 
                                                            "Startleie kan ikke være mindre enn 0" 
                                                        : 
                                                            null
                                                }
                                            >
                                                {inputProps => (
                                                    <>
                                                        <InputWithUsability
                                                            prefix="Kr"
                                                            tabIndex={8}
                                                            type="number"
                                                            max={Math.min(loanapplication?.purchaseSum, 100000000)}
                                                            min={0}
                                                            name="startrent"
                                                            useseparatorformatter
                                                            onBlur={(e) => {
                                                                reCalculateLoan();
                                                            }}
                                                            value={loanapplication?.startRent != undefined ? loanapplication?.startRent : ""}
                                                            onChange={e => {
                                                                abortReCalculateLoan();
                                                                let startrentpct =
                                                                    loanapplication?.purchaseSum != undefined &&
                                                                    loanapplication?.purchaseSum > 0 ?
                                                                        Number(loanapplication?.purchaseSum ? (Number(e.target.value) / loanapplication?.purchaseSum) * 100 : 0).toFixed(2)
                                                                    : undefined;
                
                                                                loanapplication.setStartRent(Number(e.target.value));
                
                                                                if (startrentpct != undefined) {
                                                                    loanapplication.setStartRentPct(Number(startrentpct));
                                                                }
                                                            }}
                                                            {...inputProps}
                                                        />
                                                    </>
                                                )}
                                            </InputGroup>
                                        </GridCol>
                                        <GridCol sm="3">
                                            <InputGroup
                                                label="&nbsp;"
                                            >
                                                {inputProps => (
                                                    <>
                                                        <InputWithUsability
                                                            suffix="%"
                                                            tabIndex={9}
                                                            type="numeric-text"
                                                            name="startrentpercentage"
                                                            min={auth.selectedAgreement?.StartRentPctMin != undefined ? auth.selectedAgreement?.StartRentPctMin : 0}
                                                            max={auth.selectedAgreement?.StartRentPctMin != undefined ? auth.selectedAgreement?.StartRentPctMin : 100}
                                                            onBlur={(e) => {
                                                                reCalculateLoan();
                                                            }}
                                                            value={loanapplication?.startRentPct}
                                                            onChangeCustom={e => {
                                                                abortReCalculateLoan();
                                                                loanapplication.setStartRentPct(e.target.valueAsNumber);
                                                                loanapplication.setStartRent(Number(loanapplication?.purchaseSum ? Math.round((e.target.valueAsNumber / 100) * loanapplication?.purchaseSum) : 0));
                                                            }}
                                                            {...inputProps}
                                                        />
                                                    </>
                                                )}
                                            </InputGroup>
                                        </GridCol>
                                    </GridRow>
                                </Grid>
                            : null }
                            { showArea("startleieEksMva") ?
                                <Grid className={styles.grid}>
                                    <GridRow>
                                        <GridCol sm="9">
                                            <InputGroup
                                                label="Startleie (eks. mva)"
                                                tooltip="Startleie kan sammenlignes med egenkapital ved lån, og er et innskudd i oppstart av leasingperioden."
                                                fieldMessage={
                                                    Number(loanapplication?.startRentExclMva || 0) > Number(loanapplication?.purchaseSumExclMva) ?
                                                        "Startleie kan ikke være høyere enn kjøpesum" 
                                                    : 
                                                        loanapplication?.startRentExclMva < 0 ? 
                                                            "Startleie kan ikke være mindre enn 0" 
                                                        : 
                                                            null
                                                }
                                            >
                                                {inputProps => (
                                                    <>
                                                        <InputWithUsability
                                                            prefix="Kr"
                                                            tabIndex={10}
                                                            type="number"
                                                            name="startrentexclmva"
                                                            max={Math.min(loanapplication?.purchaseSumExclMva, 100000000)}
                                                            min={0}
                                                            useseparatorformatter
                                                            onBlur={(e) => {
                                                                reCalculateLoan();
                                                            }}
                                                            value={loanapplication?.startRentExclMva != undefined ? loanapplication?.startRentExclMva : ""}
                                                            onChange={e => {
                                                                abortReCalculateLoan();
                                                                let starterntexclmvapct =
                                                                    loanapplication?.purchaseSumExclMva != undefined &&
                                                                    loanapplication?.purchaseSumExclMva > 0 ?
                                                                        Number(loanapplication?.purchaseSumExclMva ? (Number(e.target.value) / loanapplication?.purchaseSumExclMva) * 100 : 0).toFixed(2)
                                                                    : undefined;

                                                                loanapplication.setStartRentExclMva(Number(e.target.value));
                
                                                                if (starterntexclmvapct != undefined) {
                                                                    loanapplication.setStartRentExclMvaPct(Number(starterntexclmvapct));
                                                                }
                                                            }}
                                                            {...inputProps}
                                                        />
                                                    </>
                                                )}
                                            </InputGroup>
                                        </GridCol>
                                        <GridCol sm="3">
                                            <InputGroup
                                                label="&nbsp;"
                                            >
                                                {inputProps => (
                                                    <>
                                                        <InputWithUsability
                                                            suffix="%"
                                                            tabIndex={11}
                                                            type="numeric-text"
                                                            name="startrentexclmvapercentage"
                                                            min={auth.selectedAgreement?.StartRentExclMvaPctMin != undefined ? auth.selectedAgreement?.StartRentExclMvaPctMin : 0}
                                                            max={auth.selectedAgreement?.StartRentExclMvaPctMin != undefined ? auth.selectedAgreement?.StartRentExclMvaPctMin : 100}
                                                            onBlur={(e) => {
                                                                reCalculateLoan();
                                                            }}
                                                            value={loanapplication?.startRentExclMvaPct}
                                                            onChangeCustom={e => {
                                                                abortReCalculateLoan();
                                                                loanapplication.setStartRentExclMvaPct(e.target.valueAsNumber);
                                                                if(showArea("kjopesumFritattMva")){
                                                                    loanapplication.setStartRentExclMva(Number(loanapplication?.purchaseSum ? Math.round((e.target.valueAsNumber / 100) * loanapplication?.purchaseSum) : 0));
                                                                } else {
                                                                    loanapplication.setStartRentExclMva(Number(loanapplication?.purchaseSumExclMva ? Math.round((e.target.valueAsNumber / 100) * loanapplication?.purchaseSumExclMva) : 0));
                                                                }
                                                                
                                                            }}
                                                            {...inputProps}
                                                        />
                                                    </>
                                                )}
                                            </InputGroup>
                                        </GridCol>
                                    </GridRow>
                                </Grid>
                            : null }
                            { showArea("startleieInklMva") ?
                                <Grid className={styles.grid}>
                                    <GridRow>
                                        <GridCol sm="9">
                                            <InputGroup
                                                label="Startleie (inkl. mva)"
                                                tooltip="Startleie kan sammenlignes med egenkapital ved lån, og er et innskudd i oppstart av leasingperioden."
                                                fieldMessage={
                                                    Number(loanapplication?.startRentInclMva || 0) > Number(loanapplication?.purchaseSum) ?
                                                        "Startleie kan ikke være høyere enn kjøpesum" 
                                                    : 
                                                        loanapplication?.startRentInclMva < 0 ? 
                                                            "Startleie kan ikke være mindre enn 0" 
                                                        : 
                                                            null
                                                }
                                            >
                                                {inputProps => (
                                                    <>
                                                        <InputWithUsability
                                                            prefix="Kr"
                                                            tabIndex={12}
                                                            type="number"
                                                            name="startrentinclmva"
                                                            max={Math.min(loanapplication?.purchaseSum, 100000000)}
                                                            min={0}
                                                            useseparatorformatter
                                                            onBlur={(e) => {
                                                                reCalculateLoan();
                                                            }}
                                                            value={loanapplication?.startRentInclMva != undefined ? loanapplication?.startRentInclMva : ""}
                                                            onChange={e => {
                                                                abortReCalculateLoan();
                                                                let startrentinclmvapct =
                                                                    loanapplication?.purchaseSum != undefined &&
                                                                    loanapplication?.purchaseSum > 0 ?
                                                                        Number(loanapplication?.purchaseSum ? (Number(e.target.value) / loanapplication?.purchaseSum) * 100 : 0).toFixed(2)
                                                                    : undefined;
                
                                                                loanapplication.setStartRentInclMva(Number(e.target.value));

                                                                if (startrentinclmvapct != undefined) {
                                                                    loanapplication.setStartRentInclMvaPct(Number(startrentinclmvapct));
                                                                }
                                                            }}
                                                            {...inputProps}
                                                        />
                                                    </>
                                                )}
                                            </InputGroup>
                                        </GridCol>
                                        <GridCol sm="3">
                                            <InputGroup
                                                label="&nbsp;"
                                            >
                                                {inputProps => (
                                                    <>
                                                        <InputWithUsability
                                                            suffix="%"
                                                            tabIndex={13}
                                                            type="numeric-text"
                                                            name="startrentpercentageinclmva"
                                                            min={auth.selectedAgreement?.StartRentInclMvaPctMin != undefined ? auth.selectedAgreement?.StartRentInclMvaPctMin : 0}
                                                            max={auth.selectedAgreement?.StartRentInclMvaPctMin != undefined ? auth.selectedAgreement?.StartRentInclMvaPctMin : 100}
                                                            onBlur={(e) => {
                                                                reCalculateLoan();
                                                            }}
                                                            value={loanapplication?.startRentInclMvaPct}
                                                            onChangeCustom={e => {
                                                                abortReCalculateLoan();
                                                                loanapplication.setStartRentInclMvaPct(e.target.valueAsNumber);
                                                                loanapplication.setStartRentInclMva(Number(loanapplication?.purchaseSum ? Math.round((e.target.valueAsNumber / 100) * loanapplication?.purchaseSum) : 0));
                                                            }}
                                                            {...inputProps}
                                                        />
                                                    </>
                                                )}
                                            </InputGroup>
                                        </GridCol>
                                    </GridRow>
                                </Grid>
                            : null }
                            { showArea("egenkapital") ?
                                <Grid className={styles.grid}>
                                    <GridRow>
                                        <GridCol sm="6">
                                            <InputGroup
                                                label="Egenkapital"
                                                tooltip="Egenkapital kan være oppsparte midler eller verdi av innbytte(minus evt restgjeld)."
                                                fieldMessage={
                                                    (loanapplication?.downPaymentPercentage || 0) > auth.selectedAgreement?.DownPaymentPctMax ?
                                                        'For høy egenandel'
                                                    :
                                                        ((loanapplication?.downPaymentPercentage || 0) < auth.selectedAgreement?.DownPaymentPctMin || loanapplication?.downPaymentSum < 0) ?
                                                            'For lav egenkapital'
                                                        :
                                                            null
                                                }
                                            >
                                                {inputProps => (
                                                    <>
                                                        <InputWithUsability
                                                            prefix="Kr"
                                                            tabIndex={14}
                                                            type="number"
                                                            name="egenkapital"
                                                            max={Math.min(loanapplication?.purchaseSum, 100000000)}
                                                            min={0}
                                                            useseparatorformatter
                                                            onBlur={(e) => {
                                                                reCalculateLoan();
                                                            }}
                                                            value={loanapplication?.downPaymentSum != undefined ? loanapplication?.downPaymentSum : ""}
                                                            onChange={e => {
                                                                abortReCalculateLoan();
                                                                let downpaymentpercentage =
                                                                    loanapplication?.purchaseSum != undefined &&
                                                                    loanapplication?.purchaseSum > 0 ?
                                                                        Number(loanapplication?.purchaseSum ? (Number(e.target.value) / loanapplication?.purchaseSum) * 100 : 0).toFixed(2)
                                                                    : undefined;
                
                                                                loanapplication.setDownPaymentSum(e.target.value == "" ? undefined : Number(e.target.value));
                
                                                                if (downpaymentpercentage != undefined) {
                                                                    loanapplication.setDownPaymentPercentage(Number(downpaymentpercentage));
                                                                }

                                                                props.fieldChanged?.("downPaymentSum", Number(e.target.value));
                                                            }}
                                                            {...inputProps}
                                                        />
                                                    </>
                                                )}
                                            </InputGroup>
                                        </GridCol>
                                        <GridCol sm="6">
                                            <InputGroup
                                                label="Prosent"
                                                tooltip="Prosent egenkapital av kjøpesum."
                                                fieldMessage={
                                                    (loanapplication?.downPaymentPercentage || 0) > auth.selectedAgreement?.DownPaymentPctMax ?
                                                        'For høy egenandel'
                                                    :
                                                        (loanapplication?.downPaymentPercentage || 0) < auth.selectedAgreement?.DownPaymentPctMin ?
                                                            'For lav egenandel'
                                                        :
                                                            null
                                                }
                                            >
                                                {inputProps => (
                                                    <>
                                                        <InputWithUsability
                                                            suffix="%"
                                                            tabIndex={15}
                                                            type="numeric-text"
                                                            name="percentage"
                                                            min={auth.selectedAgreement?.DownPaymentPctMin != undefined ? auth.selectedAgreement?.DownPaymentPctMin : 0}
                                                            max={auth.selectedAgreement?.DownPaymentPctMax != undefined ? auth.selectedAgreement?.DownPaymentPctMax : 100}
                                                            onBlur={(e) => {
                                                                reCalculateLoan();
                                                            }}
                                                            value={loanapplication?.downPaymentPercentage}
                                                            onChangeCustom={e => {
                                                                abortReCalculateLoan();
                                                                loanapplication.setDownPaymentPercentage(e.target.valueAsNumber);
                                                                loanapplication.setDownPaymentSum(Number(loanapplication?.purchaseSum ? Math.round((e.target.valueAsNumber / 100) * loanapplication?.purchaseSum) : 0));
                                                            }}
                                                            {...inputProps}
                                                        />
                                                    </>
                                                )}
                                            </InputGroup>
                                        </GridCol>
                                    </GridRow>
                                </Grid>
                            : null }
                            { (showArea("midlenesOpprinnelse") && loanapplication?.downPaymentSum > 0) 
                            || 
                            (showArea("startleieMidlenesOpprinnelse") && (loanapplication?.startRentInclMva > 0 || loanapplication?.startRentExclMva > 0)) 
                            ?
                                <>
                                    <InputGroup
                                        extraMargin={false}
                                        label={loanapplication?.type != "Lån" ? "Startleie kommer fra" : "Egenkapital kommer fra" }
                                        //tooltip="Kunden kan velge hvilke forfallsdag som ønskes."
                                        //fieldMessage={showErrors ? 'Du må velge måned' : null}
                                    >
                                        <Dropdown
                                            tabIndex={16}
                                            value={loanapplication?.downPaymentSource || 0}
                                            onChange={e => {
                                                loanapplication.setDownPaymentSource(Number(e.target.value));
                                            }}
                                            defaultValue={0}
                                        >
                                            <option value={0} disabled>Velg midlenes opprinnelse</option>
                                            {general.getAgreement(auth.selectedDealer, loanapplication)?.agreement?.schema.midlenesopprinnelse.map((item: any, index: number) => {
                                                return <option key={index} value={item.Kode}>{item.Navn}</option>;
                                            })}
                                        </Dropdown>
                                    </InputGroup>
                                    {/* if any opprinnelse has * in its name, show p tag */}
                                    {general.getAgreement(auth.selectedDealer, loanapplication)?.agreement?.schema.midlenesopprinnelse.some((item: any) => item.Navn.includes("*")) ?
                                        <p className={styles.starwarning}>
                                            * Kun for enkeltpersonforetak
                                        </p>
                                    : null }
                                </>
                            : null }
                            <Grid className={styles.grid}>
                                <GridRow>
                                    <GridCol sm="6">
                                        <InputGroup
                                            label="Nominell rente"
                                            tooltip="Nominell rente er den løpende renten du får. Den effektive renten viser i tillegg alle gebyrer og andre utgifter i forbindelse med finansieringen."
                                            fieldMessage={
                                                (getInterestMin() > loanapplication?.nominalInterestRate && loanapplication?.freeinterestcalc != true ?
                                                    "For lav rente. Anbefalt rente på søknaden er " + (loanapplication?.calculationResult?.MarginDefault) + " %."
                                                :
                                                    null) || 
                                                (getInterestMax() < loanapplication?.nominalInterestRate && loanapplication?.freeinterestcalc != true ?
                                                    "For høy rente. Anbefalt rente på søknaden er " + (loanapplication?.calculationResult?.MarginDefault) + " %."
                                                :
                                                    null)
                                            }
                                        >
                                            {inputProps => (
                                                <>
                                                    <InputWithUsability
                                                        suffix="%"
                                                        tabIndex={17}
                                                        type="numeric-text"
                                                        name="nominalinterestrate"
                                                        disabled={
                                                            !showArea("nomRenteRentemaginValgbart") ||
                                                            !(auth.selectedDealer?.AllowInterestChange ?? false)
                                                        }
                                                        step={0.1}
                                                        onBlur={(e) => {
                                                            reCalculateLoan();
                                                        }}
                                                        value={loanapplication?.nominalInterestRate}
                                                        onChangeCustom={e => {
                                                            loanapplication.setNominalInterestRate(e.target.valueAsNumber);
                                                            props.fieldChanged?.("nominalInterestRate", e.target.valueAsNumber);
                                                        }}
                                                        {...inputProps}
                                                    />
                                                </>
                                            )}
                                        </InputGroup>
                                    </GridCol>
                                    <GridCol sm="6">
                                        <div className={styles.roundbuttongroup}>
                                            <SecondaryButton
                                                className={styles.roundbutton}
                                                disabled={
                                                    calculating || 
                                                    !showArea("nomRenteRentemaginValgbart") ||
                                                    !(auth.selectedDealer?.AllowInterestChange ?? false)
                                                }
                                                onClick={() => {                                                    
                                                    loanapplication.setNominalInterestRate(loanapplication?.nominalInterestRate != undefined ?
                                                            (Math.round(((loanapplication?.nominalInterestRate - 0.1) + Number.EPSILON) * 100) / 100)
                                                        :
                                                            0
                                                    );
                                                    props.fieldChanged?.(
                                                        "nominalInterestRate",
                                                        loanapplication?.nominalInterestRate != undefined ?
                                                            (Math.round(((loanapplication?.nominalInterestRate - 0.1) + Number.EPSILON) * 100) / 100)
                                                        :
                                                            0
                                                    );
                                                    // setTimeout(() => {
                                                    //     reCalculateLoan({
                                                    //         nominalInterestRate: loanapplication?.nominalInterestRate != undefined ?
                                                    //                 (Math.round(((loanapplication?.nominalInterestRate - 0.1) + Number.EPSILON) * 100) / 100)
                                                    //             :
                                                    //                 0
                                                    //     });
                                                    // }, 200);
                                                }}
                                            >
                                                <span className="material-symbols-outlined">remove</span>
                                            </SecondaryButton>
                                            <PrimaryButton
                                                className={styles.roundbutton}
                                                disabled={
                                                    calculating || 
                                                    !showArea("nomRenteRentemaginValgbart") ||
                                                    !(auth.selectedDealer?.AllowInterestChange ?? false)
                                                }
                                                onClick={() => {
                                                    loanapplication.setNominalInterestRate(loanapplication?.nominalInterestRate != undefined ?
                                                            (Math.round(((loanapplication?.nominalInterestRate + 0.1) + Number.EPSILON) * 100) / 100)
                                                        :
                                                            0
                                                    );
                                                    props.fieldChanged?.(
                                                        "nominalInterestRate",
                                                        loanapplication?.nominalInterestRate != undefined ?
                                                            (Math.round(((loanapplication?.nominalInterestRate + 0.1) + Number.EPSILON) * 100) / 100)
                                                        :
                                                            0
                                                    );
                                                    // setTimeout(() => {
                                                    //     reCalculateLoan({
                                                    //         nominalInterestRate: loanapplication?.nominalInterestRate != undefined ?
                                                    //                 (Math.round(((loanapplication?.nominalInterestRate + 0.1) + Number.EPSILON) * 100) / 100)
                                                    //             :
                                                    //                 0
                                                    //     });
                                                    // }, 200);
                                                }}
                                            >
                                                <span className="material-symbols-outlined">add</span>
                                            </PrimaryButton>
                                            <Tooltip
                                                leaveDelay={0}
                                                TransitionProps={{ 
                                                    timeout: 0
                                                }}
                                                title={"Tilbakestill rente"}
                                                classes={{ tooltip: "customTooltip" + " " + localStorage.getItem("theme") }}
                                            >
                                                <div>
                                                    <SecondaryButton
                                                        className={styles.roundbutton}
                                                        disabled={
                                                            calculating || 
                                                            !showArea("nomRenteRentemaginValgbart") || 
                                                            (getDefaultNominalInterestRate() == undefined) ||
                                                            loanapplication?.nominalInterestRate == getDefaultNominalInterestRate() ||
                                                            !(auth.selectedDealer?.AllowInterestChange ?? false)
                                                        }
                                                        onClick={() => {
                                                            loanapplication.setNominalInterestRate(getDefaultNominalInterestRate());
                                                            props.resetNominalInterestRateIsDefault?.();
                                                        }}
                                                    >
                                                        <span className="material-symbols-outlined">restart_alt</span>
                                                    
                                                    </SecondaryButton>
                                                </div>
                                            </Tooltip>
                                        </div>
                                    </GridCol>
                                </GridRow>
                            </Grid>
                            { auth.selectedDealer?.AllowInterestChange ?
                                <InputGroup
                                    className={styles.checkboxinputgroup}
                                    tooltip="Skrur på fri kalkulering av rente for å kalkulere uten begrensninger."
                                    //fieldMessage={showErrors ? 'Ugyldig telefonnummer' : null}
                                >
                                    <div className={styles.checkboxcontainer}>
                                        <Checkbox
                                            name="freeinterestcalc"
                                            inline={false}
                                            checked={loanapplication?.freeinterestcalc || false}
                                            onChange={e => {
                                                loanapplication.setFreeinterestcalc(e.target.checked);
                                            }}
                                        >
                                            Fri kalkulering av rente
                                        </Checkbox>
                                    </div>
                                </InputGroup>
                            : null }
                            <Grid className={styles.grid}>
                                <GridRow>
                                    { showArea("lopetidAr") ?
                                        <GridCol sm={showArea("lopetidAr") && showArea("lopetidManed") ? "6" : "12"}>
                                            <InputGroup
                                                label="Løpetid (år)"
                                                tooltip="Løpetid på finansieringen bør tilpasses verdifall på objektet."
                                                fieldMessage={
                                                    (loanapplication?.contractlengthmonths || 0) > auth.selectedAgreement?.ContractDurationMax ?
                                                        'For lang løpetid'
                                                    :
                                                        (loanapplication?.contractlengthmonths || 0) < auth.selectedAgreement?.ContractDurationMin ?
                                                            'For kort løpetid'
                                                        :
                                                            null
                                                }
                                            >
                                                {inputProps => (
                                                    <>
                                                        <Input
                                                            tabIndex={18}
                                                            type="number"
                                                            name="contractlengthyears"
                                                            step={1}
                                                            min={auth.selectedAgreement?.ContractDurationMin != undefined ? Math.round(auth.selectedAgreement?.ContractDurationMin / 12) : 0}
                                                            max={auth.selectedAgreement?.ContractDurationMax != undefined ? Math.round(auth.selectedAgreement?.ContractDurationMax / 12) : 100}
                                                            value={loanapplication?.contractlengthmonths != undefined ? Math.round(((loanapplication?.contractlengthmonths / 12 || 0) + Number.EPSILON) * 10) / 10 : ""}
                                                            onBlur={(e) => {
                                                                reCalculateLoan();
                                                            }}
                                                            onChange={e => {
                                                                loanapplication.setContractlengthmonths(e.target.value != "" ? Math.round(Number(e.target.value) * 12) : undefined);

                                                                props.fieldChanged?.("contractlengthmonths", Math.round(Number(e.target.value) * 12));
                                                            }}
                                                            {...inputProps}
                                                        />
                                                    </>
                                                )}
                                            </InputGroup>
                                        </GridCol>
                                    : null }
                                    { showArea("lopetidManed") ?
                                        <GridCol sm={showArea("lopetidAr") && showArea("lopetidManed") ? "6" : "12"}>
                                            <InputGroup
                                                label="Løpetid (mnd)"
                                                tooltip="Løpetid på finansieringen bør tilpasses verdifall på objektet."
                                                fieldMessage={
                                                    (loanapplication?.contractlengthmonths || 0) > auth.selectedAgreement?.ContractDurationMax ?
                                                        'For lang løpetid'
                                                    :
                                                        (loanapplication?.contractlengthmonths || 0) < auth.selectedAgreement?.ContractDurationMin ?
                                                            'For kort løpetid'
                                                        :
                                                            null
                                                }
                                            >
                                                {inputProps => (
                                                    <>
                                                        <Input
                                                            tabIndex={19}
                                                            type="number"
                                                            name="contractlengthmonths"
                                                            min={auth.selectedAgreement?.ContractDurationMin}
                                                            max={auth.selectedAgreement?.ContractDurationMax}
                                                            value={loanapplication?.contractlengthmonths != undefined ? loanapplication?.contractlengthmonths : ""}
                                                            onBlur={(e) => {
                                                                reCalculateLoan();
                                                            }}
                                                            onChange={e => {
                                                                loanapplication.setContractlengthmonths(e.target.value != "" ? Number(e.target.value) : undefined);

                                                                props.fieldChanged?.("contractlengthmonths",e.target.value);
                                                            }}
                                                            {...inputProps}
                                                        />
                                                    </>
                                                )}
                                            </InputGroup>
                                        </GridCol>
                                    : null }
                                </GridRow>
                            </Grid>
                            { !showArea("tvingForfallForste") ?
                                <InputGroup
                                    extraMargin={false}
                                    label="Forfallsdag"
                                    tooltip="Kunden kan velge hvilke forfallsdag som ønskes."
                                    //fieldMessage={showErrors ? 'Du må velge måned' : null}
                                >
                                    <Dropdown
                                        tabIndex={20}
                                        value={loanapplication?.dueday || 0}
                                        onBlur={(e) => {
                                            reCalculateLoan();
                                        }}
                                        onChange={e => {
                                            loanapplication.setDueday(Number(e.target.value));

                                            props.fieldChanged?.("dueday", e.target.value);
                                        }}
                                        disabled={!auth.selectedAgreement?.DueDayChange ?? false}
                                    >
                                        { [
                                            1,2,3,4,5,6,7,8,9,
                                            10,11,12,13,14,15,16,17,18,19,
                                            20,21,22,23,24,25,26,27,28,29,30,31
                                        ].map((i, key) => {
                                            return <option key={key} value={i}>{i}</option>
                                        }) }
                                    </Dropdown>
                                </InputGroup>
                            : null }
                        </ContentCard>
                    </GridCol>
                    <GridCol lg="6" md="12" sm="12" className="maincol">
                        <ContentCard
                            open={props.open}
                            disabled={props.disabled}
                            className={styles.costoverview}
                            topLeftButton={
                                <span
                                    onClick={() => {
                                        toggleAutoRefresh();
                                    }}
                                    className={"material-symbols-outlined " + styles.costoverviewrefreshbtn + " " + (autoRefresh ? styles.active : "")}
                                >
                                    {autoRefresh ? "autorenew" : "sync_disabled"}
                                </span>
                            }
                            title="Kostnadsoversikt"
                        >
                            <ActionButton
                                isLoading={calculating}
                                disabled={problems.length > 0}
                                onClick={() => {
                                    calculateLoan();
                                }}
                            >Kalkuler</ActionButton>
                            { problems.length > 0 ?
                                <div className={styles.problems}>
                                    { problems.map((problem, key) =>
                                        <p key={key}>{problem}</p>
                                    )}
                                </div>
                            : null }
                            <div
                                className={
                                    styles.resultslist + " " + 
                                    (loanapplication?.calculationResult != undefined && !calculating && !changesMade && problems.length == 0 ? "" : styles.blurred)
                                }
                            >
                                {/* { showArea("kjopesum") || (showArea("ingenMvaVedBrukt") && !loanapplication.caseObjectNew) ?
                                    <div className={styles.result}>
                                        <hr/>
                                        <p>Kjøpesum</p>
                                        <p>{(showArea("ingenMvaVedBrukt") && !loanapplication.caseObjectNew) ? 
                                        general.formatPrice(loanapplication?.calculationResult?.CaseObjectPriceExVAT ?? 0, true, true) :
                                        general.formatPrice(loanapplication?.calculationResult?.CaseObjectPrice ?? 0, true, true)}</p>
                                    </div>
                                : null }
                                {(showArea("ingenMvaVedBrukt") && !loanapplication.caseObjectNew) ? null : 
                                    showArea("kjopesumInklMva") ?
                                        <div className={styles.result}>
                                            <hr/>
                                            <p>Kjøpesum (inkl. mva)</p>
                                            <p>{general.formatPrice(loanapplication?.calculationResult?.CaseObjectPrice ?? 0, true, true)}</p>
                                        </div>
                                    : null 
                                }
                                {(showArea("ingenMvaVedBrukt") && !loanapplication.caseObjectNew) ? null : 
                                    showArea("kjopesumEksMva") ?
                                        <div className={styles.result}>
                                            <hr/>
                                            <p>Kjøpesum (eks. mva)</p>
                                            <p>{general.formatPrice(loanapplication?.calculationResult?.CaseObjectPriceExVAT ?? 0, true, true)}</p>
                                        </div>
                                    : null 
                                }
                                { showArea("kjopesumFritattMva") ?
                                    <div className={styles.result}>
                                        <hr/>
                                        <p>Kjøpesum (fritatt mva)</p>
                                        <p>{loanapplication?.calculationResult?.CaseObjectVATExempt ? "Ja" : "Nei"}</p>
                                    </div>
                                : null } */}
                                {/* { showArea("restverdi") ?
                                    <>
                                        <div className={styles.result}>
                                            <hr/>
                                            <p>Restverdi</p>
                                            <p>{general.formatPrice(loanapplication?.calculationResult?.ResidualValue ?? 0, true, true)}</p>
                                        </div>
                                        <div className={styles.result}>
                                            <hr/>
                                            <p>Restverdi %</p>
                                            <p>{loanapplication?.calculationResult?.CaseObjectPrice != undefined && loanapplication?.calculationResult?.CaseObjectPrice > 0 ?
                                                    `${Number(loanapplication?.calculationResult?.CaseObjectPrice ? 
                                                        (Number(loanapplication?.calculationResult?.ResidualValue) / loanapplication?.calculationResult?.CaseObjectPrice) * 100 
                                                    : 0).toFixed(2)} %`
                                                : undefined}</p>
                                        </div>
                                    </>
                                : null } */}
                                {/* {(showArea("ingenMvaVedBrukt") && !loanapplication.caseObjectNew) ? null : 
                                    showArea("engangsavgiftOgEvVrakpant") ?
                                        <div className={styles.result}>
                                            <hr/>
                                            <p>Engangsavgift og vrakpant</p>
                                            <p>{general.formatPrice(loanapplication?.calculationResult?.CaseObjectRegistryFee ?? 0, false, true)}</p>
                                        </div>
                                    : null
                                } */}
                                {/* { showArea("startleie") || (showArea("ingenMvaVedBrukt") && !loanapplication.caseObjectNew) ?
                                    <>
                                        <div className={styles.result}>
                                            <hr/>
                                            <p>Startleie</p>
                                            <p>{general.formatPrice(loanapplication?.calculationResult?.DownPayment ?? 0, false, true)}</p>
                                        </div>
                                        <div className={styles.result}>
                                            <hr/>
                                            <p>Startleie %</p>
                                            <p>{loanapplication?.calculationResult?.CaseObjectPriceExVAT != undefined && loanapplication?.calculationResult?.CaseObjectPriceExVAT > 0 ?
                                                    `${Number(loanapplication?.calculationResult?.CaseObjectPriceExVAT ? 
                                                        (Number(loanapplication?.calculationResult?.DownPayment) / loanapplication?.calculationResult?.CaseObjectPriceExVAT) * 100 
                                                    : 0).toFixed(2)} %`
                                                : undefined}</p>
                                        </div>
                                    </>
                                : null }
                                {(showArea("ingenMvaVedBrukt") && !loanapplication.caseObjectNew) ? null : 
                                    showArea("startleieEksMva") ?
                                        <>
                                            <div className={styles.result}>
                                                <hr/>
                                                <p>Startleie (eks mva)</p>
                                                <p>{general.formatPrice(loanapplication?.calculationResult?.DownPayment ?? 0, false, true)}</p>
                                            </div>
                                            <div className={styles.result}>
                                                <hr/>
                                                <p>Startleie (eks mva) %</p>
                                                <p>{loanapplication?.calculationResult?.CaseObjectPriceExVAT != undefined && loanapplication?.calculationResult?.CaseObjectPriceExVAT > 0 ?
                                                        `${Number(loanapplication?.calculationResult?.CaseObjectPriceExVAT ? 
                                                            (Number(loanapplication?.calculationResult?.DownPayment) / loanapplication?.calculationResult?.CaseObjectPriceExVAT) * 100 
                                                        : 0).toFixed(2)} %`
                                                    : undefined}</p>
                                            </div>
                                        </>
                                    : null
                                }
                                {(showArea("ingenMvaVedBrukt") && !loanapplication.caseObjectNew) ? null : 
                                    showArea("startleieInklMva") ?
                                        <>
                                            <div className={styles.result}>
                                                <hr/>
                                                <p>Startleie (inkl mva)</p>
                                                <p>{general.formatPrice((loanapplication?.calculationResult?.DownPayment + loanapplication?.calculationResult?.DownPaymentVAT) ?? 0, false, true)}</p>
                                            </div>
                                            <div className={styles.result}>
                                                <hr/>
                                                <p>Startleie (inkl mva) %</p>
                                                <p>{loanapplication?.calculationResult?.CaseObjectPrice != undefined && loanapplication?.calculationResult?.CaseObjectPrice > 0 ?
                                                        `${Number(loanapplication?.calculationResult?.CaseObjectPrice ? 
                                                            (Number(loanapplication?.calculationResult?.DownPayment + loanapplication?.calculationResult?.DownPaymentVAT) / loanapplication?.calculationResult?.CaseObjectPrice) * 100 
                                                        : 0).toFixed(2)} %`
                                                    : undefined}</p>
                                            </div>
                                        </>
                                    : null 
                                }
                                { showArea("egenkapital") ?
                                    <>
                                        <div className={styles.result}>
                                            <hr/>
                                            <p>Egenkapital</p>
                                            <p>{general.formatPrice(loanapplication?.calculationResult?.DownPayment ?? 0, false, true)}</p>
                                        </div>
                                        <div className={styles.result}>
                                            <hr/>
                                            <p>Egenkapital %</p>
                                            <p>{loanapplication?.calculationResult?.CaseObjectPrice != undefined && loanapplication?.calculationResult?.CaseObjectPrice > 0 ?
                                                    `${Number(loanapplication?.calculationResult?.CaseObjectPrice ? 
                                                        (Number(loanapplication?.calculationResult?.DownPayment) / loanapplication?.calculationResult?.CaseObjectPrice) * 100 
                                                    : 0).toFixed(2)} %`
                                                : undefined}</p>
                                        </div>
                                    </>
                                : null } */}
                                {/* <div className={styles.result}>
                                    <hr/>
                                    <p>Fri kalkulering av rente</p>
                                    <p>{loanapplication?.freeinterestcalc ? "Ja" : "Nei"}</p>
                                </div> */}
                                {/* { showArea("lopetidAr") ?
                                    <div className={styles.result}>
                                        <hr/>
                                        <p>Løpetid (år)</p>
                                        <p>{Math.round(((loanapplication?.calculationResult?.Duration / 12 || 0) + Number.EPSILON) * 10) / 10} år</p>
                                    </div>
                                : null } */}
                                { (showArea("ingenMvaVedBrukt") && !loanapplication?.caseObjectNew) ||
                                showArea("terminbelop") || showArea("terminbelopInklMva") ?
                                    <div className={styles.result}>
                                        <hr/>
                                        <p>Terminbeløp</p>
                                        <p>{general.formatPrice(
                                            loanapplication?.calculationResult?.TotalPeriodicAmount + 
                                            loanapplication?.calculationResult?.TotalPeriodicAmountVat, false, true
                                        ) ?? "N/A"}</p>
                                    </div>
                                : null} 
                                { showArea("ingenMvaVedBrukt") && !loanapplication?.caseObjectNew ? null :  
                                showArea("terminbelopEksMva") || showArea("terminbelopFritattMva") ?
                                    <div className={styles.result}>
                                        <hr/>
                                        <p>Terminbeløp (eks. mva)</p>
                                        <p>{general.formatPrice(
                                            loanapplication?.calculationResult?.TotalPeriodicAmount, false, true
                                        ) ?? "N/A"}</p>
                                    </div>
                                : null}
                                { showArea("nominellRente") ?
                                    <div className={styles.result}>
                                        <hr/>
                                        <p>Nominell rente</p>
                                        <p>{general.formatPercentage(loanapplication?.calculationResult?.BaseInterest + loanapplication?.calculationResult?.Interest) ?? "N/A"}</p>
                                    </div>
                                : null }
                                { showArea("effektivRente") ?
                                    <div className={styles.result}>
                                        <hr/>
                                        <p>Effektiv rente</p>
                                        <p>{general.formatPercentage(loanapplication?.calculationResult?.EffectiveInterest) ?? "N/A"}</p>
                                    </div>
                                : null }
                                { showArea("lopetidManed") ?
                                    <div className={styles.result}>
                                        <hr/>
                                        <p>Løpetid (mnd)</p>
                                        <p>{loanapplication?.calculationResult?.Duration} mnd</p>
                                    </div>
                                : null }
                                { showArea("termingebyr") || showArea("termingebyrEksMva") ?
                                    <div className={styles.result}>
                                        <hr/>
                                        <p>{"Termingebyr" + (showArea("termingebyrEksMva") ? " (eks. mva)" : "")}</p>
                                        <p>{general.formatPrice(loanapplication?.calculationResult?.InvoiceFee ?? 0, false, true)}</p>
                                    </div>
                                : null }
                                { showArea("etableringskostnad") ?
                                    <div className={styles.result}>
                                        <hr/>
                                        <p>Etableringskostnad</p>
                                        <p>{general.formatPrice(loanapplication?.calculationResult?.AdministrationFee ?? 0, false, true)}</p>
                                    </div>
                                : null }
                                { showArea("tinglysningsgebyr") ?
                                    <div className={styles.result}>
                                        <hr/>
                                        <p>Tinglysningsgebyr</p>
                                        <p>{general.formatPrice(loanapplication?.calculationResult?.DocumentFee ?? 0, false, true)}</p>
                                    </div>
                                : null }
                                { showArea("beregningsgrunnlag") ?
                                    <div className={styles.result}>
                                        <hr/>
                                        <p>Beregningsgrunnlag</p>
                                        <p>{general.formatPrice(
                                            (loanapplication?.calculationResult?.FinancedAmount ?? 0), false, true
                                        ) ?? "N/A"}</p>
                                    </div>
                                : null}

                                {/* { !showArea("tvingForfallForste") ?
                                    <div className={styles.result}>
                                        <hr/>
                                        <p>Forfallsdag</p>
                                        <p>{loanapplication?.calculationResult?.DueDay}</p>
                                    </div>
                                : null } */}

                                { loanapplication?.calculationResult?.Expenses?.map((expense: any, key: any) => {
                                    if (expense.BudgetClassSequence == 5 && !showArea("korreksjonMvaPrMndPersonbil")) return undefined;
                                    return (
                                        <div className={styles.result} key={key}>
                                            <hr/>
                                            <p style={{
                                                paddingRight: "14px"
                                            }}>{general.getBudgetClassName(expense)}</p>
                                            <p>{general.formatPrice(
                                                expense.BudgetClassSequence == 5 ? (expense.Amount * 1.25) : expense.Amount, false, true
                                            ) ?? "N/A"}</p>
                                        </div>
                                    );
                                }).filter((i: any) => i != undefined) ?? null }
                                {/* { (showArea("midlenesOpprinnelse") && loanapplication?.downPaymentSum > 0) || (showArea("startleieMidlenesOpprinnelse") && (loanapplication?.startRentInclMva > 0 || loanapplication?.startRentExclMva > 0)) ?
                                    <div className={styles.result}>
                                        <hr/>
                                        <p>Midlenes opprinnelse</p>
                                        <p>{general.getAgreement(auth.selectedDealer, loanapplication)?.agreement?.schema.midlenesopprinnelse?.find((item: any) => item.Kode == loanapplication?.downPaymentSource)?.Navn}</p>
                                    </div>
                                : null } */}
                                
                                { showArea("printKalkyleMulig") ?
                                    <SecondaryButton
                                        className={styles.printbutton}
                                        isLoading={calculating}
                                        onClick={() => {
                                            props.printClicked?.();
                                        }}
                                        disabled={
                                            loanapplication?.calculationResult != undefined &&
                                            !calculating &&
                                            !changesMade &&
                                            loanapplication?.freeinterestcalc != true &&
                                            problems.length == 0 ?
                                                false
                                            :
                                                true
                                        }
                                    >Skriv ut kalkulering</SecondaryButton>
                                : null}
                                { showArea("visUtvidetInfo") ?
                                    <>
                                        <hr/>
                                        <p style={{
                                            cursor: "pointer",
                                            color: "#0072c6",
                                        }} onClick={() => {
                                            setShowExtraInfo(!showExtraInfo);
                                        }}>Vis utvidet info</p>
                                        { showExtraInfo ?
                                            <>
                                                <div className={styles.result}>
                                                    <hr style={{display: "none"}} />
                                                    <p>Totalt</p>
                                                    <p>{general.formatPrice(loanapplication?.calculationResult?.CommissionUpfrontDealer ?? 0, false, true)}</p>
                                                </div>
                                                <p className={styles.totalcost}>
                                                    Total kostnad over {loanapplication?.calculationResult?.Duration} mnd: {
                                                        general.formatPrice(
                                                            (loanapplication?.calculationResult?.TotalCreditCost ?? 0) +
                                                            (loanapplication?.calculationResult?.FinancedAmount ?? 0), false, true
                                                        )
                                                    }
                                                </p>
                                            </>
                                        : null}
                                    </>
                                : null}
                            </div>
                        </ContentCard>
                    </GridCol>
                </GridRow>
            </Grid>
        </div>
    );
}

class CalculatorProps {
    open?: boolean;
    disabled?: boolean;
    agreement?: Agreement;
    fieldChanged?: (field: string, value: any) => void;
    nominalInterestRateChanged?: (value: any) => void;
    nominalInterestRateIsDefault?: boolean;
    resetNominalInterestRateIsDefault?: () => void;
    printClicked?: () => void;
    onChangesMade?: (value: boolean) => void;
}

export default Calculator;