import React, { useEffect } from "react";

import styles from './YearCompare.module.scss';
import ContentCard from "../../../../Shared/ContentCard/ContentCard";

import {
    Chart as ChartJS,
    CategoryScale,
    LinearScale,
    Title,
    Tooltip,
    Legend,
    PointElement,
    LineElement,
    ArcElement,
} from 'chart.js';
import { Doughnut} from "react-chartjs-2";

ChartJS.register(
    CategoryScale,
    LinearScale,
    PointElement,
    LineElement,
    ArcElement,
    Title,
    Tooltip,
    Legend,
);

function YearCompare(props: YearCompareProps) {
    const valueDefault = [0, 0];
    
    const [redraw, setRedraw] = React.useState(false);

    useEffect(() => {
        setRedraw(!redraw);
    }, [(window as any).colorPrimary, (window as any).colorSecondary]);
    
    return (
        <div className={styles.yearcompare}>
            <ContentCard
                title="Volum"
                open={true}
                disableClick={true}
                loading={props.data === undefined}
            >
                <div className={styles.doughnutchart}>
                    <p className={styles.centertext}>{props.data?.year[1]}/{props.data?.year[0]}</p>
                    {props?.data?.value !== undefined && !props.data.value.every(v => v == 0) ? 
                        <Doughnut
                            redraw={redraw}
                            data={{
                                datasets: [
                                    {
                                        label: 'My Doughnut Dataset',
                                        data: (props.data?.value ?? valueDefault),
                                        backgroundColor: [
                                            (window as any).colorPrimary,
                                            (window as any).colorSecondary
                                        ],
                                        hoverOffset: localStorage.getItem("theme") == "light" ? 14 : 0,
                                        borderAlign: 'inner',
                                        borderJoinStyle: 'round',
                                        borderWidth: localStorage.getItem("theme") == "light" ? 0 : 1,
                                        borderRadius: 10,
                                        spacing: localStorage.getItem("theme") == "light" ? -4 : 0,
                                    }
                                ]
                            }}  
                            options={{
                                maintainAspectRatio: false,
                                cutout: '80%',
                                layout: {
                                    padding: 20,
                                },
                                plugins: {
                                    tooltip: {
                                        callbacks: {
                                            label: (data) => {
                                                try{
                                                    if (data.raw == data.dataset.data[0]) {
                                                        return 'Forrige år: ' + data.raw;
                                                    } else {
                                                        return 'Hittil i år: ' + data.raw;
                                                    }
                                                } catch (err){
                                                    console.log(err);
                                                    return "";
                                                }
                                            }
                                        }
                                    },
                                }
                            }}
                        />
                    : null }
                </div>
                <div className={styles.doughnuttext}>
                    <div className={styles.left}>
                        <p>Hittil i år:</p>
                        <p>Forrige år:</p>
                    </div>
                    <div className={styles.right}>
                        <p>{props.data?.value?.[1]}</p>
                        <p>{props.data?.value?.[0]}</p>
                    </div>
                </div>
            </ContentCard>
        </div>
    );
}

interface YearCompareProps {
    data: {
        year: Array<number>,
        value: Array<number>
    } | undefined
}

export default YearCompare;