import React, { useEffect } from "react";
import { useAuth } from "../../../Auth";
import Notifications from "./MessageComponents/Notifications";
import { toast } from 'react-toastify';

import styles from './Messages.module.scss';

function Messages(props: MessagesProps) {
    const auth = useAuth();

    const [seenNewMessages, setSeenNewMessages] = React.useState(false);
    const [open, setOpen] = React.useState(false);
    const [activeTab, setActiveTab] = React.useState(0);

    const [newMessages, setNewMessages] = React.useState(0);

    useEffect(() => {
        if (newMessages > 0 && !seenNewMessages) {
            toast.info("Du har " + newMessages + " nye meldinger! Klikk her for å lese.", {
                onClick: () => {
                    setOpen(true);
                    setSeenNewMessages(true);
                },
                position: "top-right",
            });
        }
    }, [newMessages, seenNewMessages]);

    return (
        <>
            <div className={styles.messages + " " + "noprint"}>
                { auth.user != undefined && auth.user.jwt != "" ? 
                    <>
                        <div
                            className={styles.floatingbutton}
                            onClick={() => {
                                if (!open) {
                                    setSeenNewMessages(true);
                                }
                                setOpen(!open);
                            }}
                        >
                            { newMessages > 0 ? <span className={styles.newmessages}>{newMessages}</span> : null }
                            <span className="material-symbols-outlined">
                                notifications_active
                            </span>
                        </div>
                        <div className={styles.container} style={{ visibility: open ? "visible" : "hidden" }}>
                            <div className={styles.tabs}>
                                <div className={styles.tab + " " + (activeTab == 0 ? styles.active : "")} onClick={() => setActiveTab(0)}>
                                    <h3>Notifikasjoner</h3>
                                </div>
                                {/* <div className={styles.tab + " " + (activeTab == 1 ? styles.active : "")} onClick={() => setActiveTab(1)}>
                                    <h3>Chat</h3>
                                </div> */}
                            </div>
                            <div className={styles.componentcontainer}>
                                { activeTab == 0 &&
                                    <Notifications
                                        onLoad={(messages) => setNewMessages(messages.length)}
                                    />
                                }
                            </div>
                        </div>
                    </>
                : null }
            </div>
            <div className={styles.messagesbackdrop + " " + "noprint"} style={{ display: open ? "block" : "none" }} onClick={() => setOpen(false)}></div>
        </>
    );
}

class MessagesProps {
    
}

export default Messages;