import React, { useContext, createContext, useState, useEffect } from "react";

const axiosImport = require('axios').default;

var refreshProcess: Promise<any> | undefined = undefined;
var isRefreshing = false;

const axiosContext = createContext({
    axios: {
        post: (link: string, data: Object, config: Object): Promise<any> => { return new Promise<any>((resolve, reject) => { }); },
        delete: (link: string, data: Object, config: Object): Promise<any> => { return new Promise<any>((resolve, reject) => { }); },
        get: (link: string, data: Object, config: Object): Promise<any> => { return new Promise<any>((resolve, reject) => { }); },
        put: (link: string, data: Object, config: Object): Promise<any> => { return new Promise<any>((resolve, reject) => { }); },
        all: (data: Object): Promise<any> => { return new Promise<any>((resolve, reject) => { }); },
        single: (data: Object): Promise<any> => { return new Promise<any>((resolve, reject) => { }); },
        spread: (data: Object): Promise<any> => { return new Promise<any>((resolve, reject) => { }); },
    }
});

export const useAxios = () => {
    return useContext(axiosContext);
};

export const ProvideAxios = ({ children }: any) => {
    const axios = useProvideAxios();
    return <axiosContext.Provider value={axios}>{children}</axiosContext.Provider>;
}

export const useProvideAxios = () => {
    const axios = {
        post: post,
        delete: deleteFunc,
        get: get,
        put: put,
        all: all,
        single: single,
        spread: spread
    };

    const [responseHandler, setResponseHandler] = useState(null);
    const [requestHandler, setRequestHandler] = useState(null);

    // Function to refresh the token
    async function refresh(refreshToken: string): Promise<any> {
        return new Promise<Object>(async (resolve, reject) => {
            let logindata: any;

            await axiosImport.post('/api/login/refresh', {
                refreshToken: refreshToken,
            }, { noIntercept: true }).then(function (response: any) {
                logindata = response.data;
                logindata.jwt = logindata.JWT;
                let oldLogindata = localStorage.getItem('logindata');

                localStorage.setItem('logindata', JSON.stringify({
                    ...JSON.parse(oldLogindata || "{}"),
                    ...logindata
                }));

                // Dispatch a storage event to notify other tabs and auth system of new logindata
                window.dispatchEvent(new Event('storage'));

                resolve(logindata);
            }).catch(function (error: any) {
                console.log("Token refresh failed");
                reject(error);
            });
        });
    };
    
    // Interceptor after request is sent, to detect unauthorized and error responses
    async function unauthorizedHandler(error: any) {
        if (error.response.status === 401 || error.response.status === 403) {

            // Clear local storage and redirect to login page
            console.log("Axios unauthorized error!");
            localStorage.clear();
    
            // Navigate to the login page and keep url params
            window.location.href = "/login" + window.location.search;
    
            return error.config;
        }

        // If the error is not unauthorized, categorize it as a general error
        if (error.response.status < 400 || error.response.status >= 500) {
            console.log("Something went wrong!");
        }
        return error.response; //Changed from error.config to error.response, as error.config did not contain the needed response
    }
    
    // Interceptor before requests to check if token is expired and refresh it if needed
    async function refreshTokenHandler(config: any) {
        if (config?.noIntercept == true) return config;

        const logindata = localStorage.getItem("logindata");
        if (logindata != null) {
            const logindataJson = JSON.parse(logindata);
            const tokenExpirationDate = new Date(logindataJson.Expires);
            const now = new Date();

            // Add 2 minutes to the current time to make sure the token is not expired when the request is sent
            now.setMinutes(now.getMinutes() + 2);

            if (tokenExpirationDate < now && logindataJson.RefreshToken != null) {
                if (!localStorage.getItem("isRefreshing")) {
                    localStorage.setItem("isRefreshing", "true");

                    // Start a refresh process
                    refreshProcess = refresh(logindataJson.RefreshToken);
                    const newLogindata = await refreshProcess;
                    config.headers.UserAuthorization = `Bearer ${newLogindata.jwt}`;

                    localStorage.removeItem("isRefreshing");

                    return config;
                } else { // If a refresh process is started by any other request, wait for it to finish
                    if (refreshProcess != null) {
                        await refreshProcess;
                    }

                    // Get the new token from local storage and add it to the request
                    const logindataNew = localStorage.getItem("logindata");
                    if (logindataNew != null) {
                        const logindataJsonNew = JSON.parse(logindataNew);
                        config.headers.UserAuthorization = `Bearer ${logindataJsonNew.jwt}`;
                    }

                    return config;
                }
            }
            return config;
        }
        return config;
    }
    
    async function put(link: string, data: Object, config: Object) {
        if (responseHandler == null) setResponseHandler(axiosImport.interceptors.response.use(undefined, await unauthorizedHandler));
        if (requestHandler == null) setRequestHandler(axiosImport.interceptors.request.use(refreshTokenHandler, undefined));
        const promise = axiosImport.put(link, data, { ...config, timeout: 60000 });
        return promise;
    }
    
    async function post(link: string, data: Object, config: Object) {
        if (responseHandler == null) setResponseHandler(axiosImport.interceptors.response.use(undefined, await unauthorizedHandler));
        if (requestHandler == null) setRequestHandler(axiosImport.interceptors.request.use(refreshTokenHandler, undefined));
        const promise = axiosImport.post(link, data, { ...config, timeout: 60000 });
        return promise;
    }
    
    function deleteFunc(link: string, data: Object, config: any) {
        if (responseHandler == null) setResponseHandler(axiosImport.interceptors.response.use(undefined, unauthorizedHandler));
        if (requestHandler == null) setRequestHandler(axiosImport.interceptors.request.use(refreshTokenHandler, undefined));
        const promise = axiosImport.delete(link, { headers: config.headers, data: { ...data, config: { ...(data as any).config, timeout: 60000 } } });
        return promise;
    }
    
    function get(link: string, data: Object, config: Object) {
        if (responseHandler == null) setResponseHandler(axiosImport.interceptors.response.use(undefined, unauthorizedHandler));
        if (requestHandler == null) setRequestHandler(axiosImport.interceptors.request.use(refreshTokenHandler, undefined));
        const promise = axiosImport.get(link, data, { ...config, timeout: 60000 });
        return promise;
    }
    
    function single(data: Object) {
        if (responseHandler == null) setResponseHandler(axiosImport.interceptors.response.use(undefined, unauthorizedHandler));
        if (requestHandler == null) setRequestHandler(axiosImport.interceptors.request.use(refreshTokenHandler, undefined));
        const promise = axiosImport({ ...data, config: { ...(data as any).config, timeout: 60000 }});
        return promise;
    }
    
    function all(data: Object) {
        if (responseHandler == null) setResponseHandler(axiosImport.interceptors.response.use(undefined, unauthorizedHandler));
        if (requestHandler == null) setRequestHandler(axiosImport.interceptors.request.use(refreshTokenHandler, undefined));
        const promise = axiosImport.all({ ...data, config: { ...(data as any).config, timeout: 60000 }});
        return promise;
    }
    
    function spread(data: Object) {
        if (responseHandler == null) setResponseHandler(axiosImport.interceptors.response.use(undefined, unauthorizedHandler));
        if (requestHandler == null) setRequestHandler(axiosImport.interceptors.request.use(refreshTokenHandler, undefined));
        return axiosImport.spread({ ...data, config: { ...(data as any).config, timeout: 60000 }});
    }

    return {
        axios
    };
};