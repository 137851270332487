import React from "react";

import styles from './ChangePasswordCard.module.scss';
import ContentCard from "../../../Shared/ContentCard/ContentCard";
import { Input, InputGroup } from "@sb1/ffe-form-react";
import { ActionButton } from "@sb1/ffe-buttons-react";
import { toast } from "react-toastify";
import { useAxios } from "../../../../AxiosHandler";
import { useAuth } from "../../../../Auth";

function ChangePasswordCard(props: ChangePasswordCardProps) {
    const auth = useAuth();
    const axiosHandler = useAxios();
    const axios = axiosHandler.axios;
    
    const [loading, setLoading] = React.useState<boolean>(false);

    const [oldPassword, setOldPassword] = React.useState<string>("");
    const [newPassword, setNewPassword] = React.useState<string>("");
    const [confirmPassword, setConfirmPassword] = React.useState<string>("");

    // Requires at least 8 characters, one uppercase, one lowercase and one number
    const passRegex = /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)[a-zA-Z\d]{8,}$/;

    function updatePassword(oldPassword: string, newPassword: string) {
        setLoading(true);
        toast.info("Oppdaterer passord ...", { autoClose: false });

        axios.single({
            url: "/api/users/password",
            method: "PUT",
            headers: {
                "UserAuthorization": "Bearer " + (JSON.parse(localStorage.getItem("logindata") ?? "")["JWT"])
            },
            data: {
                OldPassword: oldPassword,
                NewPassword: newPassword
            }
        }).then((res: any) => {
            setLoading(false);
            toast.dismiss();
            toast.success("Passord oppdatert!");
            props.onPasswordUpdated?.();
        }).catch((err: any) => {
            setLoading(false);
            toast.dismiss();
            console.log(err);
            toast.error("Oppdatering av passord feilet!");
        });
    }

    return (
        <ContentCard
            open={props.open}
            title="Bytt passord"
            onClick={props.onClick}
            className={styles.changepasswordcard}
        >
            <InputGroup
                label={"Gammelt passord"}
            >
                {inputProps => (
                    <Input
                        type="password"
                        name="oldPassword"
                        value={oldPassword}
                        onChange={e => {
                            setOldPassword(e.target.value);
                        }}
                        {...inputProps}
                    />
                )}
            </InputGroup>
            &nbsp;
            <hr/>
            <InputGroup
                label={"Nytt passord"}
                fieldMessage={
                    newPassword != "" && !passRegex.test(newPassword) ?
                        "Passordet må inneholde minst 8 tegn, en stor bokstav, en liten bokstav og et tall"
                    : undefined
                }
            >
                {inputProps => (
                    <Input
                        type="password"
                        name="newPassword"
                        value={newPassword}
                        onChange={e => {
                            setNewPassword(e.target.value);
                        }}
                        {...inputProps}
                    />
                )}
            </InputGroup>
            <InputGroup
                label={"Bekreft nytt passord"}
                fieldMessage={
                    confirmPassword != "" && newPassword != confirmPassword ?
                        "Passordene er ikke like"
                    : undefined
                }
            >
                {inputProps => (
                    <Input
                        type="password"
                        name="confirmPassword"
                        value={confirmPassword}
                        onChange={e => {
                            setConfirmPassword(e.target.value);
                        }}
                        {...inputProps}
                    />
                )}
            </InputGroup>
            <ActionButton
                disabled={
                    oldPassword == "" ||
                    newPassword == "" ||
                    confirmPassword == "" ||
                    !passRegex.test(newPassword) ||
                    newPassword != confirmPassword ||
                    loading
                }
                onClick={() => {
                    updatePassword(oldPassword, newPassword);
                }}
            >
                Sett nytt passord
            </ActionButton>
        </ContentCard>
    );
}

interface ChangePasswordCardProps {
    open: boolean;
    onClick?: () => void;
    onPasswordUpdated?: () => void;
}

export default ChangePasswordCard;