import { config } from "./config";
import { formatPhoneNumber } from "./dataSanitation";

const general = {
    formatPrice: (number: Number, noPrefix: boolean = false, clearZeroDecimals: boolean = false, noSuffix: boolean = false) => {
        if (number === undefined) return "";
        let numberString = number.toLocaleString('no-NO', { style: 'currency', currency: 'NOK' });
        if (noPrefix) numberString = numberString.replace("kr" + String.fromCharCode(160), "");
        if (clearZeroDecimals && numberString.includes(",00")) numberString = numberString.replace(",00", ",-");
        if (noSuffix) numberString = numberString.replace("kr", "");
        if (noSuffix) numberString = numberString.replace(",-", "");
        return numberString;
    },
    formatPercentage: (number: Number) => {
        if (number === undefined) return "";
        return (Number(number) / 100).toLocaleString('no-NO', { style: 'percent', minimumFractionDigits: 2 });
    },
    getReadableFremtindProductname(product: string) {
        switch (product) {
            case "BIL": return "Bil";
            case "BIL_NAERING": return "Bil (næring)";
            case "BOBIL": return "Bobil";
            default: return product;
        }
    },
    showArea: (area:string, selectedAgreement: any, selectedDealer: any, currentLoanapplication: any) => {
        return selectedAgreement != undefined &&
            selectedDealer?.DealerGroupAgreementConnection != undefined &&
            general.getAgreement(selectedDealer, currentLoanapplication)?.agreement?.schema?.fields?.includes(area);
    },
    generateXRequestId: () => {
        return "xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx".replace(/[xy]/g, function (c) {
            let r = Math.random() * 16 | 0, v = c == "x" ? r : ((r & 0x3) | 0x8);
            return v.toString(16);
        });
    },
    getCaseAgreement: (selectedDealer: any, currentCase: any) => {
        let agreements = selectedDealer?.DealerGroupAgreementConnection || [];
        let allSet = true;

        if(currentCase?.FinanceCaseExtension == undefined){
            return {
                agreement: undefined,
                exactAgreement: false,
            };
        }

        if (currentCase?.FinanceCaseExtension?.[0]?.SchemaCustomerType != '' && currentCase?.FinanceCaseExtension?.[0]?.SchemaCustomerType != undefined) {
            agreements = agreements.filter((i:any) => i.customertype["name"] == currentCase?.FinanceCaseExtension?.[0]?.SchemaCustomerType);
        } else {
            allSet = false;
        }
        if (currentCase?.Type != '' && currentCase?.Type != undefined) {
            agreements = agreements.filter((i:any) => i.financingtype["id"] == currentCase?.Type);
        } else {
            allSet = false;
        }
        if (currentCase?.FinanceCaseExtension?.[0]?.SchemaObjectVariant != '' && currentCase?.FinanceCaseExtension?.[0]?.SchemaObjectVariant != undefined) {
            agreements = agreements.filter((i:any) => (i.objectvariant["id"]) == currentCase?.FinanceCaseExtension?.[0]?.SchemaObjectVariant.split("|")?.[1]);
        } else {
            allSet = false;
        }
        if (currentCase?.FinanceCaseExtension?.[0]?.SchemaAgreementVariant != '' && currentCase?.FinanceCaseExtension?.[0]?.SchemaAgreementVariant != undefined) {
            agreements = agreements.filter((i:any) => i.agreementvariant["name"] == currentCase?.FinanceCaseExtension?.[0]?.SchemaAgreementVariant);
        } else {
            allSet = false;
        }
        if (currentCase?.CaseObjects?.[0]?.IsNew !== '' && currentCase?.CaseObjects?.[0]?.IsNew !== undefined) {
            agreements = agreements.filter((i:any) => i.objectstate["name"] == (currentCase?.CaseObjects?.[0]?.IsNew ? "Ny" : "Brukt"));
        } else {
            allSet = false;
        }

        return {
            agreement: agreements.length == 1 || (allSet && agreements.length > 0) ? agreements[0] : undefined,
            exactAgreement: agreements.length == 1 || (allSet && agreements.length > 0),
        };
    },
    getAgreement: (selectedDealer: any, currentLoanapplication: any) => {
        let agreements = selectedDealer?.DealerGroupAgreementConnection || [];
        let allSet = true;

        if (currentLoanapplication?.segment != '' && currentLoanapplication?.segment != undefined) {
            agreements = agreements.filter((i:any) => i.customertype["name"] == currentLoanapplication?.segment);
        } else {
            allSet = false;
        }
        if (currentLoanapplication?.type != '' && currentLoanapplication?.type != undefined) {
            agreements = agreements.filter((i:any) => i.financingtype["name"] == currentLoanapplication?.type);
        } else {
            allSet = false;
        }
        if (currentLoanapplication?.category != '' && currentLoanapplication?.category != undefined) {
            agreements = agreements.filter((i:any) => (i.objecttype["name"] + "|" + i.objectvariant["name"]) == currentLoanapplication?.category);
        } else {
            allSet = false;
        }
        if (currentLoanapplication?.variant != '' && currentLoanapplication?.variant != undefined) {
            agreements = agreements.filter((i:any) => i.agreementvariant["name"] == currentLoanapplication?.variant);
        } else {
            allSet = false;
        }
        if (currentLoanapplication?.caseObjectNew !== '' && currentLoanapplication?.caseObjectNew !== undefined) {
            agreements = agreements.filter((i:any) => i.objectstate["name"] == (currentLoanapplication?.caseObjectNew ? "Ny" : "Brukt"));
        } else {
            allSet = false;
        }

        return {
            agreement: agreements.length == 1 || (allSet && agreements.length > 0) ? agreements[0] : undefined,
            exactAgreement: agreements.length == 1 || (allSet && agreements.length > 0),
        };
    },
    checkProblems: (currentLoanapplication: any, selectedAgreement: any, objectCategorySequence: any = undefined) => {
        function getInterestMin (currentLoanapplication: any, selectedAgreement: any) {
            let agreementMin = (selectedAgreement?.InterestMarginMin ?? 0) + (selectedAgreement?.BaseInterest ?? 0);
            let calculateMin = (currentLoanapplication?.calculationResult?.MarginMin ?? 0) + (currentLoanapplication?.calculationResult?.BaseInterest ?? 0);
            return Math.max(agreementMin, calculateMin);
        }
        function getInterestMax (currentLoanapplication: any, selectedAgreement: any) {
            let agreementMax = (selectedAgreement?.InterestMarginMax ?? 100) + (selectedAgreement?.BaseInterest ?? 0);
            let calculateMax = (currentLoanapplication?.calculationResult?.MarginMax ?? 100) + (currentLoanapplication?.calculationResult?.BaseInterest ?? 0);
            return Math.min(agreementMax, calculateMax);
        }

        let problemsTemp = [];
        if ((currentLoanapplication?.contractlengthmonths || 0) > selectedAgreement?.ContractDurationMax)
            problemsTemp.push("Løpetid kan ikke overstige " + selectedAgreement?.ContractDurationMax + " måneder");

        if ((currentLoanapplication?.contractlengthmonths || 0) < selectedAgreement?.ContractDurationMin)
            problemsTemp.push("Løpetid kan ikke være mindre enn " + selectedAgreement?.ContractDurationMin + " måneder");

        if ((currentLoanapplication?.downPaymentPercentage || 0) > selectedAgreement?.DownPaymentPctMax)
            problemsTemp.push("Egenandel kan ikke overstige " + selectedAgreement?.DownPaymentPctMax + " %");

        if ((currentLoanapplication?.downPaymentPercentage || 0) < selectedAgreement?.DownPaymentPctMin)
            problemsTemp.push("Egenandel kan ikke være mindre enn " + selectedAgreement?.DownPaymentPctMin + " %");

        if ((currentLoanapplication?.downPaymentSum || 0) < 0)
            problemsTemp.push("Egenkapital kan ikke være mindre enn 0");
        
        if ((currentLoanapplication?.startRentInclMva || 0) < 0)
            problemsTemp.push("Startleie kan ikke være mindre enn 0");
        
        if ((currentLoanapplication?.startRentExclMva || 0) < 0)
            problemsTemp.push("Startleie kan ikke være mindre enn 0");
        
        if ((currentLoanapplication?.startRent || 0) < 0)
            problemsTemp.push("Startleie kan ikke være mindre enn 0");

        if ((currentLoanapplication?.nominalInterestRate || 0) > getInterestMax(currentLoanapplication, selectedAgreement))
            problemsTemp.push("Rente kan ikke være høyere enn " + getInterestMax(currentLoanapplication, selectedAgreement) + " %. Anbefalt rente på søknaden er " + (currentLoanapplication?.calculationResult?.MarginDefault) + " %");

        if ((currentLoanapplication?.nominalInterestRate || 0) < getInterestMin(currentLoanapplication, selectedAgreement))
            problemsTemp.push("Rente kan ikke være lavere enn " + getInterestMin(currentLoanapplication, selectedAgreement) + " %. Anbefalt rente på søknaden er " + (currentLoanapplication?.calculationResult?.MarginDefault) + " %");
        
        if (
            ( // Customer has a downpayment or rentstart
                ((currentLoanapplication?.downPaymentSum || 0) > 0) ||
                ((currentLoanapplication?.startRentInclMva || 0) > 0) ||
                ((currentLoanapplication?.startRentExclMva || 0) > 0) ||
                ((currentLoanapplication?.startRent || 0) > 0)
            ) && // But no source is selected
            !currentLoanapplication?.downPaymentSource
        )
            problemsTemp.push("Kilde til egenkapital må velges"); // Show error
            
        if (currentLoanapplication?.objectyear == undefined || currentLoanapplication?.objectyear == "")
            problemsTemp.push("Objektet mangler årsmodell");

        if (currentLoanapplication?.calculationResult == undefined)
            problemsTemp.push("Kalkulering ikke kjørt");

        if (currentLoanapplication?.customerSequence == undefined || currentLoanapplication?.customerSequence == "")
            problemsTemp.push("Det må velges en kunde");

        if (currentLoanapplication?.customerPhone == undefined || currentLoanapplication?.customerPhone == "")
            problemsTemp.push("Kunden må ha telefonnummer");
        else {
            try {
                formatPhoneNumber(currentLoanapplication?.customerPhone);
            } catch (error) {
                problemsTemp.push("Kundens telefonnummer er ugyldig");
            }
        }

        if (currentLoanapplication?.coCustomerPhone) {
            try {
                formatPhoneNumber(currentLoanapplication?.coCustomerPhone);
            } catch (error) {
                problemsTemp.push("Medlånetakers telefonnummer er ugyldig");
            }
        }
        
        if (objectCategorySequence != 7 && currentLoanapplication?.ekundeSamtykke != true && currentLoanapplication?.requestingInsurance == true && currentLoanapplication?.insuranceCoverageTypeCode != undefined)
            problemsTemp.push("Forsikring: Kunden må ha gitt sitt samtykke til elektronisk kommunikasjon");

        if (currentLoanapplication?.requestingInsurance == true && objectCategorySequence == 7) {
            if (currentLoanapplication?.objectmakeCode == undefined || currentLoanapplication?.objectmakeCode == "")
                problemsTemp.push("Forsikring: Objektmerke må fylles ut");

            if(!currentLoanapplication?.oneMonthFreeInsurance)
                problemsTemp.push("Forsikring: Det må bekreftes at kunden ønsker 1 måned gratis forsikring");

            if (
                (currentLoanapplication.regnumber == undefined || currentLoanapplication.regnumber == "") && 
                (currentLoanapplication.chassisnumber == undefined || currentLoanapplication.chassisnumber == "")
            )
                problemsTemp.push("Forsikring: Objektet må ha registreringsnummer eller chassisnummer");
        }

        return problemsTemp;
    },
    checkProblemsInsurance: (currentInsuranceapplication: any, selectedAgreement: any) => {
        let problemsTemp:Array<any> = [];

        if (currentInsuranceapplication?.category == undefined || currentInsuranceapplication?.category == "")
            problemsTemp.push("Objektkategori må fylles ut");
        
        if (
            (currentInsuranceapplication?.regnumber == undefined ||
            currentInsuranceapplication?.regnumber == "") && 
            (currentInsuranceapplication?.chassisnumber == undefined ||
            currentInsuranceapplication?.chassisnumber == "")
        )
            problemsTemp.push("Objektet må ha registreringsnummer eller chassisnummer");

        if (currentInsuranceapplication?.customerSocialSecurityNumber == undefined || currentInsuranceapplication?.customerSocialSecurityNumber == "")
            problemsTemp.push("Kunde må angis med fødselsnummer");

        if (!currentInsuranceapplication?.customerPhone) {
            try {
                formatPhoneNumber(currentInsuranceapplication?.customerPhone);
            } catch (error) {
                problemsTemp.push("Kundens telefonnummer er ugyldig");
            }
        }

        if (currentInsuranceapplication?.category != "CAMPINGVOGN" && currentInsuranceapplication?.insuranceAnnualKilometers == undefined)
            problemsTemp.push("Ønsket kjørelengde må fylles ut");

        if (currentInsuranceapplication?.category != "CAMPINGVOGN" && currentInsuranceapplication?.insuranceBonus == undefined)
            problemsTemp.push("Nåværende bonus må fylles ut");

        if (currentInsuranceapplication?.category != "CAMPINGVOGN" && currentInsuranceapplication?.insuranceLowAge == undefined)
            problemsTemp.push("Yngre sjåfør må fylles ut");

        if (currentInsuranceapplication?.loanLeasing == undefined)
            problemsTemp.push("Finansiering må fylles ut");

        if (currentInsuranceapplication?.category == "CAMPINGVOGN") {
            if(currentInsuranceapplication?.purchaseSum == undefined || currentInsuranceapplication?.purchaseSum == "")
                problemsTemp.push("Kjøpesum må fylles ut");

            if(currentInsuranceapplication?.objectmakeCode == undefined)
                problemsTemp.push("Objektmerke må fylles ut");
            
            if (
                (currentInsuranceapplication?.regnumber == undefined ||
                currentInsuranceapplication?.regnumber == "") && 
                (currentInsuranceapplication?.chassisnumber == undefined ||
                currentInsuranceapplication?.chassisnumber == "")
            )
            problemsTemp.push("Objektet må ha registreringsnummer eller chassisnummer");

        }
        
        if (Number(currentInsuranceapplication?.insuranceBonus || 0) > 60) {
            if (currentInsuranceapplication?.insuranceBonusSource == undefined) problemsTemp.push("Bonuskilde må fylles ut");
            if (
                currentInsuranceapplication?.insuranceBonusSourceRegnr == undefined ||
                currentInsuranceapplication?.insuranceBonusSourceRegnr == ""
            ) problemsTemp.push("Bonuskilde registreringsnummer må fylles ut");
        }
        

        if (
            currentInsuranceapplication?.loanLeasing == "loan" || 
            currentInsuranceapplication?.loanLeasing == "leasing"
        ) {
            if (currentInsuranceapplication?.loanLeasingCompany == undefined) problemsTemp.push("Panthaver må fylles ut");
        }

        if (currentInsuranceapplication?.category != "CAMPINGVOGN" && currentInsuranceapplication?.ekundeSamtykke != true)
            problemsTemp.push("Kunden må ha gitt sitt samtykke til elektronisk kommunikasjon");

        return problemsTemp;
    },
    getTheme: () => {
        let themeStorage = localStorage.getItem("theme");
        if (themeStorage == "dark") {
            return "dark";
        } else {
            return "light";
        }
    },
    getUniqueFilters: (selectedDealer: any) => {
        let agreements = selectedDealer?.DealerGroupAgreementConnection || [];

        let result = {
            uniqueSegments: [] as any[],
            uniqueTypes: [] as any[],
            uniqueCategories: [] as any[],
            uniqueStates: [] as any[],
            uniqueVariants: [] as any[],
        };

        let uniqueSegments: any[] = [];
        agreements.forEach((i:any) => {
            if (!uniqueSegments.map(o => o.value).includes(i.customertype["name"])) {
                uniqueSegments.push({
                    name: i.customertype["name"],
                    value: i.customertype["name"]
                });
            }
        });
        result.uniqueSegments = uniqueSegments;
        
        let uniqueTypes: any[] = [];
        agreements.forEach((i:any) => {
            if (!uniqueTypes.map(o => o.value).includes(i.financingtype["name"])) {
                uniqueTypes.push({
                    name: i.financingtype["name"],
                    value: i.financingtype["name"]
                });
            }
        });
        result.uniqueTypes = uniqueTypes;
        
        let uniqueCategories: any[] = [];
        agreements.forEach((i:any) => {
            if (!uniqueCategories.map(o => o.value).includes(i.objecttype["name"] + "|" + i.objectvariant["name"])) {
                uniqueCategories.push({
                    name: i.objecttype["name"] + (i.objectvariant["name"] != "Vanlig" ? (" (" + i.objectvariant["name"] + ")") : ""),
                    value: i.objecttype["name"] + "|" + i.objectvariant["name"],
                    typeid: i.objecttype["id"],
                    variantid: i.objectvariant["id"],
                    typeSequence: i.objecttype["sequence"],
                });
            }
        });
        result.uniqueCategories = uniqueCategories;
        
        let uniqueStates: any[] = [];
        agreements.forEach((i:any) => {
            if (!uniqueStates.map(o => o.value).includes(i.objectstate["name"])) {
                uniqueStates.push({
                    name: i.objectstate["name"],
                    value: i.objectstate["name"]
                });
            }
        });
        result.uniqueStates = uniqueStates;
        
        let uniqueVariants: any[] = [];
        agreements.forEach((i:any) => {
            if (!uniqueVariants.map(o => o.value).includes(i.agreementvariant["name"])) {
                uniqueVariants.push({
                    name: i.agreementvariant["name"],
                    value: i.agreementvariant["name"]
                });
            }
        });
        result.uniqueVariants = uniqueVariants;

        return result;
    },
    getBudgetClassName: (expense: any) => {
        switch (expense.BudgetClassSequence) {
            case 5:
                return "Korreksjon mva. pr mnd";
            default:
                return expense.BudgetClassName;
        }
    },
    stringCategoryToIds(uniqueCategories: Array<any>, category: string) {
        let categoryName = category?.split("|")[0];
        let variantName = category?.split("|")[1];
        let categoryId = uniqueCategories.find((i: any) => i.value == categoryName + "|" + variantName)?.typeid;
        let variantId = uniqueCategories.find((i: any) => i.value == categoryName + "|" + variantName)?.variantid;
        return categoryId + "|" + variantId;
    },
    getInsurancePricing(calculationResult: any, selectedDeductible: any, selectedInsurance: any){
        const trafikkforsikringsavgift = calculationResult.find((idx: any) => 
            idx.dekningsTypeKode == "990"
        )?.prisEgenandelList.find((idx: any) => 
            idx.egenAndelsKode == selectedDeductible
        )?.prisPerMaaned ?? 0;
        const panthavergaranti = calculationResult.find((idx: any) => 
            idx.dekningsTypeKode == "992"
        )?.prisEgenandelList.find((idx: any) => 
            idx.egenAndelsKode == selectedDeductible
        )?.prisPerMaaned ?? 0;
        const forsikring = selectedInsurance.prisEgenandelList.find((i: any) => i.egenAndelsKode == selectedDeductible)?.prisPerMaaned ?? 0;
        const total = forsikring + trafikkforsikringsavgift + panthavergaranti;

        const price = {
            trafikkforsikringsavgift: general.formatPrice(trafikkforsikringsavgift, true, true),
            panthavergaranti: general.formatPrice(panthavergaranti, true, true),
            forsikring: general.formatPrice(forsikring, true, true),
            total: general.formatPrice(total, true, true)
        }

        return price;
    },
    getInitialInsurancePrice(calculationResult: any, currentPrisEgenandelList: any, annual: boolean = false) {
        let panthavergaranti = calculationResult?.find((x: any) => x.dekningsTypeKode == "992");
        let currentPanthavergaranti = panthavergaranti?.prisEgenandelList.find((idx: any) => idx.egenAndelsKode == currentPrisEgenandelList?.egenAndelsKode);
        let trafikkforsikringsavgift = calculationResult?.find((x: any) => x.dekningsTypeKode == "990");
        let currentTrafikkforsikringsavgift = trafikkforsikringsavgift?.prisEgenandelList.find((idx: any) => idx.egenAndelsKode == currentPrisEgenandelList?.egenAndelsKode);
       
        let priceField = annual ? "prisPerAar" : "prisPerMaaned";

        return (
            (currentPrisEgenandelList?.[priceField] ?? 0) + 
            (currentPanthavergaranti?.[priceField] ?? 0) + 
            (currentTrafikkforsikringsavgift?.[priceField] ?? 0)
        );
        
    },
    capitalize(value: string | undefined){
        return !value ? value : value.charAt(0).toUpperCase() + value.slice(1).toLowerCase();
    },
    isError(res: any){
        let isError = false;
        let errorMessage = "";

        if(res.data === undefined) isError = true;
        if(res.data.error != undefined) {
            isError = true;
            errorMessage = res.data.error.errorMessage;
        }
        if(res.status === 500 && typeof res.data === "string") {
            isError = true;
            errorMessage = res.data;
        }
        if(res.data.ErrorResponse != undefined) {
            isError = true;
            errorMessage = res.data.ErrorResponse.Message;
        }

        return {isError, errorMessage};
    },
    getProduktType(makeSequence: number | undefined, fremtindCode: string | undefined = undefined) {
        let type = "OTHER";

        if (config.region === 'ØST') {
            switch (makeSequence) {
                case 1:
                    return "BIL";
                case 6:
                    return "BOBIL";
                case 7:
                    return "CAMPINGVOGN";       
            }
        } else if (config.region === 'NORD') {
            switch (makeSequence) {
                case 37:
                    return "BIL";
                case 46:
                    return "BOBIL";
                case 27:
                    return "CAMPINGVOGN";         
            }        
        }

        if(type === "OTHER" && fremtindCode != undefined){
            switch(fremtindCode){
                case "BIL":
                    return "BIL";
                case "BOBIL":
                    return "BOBIL";
                case "CAMPINGVOGN":
                    return "CAMPINGVOGN";
            }
        }

        return type;
    }
};

export default general;