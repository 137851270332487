import React from "react";

import styles from './EKLoanPercentage.module.scss';
import ContentCard from "../../../../Shared/ContentCard/ContentCard";
import Progressbar from "../../../../Shared/Progressbar/Progressbar";
import general from "../../../../../functions/general";

function EKLoanPercentage(props: EKLoanPercentageProps) {
    const targetDefault = 25;
    const valueDefault = 20;

    return (
        <div className={styles.ekloanpercentage}>
            <ContentCard
                title="Total EK av finansieringsbeløp siste 6 mnd"
                open={true}
                disableClick={true}
                loading={props.data === undefined}
            >
                <Progressbar
                    data={{
                        markers: props.data?.target ? [
                            {
                                value: (props.data?.target ?? targetDefault),
                                label: (props.data?.target ?? targetDefault).toString() + "%",
                                labeloffset: -10
                            },
                        ] : [],
                        min: 0,
                        max: 50,
                        value: (props.data?.value ?? valueDefault),
                        label: props.data?.value ? general.formatPercentage((props.data?.value ?? valueDefault)) : "",
                        labeloffset: -10,
                        minLabel: "0%",
                        maxLabel: "50%"
                    }}
                ></Progressbar>
            </ContentCard>
        </div>
    );
}

interface EKLoanPercentageProps {
    data: {
        target: number,
        value: number
    } | undefined
}

export default EKLoanPercentage;