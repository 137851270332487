import React, { useEffect } from "react";

import { Grid, GridCol, GridRow } from "@sb1/ffe-grid-react";

import styles from './Settings.module.scss';
import ChangePasswordCard from "./ChangePasswordCard/ChangePasswordCard";
import GeneralSettingsCard from "./GeneralSettingsCard/GeneralSettingsCard";

function Settings() {
    const [dateText, setDateText] = React.useState<string>("");
    const [openCard, setOpenCard] = React.useState<string | null>("changePassword");

    function fetchDateFile() {
        fetch("builddate.txt")
        .then((res) => res.text())
        .then((content) => {
            setDateText(content);
        });
    }

    useEffect(() => {
        fetchDateFile();
    }, []);

    return (
        <div className={styles.settings}>
            <Grid className={styles.grid}>
                <GridRow>
                    <GridCol lg="6" md="12" sm="12">
                        <ChangePasswordCard
                            open={openCard == "changePassword"}
                            onClick={() => setOpenCard(openCard == "changePassword" ? null : "changePassword")}
                        />
                        <GeneralSettingsCard
                            open={openCard == "changePassword"}
                        />
                        {/* <p className={styles.latestVersion}>Sist oppdatert versjon: {dateText}</p> */}
                    </GridCol>
                </GridRow>
            </Grid>
        </div>
    );
}

export default Settings;