import React, { useEffect } from "react";

import styles from './GeneralSettingsCard.module.scss';
import ContentCard from "../../../Shared/ContentCard/ContentCard";
import { Checkbox, InputGroup } from "@sb1/ffe-form-react";

function GeneralSettingsCard(props: GeneralSettingsCardProps) {
    const [objectBeforeCalc, setObjectBeforeCalc] = React.useState<boolean>(localStorage.getItem("objectbeforecalc") == "true");

    useEffect(() => {
        localStorage.setItem("objectbeforecalc", objectBeforeCalc.toString());
    }, [objectBeforeCalc]);


    return (
        <ContentCard
            open={props.open}
            title="Generelle innstillinger"
            onClick={props.onClick}
            className={styles.generalsettingscard}
        >
            <InputGroup
                className={styles.checkboxinputgroup}
            >
                <div className={styles.checkboxcontainer}>
                    <Checkbox
                        name="objectbeforecalc"
                        inline={false}
                        checked={objectBeforeCalc}
                        onChange={e => {
                            setObjectBeforeCalc(e.target.checked);
                        }}
                    >
                        Vis objekt før kalkulator
                    </Checkbox>
                </div>
            </InputGroup>
        </ContentCard>
    );
}

interface GeneralSettingsCardProps {
    open: boolean;
    onClick?: () => void;
}

export default GeneralSettingsCard;