const objectFunctions = {
    getModelYear: (year: number, vin: string, definite: boolean = false) => {
        if (definite) return year;
        if (vin.length != 17) return year;

        const yearCodes = [ 'A', 'B', 'C', 'D', 'E', 'F', 'G', 'H', 'J', 'K', 'L', 'M', 'N', 'P', 'R', 'S', 'T', 'V', 'W', 'X', 'Y', '1', '2', '3', '4', '5', '6', '7', '8', '9' ];

        let yearcode = vin.charAt(9);
        if (yearcode == "0") return year;

        const yearOffset = yearCodes.indexOf(yearcode);
        
        const possibleYears = [
            2070 + yearOffset,
            2040 + yearOffset,
            2010 + yearOffset,
            1980 + yearOffset,
            1950 + yearOffset,
        ];

        let foundYear = possibleYears.filter((y) => y >= Number(year)).sort((a, b) => a - b)[0];

        return foundYear;
    }
};

export default objectFunctions;