import React from "react";

import styles from './AvgDuration.module.scss';
import ContentCard from "../../../../Shared/ContentCard/ContentCard";
import Progressbar from "../../../../Shared/Progressbar/Progressbar";

function AvgDuration(props: AvgDurationProps) {
    const targetDefault = 0;
    const valueDefault = 0;

    return (
        <div className={styles.avgduration}>
            <ContentCard
                title="Snitt løpetid siste 12 måneder"
                open={true}
                disableClick={true}
                loading={props.data === undefined}
            >
                <Progressbar
                    data={{
                        markers: props.data?.target ? [
                            {
                                value: (props.data?.target ?? targetDefault),
                                label: "Mål: " + (props.data?.target ?? targetDefault).toString() + " terminer",
                                labeloffset: -50
                            },
                        ] : [],
                        min: 0,
                        max: ((props?.data?.value ?? 0) > 103 ? Number(((props?.data?.value ?? 0) * 1.1).toFixed(0)) : 103),
                        value: (props.data?.value ?? valueDefault),
                        label: props.data?.value ? ((props.data?.value ?? valueDefault).toString() + " terminer") : "",
                        labeloffset: -35,
                        minLabel: "0",
                        maxLabel: ((props?.data?.value ?? 0) > 103 ? ((props?.data?.value ?? 0) * 1.1).toFixed(0).toString() : "103")
                    }}
                ></Progressbar>
            </ContentCard>
        </div>
    );
}

interface AvgDurationProps {
    data: {
        target: number,
        value: number
    } | undefined
}

export default AvgDuration;