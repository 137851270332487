const urlParamsLoader = {
    load: (currentLoanapplication: any) => {
        let urlParams = new URLSearchParams(window.location.search);

        let results = {
            currentLoanapplication,
            urlParams: {} as any
        };

        let segment = urlParams.get("sb1-kundetype");
        if (segment != undefined && currentLoanapplication.segment == undefined) {
            switch (segment) {
                case "PM": currentLoanapplication.setSegment("Privat"); break;
                case "BM": currentLoanapplication.setSegment("Bedrift"); break;
                default: break;
            }
            results.urlParams["segment"] = segment;
        }

        let type = urlParams.get("sb1-typelaan");
        if (type != undefined && currentLoanapplication.type == undefined) {
            switch (type) {
                case "Laan": currentLoanapplication.setType("Lån"); break;
                case "Leasing": currentLoanapplication.setType("Leasing"); break;
                default: break;
            }
            results.urlParams["type"] = type;
        }

        let category = urlParams.get("sb1-produkt");
        if (category != undefined && currentLoanapplication.category == undefined) {
            switch (category) {
                default: currentLoanapplication.setCategory(category); break;
            }
            results.urlParams["category"] = category;
        }

        let caseObjectNew = urlParams.get("sb1-tilstand");
        if (caseObjectNew != undefined && currentLoanapplication.caseObjectNew == undefined) {
            switch (caseObjectNew) {
                case "ny": currentLoanapplication.setCaseObjectNew(true); break;
                case "brukt": currentLoanapplication.setCaseObjectNew(false); break;
                default: break;
            }
            results.urlParams["caseObjectNew"] = caseObjectNew;
        }

        let purchaseSum = urlParams.get("sb1-pris");
        if (purchaseSum != undefined && currentLoanapplication.purchaseSum == undefined) {
            currentLoanapplication.setPurchaseSum(Number(purchaseSum));
            results.urlParams["purchaseSum"] = purchaseSum;
        }

        let downPaymentSum = urlParams.get("sb1-egenkapital");
        if (downPaymentSum != undefined && currentLoanapplication.downPaymentSum == undefined) {
            currentLoanapplication.setDownPaymentSum(Number(downPaymentSum));
            results.urlParams["downPaymentSum"] = downPaymentSum;
        }

        let contractlengthmonths = urlParams.get("sb1-nedbetalingstid");
        if (contractlengthmonths != undefined && currentLoanapplication.contractlengthmonths == undefined) {
            currentLoanapplication.setContractlengthmonths(Math.round((Number(contractlengthmonths) * 12)));
            results.urlParams["contractlengthmonths"] = contractlengthmonths;
        }

        let regnumber = urlParams.get("sb1-regnr");
        if (regnumber != undefined && regnumber != "" && currentLoanapplication.regnumber == undefined) {
            currentLoanapplication.setRegnumber(regnumber);
            currentLoanapplication.setExactCar(true);
            currentLoanapplication.setRegnrknown(true);
            results.urlParams["regnumber"] = regnumber;
        }

        let objectyear = urlParams.get("sb1-aarsmodell");
        if ((regnumber == undefined || regnumber == "") && objectyear != undefined && objectyear != "" && currentLoanapplication.objectyear == undefined) {
            currentLoanapplication.setObjectyear(Number(objectyear));
            currentLoanapplication.setExactCar(true);
            currentLoanapplication.setRegnrknown(false);
            results.urlParams["objectyear"] = objectyear;
        }

        let objectmake = urlParams.get("sb1-merke");
        if ((regnumber == undefined || regnumber == "") && objectmake != undefined && objectmake != "" && currentLoanapplication.objectmake == undefined) {
            currentLoanapplication.setObjectmake(objectmake);
            currentLoanapplication.setExactCar(true);
            currentLoanapplication.setRegnrknown(false);
            results.urlParams["objectmake"] = objectmake;
        }

        let objectmodel = urlParams.get("sb1-modell");
        if ((regnumber == undefined || regnumber == "") && objectmodel != undefined && objectmodel != "" && currentLoanapplication.objectmodel == undefined) {
            currentLoanapplication.setObjectmodel(objectmodel);
            currentLoanapplication.setExactCar(true);
            currentLoanapplication.setRegnrknown(false);
            results.urlParams["objectmodel"] = objectmodel;
        }

        let dueday = urlParams.get("sb1-forfallsdag");
        if (dueday != undefined && dueday != "" && currentLoanapplication.dueday == undefined) {
            currentLoanapplication.setDueday(Number(dueday));
            results.urlParams["dueday"] = dueday;
        }

        let comment = urlParams.get("sb1-kommentar");
        if (comment != undefined && comment != "" && currentLoanapplication.comment == undefined) {
            currentLoanapplication.setComment(comment);
            results.urlParams["comment"] = comment;
        }

        let customerPhone = urlParams.get("sb1-mobil");
        if (customerPhone != undefined && customerPhone != "" && currentLoanapplication.customerPhone == undefined) {
            currentLoanapplication.setCustomerPhone(customerPhone);
            results.urlParams["customerPhone"] = customerPhone;
        }

        let customerEmail = urlParams.get("sb1-epost");
        if (customerEmail != undefined && customerEmail != "" && currentLoanapplication.customerEmail == undefined) {
            currentLoanapplication.setCustomerEmail(customerEmail);
            results.urlParams["customerEmail"] = customerEmail;
        }

        results.currentLoanapplication = currentLoanapplication;

        return results;
    },
};

export default urlParamsLoader;