import React from "react";
import Spinner from "@sb1/ffe-spinner-react";

import styles from "./FullscreenLoading.module.scss";

function FullscreenLoading(props: any) {
    return (
        <div className={styles.fullscreenloading + " " + (props.show ? "" : "visibilityhidden")} style={props.style}>
            <Spinner large={true} />
            <p>Laster inn ..</p>
        </div> 
    );
}

export default FullscreenLoading;