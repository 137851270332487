import React, { useEffect, useState } from "react";

import { Input, InputGroup, RadioButtonInputGroup, RadioSwitch } from "@sb1/ffe-form-react";
import ContentCard from "../../../../Shared/ContentCard/ContentCard";
import InputWithUsability from "../../../../Shared/InputWithUsability/InputWithUsability";
import Select from 'react-select';
import { toast } from 'react-toastify';
import { Insuranceapplication as InsuranceapplicationModel} from "../../../../../model/Insuranceapplication";
import { useAuth } from "../../../../../Auth";
import { useAxios } from "../../../../../AxiosHandler";
import objectFunctions from "../../../../../functions/objectFunctions";
import general from "../../../../../functions/general";
import { formatRegistrationNumber } from "../../../../../functions/dataSanitation";

function InsuranceObjectCard(props: InsuranceObjectCardProps) {
    const auth = useAuth();
    const axiosHandler = useAxios();
    const axios = axiosHandler.axios;

    const [objectMakes, setObjectMakes] = useState<any>([]);
    const [objectModels, setObjectModels] = useState<any>([]);
    const [objectVariants, setObjectVariants] = useState<any>([]);

    const [makesLoading, setMakesLoading] = useState<boolean>(false);
    const [modelsLoading, setModelsLoading] = useState<boolean>(false);
    const [variantsLoading, setVariantsLoading] = useState<boolean>(false);

    function fetchObjectMakesData(){
        setMakesLoading(true);
        setObjectMakes([]);
        setObjectModels([]);
        setObjectVariants([]);
        props.setCurrentInsuranceapplication({
            ...props.currentInsuranceapplication || new InsuranceapplicationModel(),
            objectyear: undefined,
            objectmake: undefined,
            objectmakeName: undefined,
            objectmodel: undefined,
            objectmodelName: undefined,
            objecttype: undefined,
            objecttypeName: undefined,
        });

        switch (auth.currentInsuranceapplication?.category) {
            //BOBIL
            case "BOBIL": {
                fetchCaravanObjectMakes();
                break;
            }
            //CAMPINGVOGN
            case "CAMPINGVOGN": {
                fetchCampingTrailerObjectMakes();
                break;
            }
            //BIL
            default: {
                fetchObjectMakes();
                break;
            }
        }
    }

    function fetchObjectMakes() {
        setMakesLoading(true);
        setObjectMakes([]);
        axios.single({
            url: "/api/fremtind/kodeverk/bilmerke",
            method: "GET",
            headers: {
                "Content-Type": "application/json",
                "UserAuthorization": "Bearer " + (JSON.parse(localStorage.getItem("logindata") ?? "")["JWT"])
            }
        }).then((res: any) => {
            if (res.data != undefined) {
                setObjectMakes(res.data.map((i:any) => {
                    return {
                        value: i.merkenr,
                        label: i.merkenavn
                    }
                }));
            }
        }).catch((err: any) => {
            console.log(err);
            toast.error("Innlasting av mulige merker feilet! Vennligst prøv igjen");
        }).finally(() => {
            setMakesLoading(false);
        });
    }

    function fetchCaravanObjectMakes(){
        setMakesLoading(true);
        setObjectMakes([]);
        setObjectModels([]);
        axios.single({
            url: "/api/fremtind/kodeverk/bobiler",
            method: "GET",
            headers: {
                "Content-Type": "application/json",
                "UserAuthorization": "Bearer " + (JSON.parse(localStorage.getItem("logindata") ?? "")["JWT"])
            }
        }).then((res: any) => {
            let tempMakes = [];
            if (res.data != undefined) {
                // Reduce to only unique values based on .bilmerke
                let unique = res.data.filter((v: any, i: any, a: any) =>
                    a.findIndex((t: any) => (t.bilmerke === v.bilmerke)) === i
                );
                tempMakes.push(...unique.map((i:any) => {
                    const firstLetter = i.bilmerke.charAt(0);
                    const firstLetterCap = firstLetter.toUpperCase();
                    const remainingLetters = i.bilmerke.slice(1).toLowerCase();
                    const capitalizedWord = firstLetterCap + remainingLetters;
                    return {
                        value: i.bilmerkekode,
                        label: capitalizedWord,
                        variants: res.data.filter((v: any) => v.bilmerke == i.bilmerke)
                    }
                }));
            }
            setObjectMakes(tempMakes);
        }).catch((err: any) => {
            console.log(err);
            toast.error("Innlasting av mulige merker feilet! Vennligst prøv igjen");
        }).finally(() => {
            setMakesLoading(false);
        });
    }

    function fetchCampingTrailerObjectMakes(){
        setMakesLoading(true);
        setObjectMakes([]);
        setObjectModels([]);
        axios.single({
            url: "/api/fremtind/kodeverk/campingvogner",
            method: "GET",
            headers: {
                "Content-Type": "application/json",
                "UserAuthorization": "Bearer " + (JSON.parse(localStorage.getItem("logindata") ?? "")["JWT"])
            }
        }).then((res: any) => {
            let tempMakes = [];
            if (res.data != undefined) {
                tempMakes.push(...res.data.map((i:any) => {
                    return {
                        value: i.merkekode.trim(),
                        label: (i.merke.charAt(0) + i.merke.slice(1).toLowerCase())
                    }
                }));
            }
            setObjectMakes(tempMakes);
        }).catch((err: any) => {
            console.log(err);
            toast.error("Innlasting av mulige merker feilet! Vennligst prøv igjen");
        }).finally(() => {
            setMakesLoading(false);
        });
    }
    
    function fetchObjectModels(year: number, make: string) {
        setModelsLoading(true);
        setObjectModels([]);
        axios.single({
            url: "/api/fremtind/kodeverk/bilmodell/" + year + "/" + make,
            method: "GET",
            headers: {
                "Content-Type": "application/json",
                "UserAuthorization": "Bearer " + (JSON.parse(localStorage.getItem("logindata") ?? "")["JWT"])
            }
        }).then((res: any) => {
            if (res.data != undefined) {
                setObjectModels(res.data.map((i:any) => {
                    return {
                        value: i.modellnr,
                        label: i.modellnavn
                    }
                }));
            }
        }).catch((err: any) => {
            console.log(err);
            toast.error("Innlasting av mulige modeller feilet! Vennligst prøv igjen");
        }).finally(() => {
            setModelsLoading(false);
        });
    }
    
    function fetchObjectVariants(year: number, make: string, model: string) {
        setVariantsLoading(true);
        setObjectVariants([]);
        axios.single({
            url: "/api/fremtind/kodeverk/bilvariant/" + year + "/" + make + "/" + model,
            method: "GET",
            headers: {
                "Content-Type": "application/json",
                "UserAuthorization": "Bearer " + (JSON.parse(localStorage.getItem("logindata") ?? "")["JWT"])
            }
        }).then((res: any) => {
            if (res.data != undefined) {
                setObjectVariants(res.data.map((i:any) => {
                    return {
                        value: i.variantnr,
                        label: i.variantnavn
                    }
                }));
            }
        }).catch((err: any) => {
            console.log(err);
            toast.error("Innlasting av mulige varianter feilet! Vennligst prøv igjen");
        }).finally(() => {
            setVariantsLoading(false);
        });
    }

    async function searchRegistrationNumber(regnum: string) {
        return new Promise((resolve, reject) => {
            toast("Søker etter objekt ..", {
                autoClose: false
            });
            axios.single({
                url: "/api/caseobjects/" + formatRegistrationNumber(regnum, true),
                method: "GET",
                headers: {
                    "Content-Type": "application/json",
                    "UserAuthorization": "Bearer " + (JSON.parse(localStorage.getItem("logindata") ?? "")["JWT"])
                }
            }).then((res: any) => {
                let hasForstegangRegistrertDato = res.data.kjoretoydataListe[0].godkjenning?.forstegangsGodkjenning?.forstegangRegistrertDato != undefined;
                let year =
                    res.data.kjoretoydataListe[0].godkjenning?.forstegangsGodkjenning?.forstegangRegistrertDato?.split("-")[0] || 
                    res.data.kjoretoydataListe[0].godkjenning?.tekniskGodkjenning?.kjoretoyklassifisering?.nasjonalGodkjenning?.nasjonaltGodkjenningsAr;

                let vin = res.data.kjoretoydataListe[0].kjoretoyId.understellsnummer;

                let modelYear = objectFunctions.getModelYear(year, vin, hasForstegangRegistrertDato);

                let objectyear = modelYear;
                let objectmake = res.data.kjoretoydataListe[0].godkjenning.tekniskGodkjenning.tekniskeData.generelt.merke[0].merke;
                let objectmodel = res.data.kjoretoydataListe[0].godkjenning.tekniskGodkjenning.tekniskeData.generelt.handelsbetegnelse[0];

                props.setFoundVehicle(objectmake + " " + objectmodel + " " + objectyear);
                resolve(res.data);
                toast.dismiss();
            }).catch((err: any) => {
                props.setFoundVehicle("Fant ikke objekt")
                auth.setCurrentInsuranceapplication({
                    ...auth.currentInsuranceapplication || new InsuranceapplicationModel(),
                    objectyear: undefined,
                    objectmake: undefined,
                    objectmodel: undefined,
                    objecttype: undefined,
                });
                toast.dismiss();
                console.log(err);
                toast.error("Søk på registreringsnummeret feilet! Vennligst prøv igjen");
                reject(err);
            });
        });
    }

    useEffect(() => {
        fetchObjectMakesData();
    }, [auth.currentInsuranceapplication?.category]);

    useEffect(() => {
        if (
            props.currentInsuranceapplication?.objectmake != undefined && props.currentInsuranceapplication?.objectmake != "" &&
            props.currentInsuranceapplication?.objectyear != undefined
        ) {
            fetchObjectModels(
                props.currentInsuranceapplication?.objectyear,
                props.currentInsuranceapplication?.objectmake
            );
            props.setCurrentInsuranceapplication({
                ...props.currentInsuranceapplication || new InsuranceapplicationModel(),
            });
        }
    }, [
        props.currentInsuranceapplication?.objectmake,
        props.currentInsuranceapplication?.objectyear
    ]);

    useEffect(() => {
        if (
            props.currentInsuranceapplication?.objectmake != undefined && props.currentInsuranceapplication?.objectmake != "" && 
            props.currentInsuranceapplication?.objectyear != undefined &&
            props.currentInsuranceapplication?.objectmodel != undefined && props.currentInsuranceapplication?.objectmodel != ""
        ) {
            fetchObjectVariants(
                props.currentInsuranceapplication?.objectyear,
                props.currentInsuranceapplication?.objectmake,
                props.currentInsuranceapplication?.objectmodel
            );
            props.setCurrentInsuranceapplication({
                ...props.currentInsuranceapplication || new InsuranceapplicationModel(),
            });
        }
    }, [
        props.currentInsuranceapplication?.objectmake,
        props.currentInsuranceapplication?.objectyear,
        props.currentInsuranceapplication?.objectmodel
    ]);

    return(
        <ContentCard disabled={props.disabled} open={props.open} title="Opplysninger om objekt">
            <RadioButtonInputGroup
                label="Vet du registreringsnummeret på objektet?"
                //tooltip=""
                name="regnrknown"
                onChange={e => {
                    props.setCurrentInsuranceapplication({
                        ...props.currentInsuranceapplication || new InsuranceapplicationModel(),
                        regnrknown: e.target.value == "true"
                    });
                }}
                selectedValue={props.currentInsuranceapplication?.regnrknown != undefined ? props.currentInsuranceapplication?.regnrknown.toString() : ""}
            >
                {inputProps => (
                    <RadioSwitch
                        leftLabel="Ja"
                        leftValue="true"
                        rightLabel="Nei"
                        rightValue="false"
                        {...inputProps}
                    />
                )}
            </RadioButtonInputGroup>
            &nbsp;
            { props.currentInsuranceapplication?.regnrknown != undefined && props.currentInsuranceapplication?.regnrknown ?
                <>
                    <InputGroup
                        label="Registreringsnummer"
                        tooltip="Registreringsnummer på objektet som skal finansieres. Eks: AB12345"
                        //fieldMessage={showErrors ? 'Ugyldig telefonnummer' : null}
                    >
                        {inputProps => (
                            <Input
                                type="text"
                                name="regnumber"
                                value={props.currentInsuranceapplication?.regnumber || ""}
                                onChange={e => {
                                    props.setCurrentInsuranceapplication({
                                        ...props.currentInsuranceapplication || new InsuranceapplicationModel(),
                                        regnumber: e.target.value
                                    });
                                }}
                                onBlur={(e) => {
                                    if (e.target.value.length > 1) {
                                        
                                        searchRegistrationNumber(e.target.value).then((res: any) => {
                                            let hasForstegangRegistrertDato = res.kjoretoydataListe[0].godkjenning?.forstegangsGodkjenning?.forstegangRegistrertDato != undefined;
                                            
                                            let year =
                                                res.kjoretoydataListe[0].godkjenning?.forstegangsGodkjenning?.forstegangRegistrertDato?.split("-")[0] || 
                                                res.kjoretoydataListe[0].godkjenning?.tekniskGodkjenning?.kjoretoyklassifisering?.nasjonalGodkjenning?.nasjonaltGodkjenningsAr;
                                            
                                            let vin = res.kjoretoydataListe[0].kjoretoyId.understellsnummer;

                                            let modelYear = objectFunctions.getModelYear(year, vin, hasForstegangRegistrertDato);

                                            let tempObjectmakeCode = objectMakes?.find((make: any) => 
                                                make.label == general.capitalize(res.kjoretoydataListe[0].godkjenning.tekniskGodkjenning.tekniskeData.generelt.merke[0].merke)
                                            )?.value ?? undefined;

                                            props.setCurrentInsuranceapplication({
                                                ...props.currentInsuranceapplication || new InsuranceapplicationModel(),
                                                objectyear: modelYear,
                                                objectmake: res.kjoretoydataListe[0].godkjenning.tekniskGodkjenning.tekniskeData.generelt.merke[0].merke,
                                                objectmodel: res.kjoretoydataListe[0].godkjenning.tekniskGodkjenning.tekniskeData.generelt.handelsbetegnelse[0],
                                                objecttype: res.kjoretoydataListe[0].godkjenning.tekniskGodkjenning.tekniskeData.generelt.typebetegnelse,
                                                objectmakeCode: tempObjectmakeCode,
                                                chassisnumber: vin,
                                                regnumber: res.kjoretoydataListe[0].kjoretoyId?.kjennemerke
                                            });
                                        }).catch((err: any) => {
                                            console.log(err);
                                        });
                                    }
                                    else {
                                        props.setFoundVehicle(""); 
                                        props.setCurrentInsuranceapplication({
                                            ...props.currentInsuranceapplication || new InsuranceapplicationModel(),
                                            objectyear: undefined,
                                            objectmake: undefined,
                                            objectmodel: undefined,
                                            objecttype: undefined,
                                            chassisnumber: undefined
                                        });
                                    }
                                }}
                                {...inputProps}
                            />
                        )}
                    </InputGroup>
                    &nbsp;
                    {props.foundVehicle != "" ?
                        <>
                            <p>{props.foundVehicle}</p>
                        </>
                    : null}
                </>
            : null }
            { auth.currentInsuranceapplication?.regnrknown != undefined && !props.currentInsuranceapplication?.regnrknown ?
                <>
                    <InputGroup
                        label="Årsmodell"
                        tooltip="Årsmodell på finansieringsobjektet. Eks: 2019"
                        fieldMessage={
                                (props.currentInsuranceapplication?.objectyear != undefined && props.currentInsuranceapplication?.objectyear < 1800) || 
                                (props.currentInsuranceapplication?.objectyear != undefined && props.currentInsuranceapplication?.objectyear > new Date().getFullYear() + 5)
                            ?
                                "Ugyldig årsmodell"
                            : null
                        }
                    >
                        {inputProps => (
                            <Input
                                type="number"
                                name="objectyear"
                                value={props.currentInsuranceapplication?.objectyear || ""}
                                min={1800}
                                max={new Date().getFullYear() + 5}
                                onChange={e => {
                                    props.setCurrentInsuranceapplication({
                                        ...props.currentInsuranceapplication || new InsuranceapplicationModel(),
                                        objectyear: e.target.value as any,
                                    });
                                }}
                                {...inputProps}
                            />
                        )}
                    </InputGroup>
                    <InputGroup
                        label="Merke"
                        tooltip="Fritekst søk etter merke på finansieringsobjektet. Krever utfylling av årsmodell først."
                        //fieldMessage={showErrors ? 'Ugyldig telefonnummer' : null}
                    >
                        <Select
                            noOptionsMessage={() => "Fant ingen merker for valgt år"}
                            placeholder="Søk ..."
                            loadingMessage={() => "Laster inn merker ..."}
                            isLoading={makesLoading}
                            isDisabled={
                                props.currentInsuranceapplication?.objectyear == undefined ||
                                props.currentInsuranceapplication?.objectyear < 1900 ||
                                props.currentInsuranceapplication?.objectyear > (new Date().getFullYear() + 1)
                            }
                            className="basic-singleselect"
                            classNamePrefix="select"
                            value={
                                [
                                    {
                                        value: props.currentInsuranceapplication?.objectmake,
                                        label: 
                                            objectMakes.filter(
                                                (i: any) => i.value != "other"
                                            ).find(
                                                (i: any) => i.value == props.currentInsuranceapplication?.objectmake
                                            )?.label ?? props.currentInsuranceapplication?.objectmakeName,
                                    }
                                ]
                            }
                            defaultValue={
                                [
                                    {
                                        value: props.currentInsuranceapplication?.objectmake,
                                        label: 
                                            objectMakes.filter(
                                                (i: any) => i.value != "other"
                                            ).find(
                                                (i: any) => i.value == props.currentInsuranceapplication?.objectmake
                                            )?.label ?? props.currentInsuranceapplication?.objectmakeName,
                                    }
                                ]
                            }
                            isClearable={true}
                            isSearchable={true}
                            options={objectMakes}
                            onInputChange={(e) => {
                                setObjectMakes([
                                    ...objectMakes.filter((i: any) => i.value != "other"),
                                    !(objectMakes.filter((i: any) => i.value != "other").some((i: any) => i.label == e)) ? 
                                        {
                                            value: "other",
                                            label: e,
                                        }
                                    : null
                                ].filter((i: any) => i != null));
                                if (objectMakes.filter((i: any) => i.value != "other").some((i: any) => i.label == e)) {
                                    props.setCurrentInsuranceapplication({
                                        ...props.currentInsuranceapplication || new InsuranceapplicationModel(),
                                        objectmake: objectMakes.filter((i: any) => i.value != "other").find((i: any) => i.label == e)?.value,
                                        objectmakeCode: objectMakes.filter((i: any) => i.value != "other").find((i: any) => i.label == e)?.value,
                                        objectmakeName: objectMakes.filter((i: any) => i.value != "other").find((i: any) => i.label == e)?.label,
                                        objectmodel: undefined,
                                        objectmodelName: undefined,
                                        objecttype: undefined,
                                        objecttypeName: undefined,
                                    });
                                }
                            }}
                            onChange={(e) => {
                                props.setCurrentInsuranceapplication({
                                    ...props.currentInsuranceapplication || new InsuranceapplicationModel(),
                                    objectmake: (e as any)?.value,
                                    objectmakeCode: (e as any)?.value,
                                    objectmakeName: (e as any)?.label,
                                    objectmodel: undefined,
                                    objectmodelName: undefined,
                                    objecttype: undefined,
                                    objecttypeName: undefined,
                                });
                            }}
                        />
                    </InputGroup>
                    <InputGroup
                        label="Modell"
                        tooltip="Fritekst søk etter modell på finansieringsobjektet. Krever utfylling av merke først."
                        //fieldMessage={showErrors ? 'Ugyldig telefonnummer' : null}
                    >
                        <Select
                            noOptionsMessage={() => "Fant ingen modeller for dette merket"}
                            placeholder="Søk ..."
                            loadingMessage={() => "Laster inn modeller ..."}
                            isLoading={modelsLoading}
                            className="basic-singleselect"
                            classNamePrefix="select"
                            value={
                                [
                                    {
                                        value: props.currentInsuranceapplication?.objectmodel,
                                        label: objectModels.filter(
                                            (i: any) => i.value != "other"
                                        ).find(
                                            (i: any) => i.value == props.currentInsuranceapplication?.objectmodel
                                        )?.label || props.currentInsuranceapplication?.objectmodelName,
                                    }
                                ]
                            }
                            defaultValue={
                                [
                                    {
                                        value: props.currentInsuranceapplication?.objectmodel,
                                        label: objectModels.filter(
                                            (i: any) => i.value != "other"
                                        ).find(
                                            (i: any) => i.value == props.currentInsuranceapplication?.objectmodel
                                        )?.label || props.currentInsuranceapplication?.objectmodelName,
                                    }
                                ]
                            }
                            isClearable={true}
                            isSearchable={true}
                            options={objectModels}
                            onInputChange={(e) => {
                                setObjectModels([
                                    ...objectModels.filter((i: any) => i.value != "other"),
                                    !(objectModels.filter((i: any) => i.value != "other").some((i: any) => i.label == e)) ? 
                                        {
                                            value: "other",
                                            label: e,
                                        }
                                    : null
                                ].filter((i: any) => i != null));
                                if (objectModels.filter((i: any) => i.value != "other").some((i: any) => i.label == e)) {
                                    props.setCurrentInsuranceapplication({
                                        ...props.currentInsuranceapplication || new InsuranceapplicationModel(),
                                        objectmodel: objectModels.filter((i: any) => i.value != "other").find((i: any) => i.label == e)?.value,
                                        objectmodelName: objectModels.filter((i: any) => i.value != "other").find((i: any) => i.label == e)?.label,
                                        objecttype: undefined,
                                        objecttypeName: undefined,
                                    });
                                }
                            }}
                            onChange={(e) => {
                                props.setCurrentInsuranceapplication({
                                    ...props.currentInsuranceapplication || new InsuranceapplicationModel(),
                                    objectmodel: (e as any)?.value,
                                    objectmodelName: (e as any)?.label,
                                    objecttype: undefined,
                                    objecttypeName: undefined,
                                });
                            }}
                        />
                    </InputGroup>
                    {auth.currentInsuranceapplication?.category == "CAMPINGVOGN" ?
                        null
                    : auth.currentInsuranceapplication?.category == "BOBIL" ?
                        <InputGroup
                            label="Verdi"
                            tooltip="Valg av verdien på finansieringsobjektet. Krever utfylling av merke først."
                        >
                            <Select
                                noOptionsMessage={() => "Fant ingen verdier for valgt merke"}
                                placeholder="Søk ..."
                                loadingMessage={() => "Laster inn verdier ..."}
                                isLoading={variantsLoading}
                                className="basic-singleselect"
                                classNamePrefix="select"
                                value={[
                                    {
                                        value: props.currentInsuranceapplication?.objectmake,
                                        label: objectMakes.find((i: any) => 
                                            i?.label === props.currentInsuranceapplication?.objectmakeName
                                        )?.variants?.find((i: any) => 
                                            i?.bilmerkekode === props.currentInsuranceapplication?.objectmake
                                        )?.beskrivelse
                                    }
                                ]}
                                defaultValue={[
                                    {
                                        value: props.currentInsuranceapplication?.objectmake,
                                        label: objectMakes.find((i: any) => 
                                            i?.label === props.currentInsuranceapplication?.objectmakeName
                                        )?.variants?.find((i: any) => 
                                            i?.bilmerkekode === props.currentInsuranceapplication?.objectmake
                                        )?.beskrivelse
                                    }
                                ]}
                                isClearable={true}
                                isSearchable={false}
                                options={objectMakes.filter(
                                    (i: any) => i.value != "other"
                                ).find(
                                    (i: any) => i.label == props.currentInsuranceapplication?.objectmakeName
                                )?.variants?.map((i: any) => {
                                    return {
                                        value: i.bilmerkekode,
                                        label: i.beskrivelse
                                    }
                                })}
                                onChange={(e) => {
                                    props.setCurrentInsuranceapplication({
                                        ...props.currentInsuranceapplication || new InsuranceapplicationModel(),
                                        objectmake: (e as any)?.value,
                                        objectmakeCode: (e as any)?.value,
                                    });
                                }}
                            />
                        </InputGroup>
                    : 
                        <InputGroup
                            label="Variant"
                            tooltip="Fritekst søk etter variant på finansieringsobjektet. Krever utfylling av modell først."
                            //fieldMessage={showErrors ? 'Ugyldig telefonnummer' : null}
                        >
                            <Select
                                noOptionsMessage={() => "Fant ingen varianter for valgt modell"}
                                placeholder="Søk ..."
                                loadingMessage={() => "Laster inn varianter ..."}
                                isLoading={variantsLoading}
                                className="basic-singleselect"
                                classNamePrefix="select"
                                value={
                                    [
                                        {
                                            value: props.currentInsuranceapplication?.objecttype,
                                            label: objectVariants.filter(
                                                (i: any) => i.value != "other"
                                            ).find(
                                                (i: any) => i.value == props.currentInsuranceapplication?.objecttype
                                            )?.label || props.currentInsuranceapplication?.objecttypeName,
                                        }
                                    ]
                                }
                                defaultValue={
                                    [
                                        {
                                            value: props.currentInsuranceapplication?.objecttype,
                                            label: objectVariants.filter(
                                                (i: any) => i.value != "other"
                                            ).find(
                                                (i: any) => i.value == props.currentInsuranceapplication?.objecttype
                                            )?.label || props.currentInsuranceapplication?.objecttypeName,
                                        }
                                    ]
                                }
                                isClearable={true}
                                isSearchable={true}
                                options={objectVariants}
                                onInputChange={(e) => {
                                    setObjectVariants([
                                        ...objectVariants.filter((i: any) => i.value != "other"),
                                        !(objectVariants.filter((i: any) => i.value != "other").some((i: any) => i.label == e)) ? 
                                            {
                                                value: "other",
                                                label: e,
                                            }
                                        : null
                                    ].filter((i: any) => i != null));
                                    if (objectVariants.filter((i: any) => i.value != "other").some((i: any) => i.label == e)) {
                                        props.setCurrentInsuranceapplication({
                                            ...props.currentInsuranceapplication || new InsuranceapplicationModel(),
                                            objecttype: objectVariants.filter((i: any) => i.value != "other").find((i: any) => i.label == e)?.value,
                                            objecttypeName: objectVariants.filter((i: any) => i.value != "other").find((i: any) => i.label == e)?.label,
                                        });
                                    }
                                }}
                                onChange={(e) => {
                                    props.setCurrentInsuranceapplication({
                                        ...props.currentInsuranceapplication || new InsuranceapplicationModel(),
                                        objecttype: (e as any)?.value,
                                        objecttypeName: (e as any)?.label,
                                    });
                                }}
                            />
                        </InputGroup>
                    }
                    {/* { auth.currentInsuranceapplication?.category == "CAMPINGVOGN" ? */}
                        <InputGroup
                            label="Chassisnummer (* ved bestilling)"
                            tooltip="Chassisnummer på finansieringsobjektet. 9 tegn. Kreves for bestilling av forsikring uten regnummer."
                            fieldMessage={
                                props.currentInsuranceapplication?.chassisnumber != undefined &&
                                props.currentInsuranceapplication?.chassisnumber != "" &&
                                props.currentInsuranceapplication?.chassisnumber.length != 9
                                    ? "Ugyldig chassisnummer. Må være 9 tegn."
                                    : null
                            }
                        >
                            {inputProps => (
                                <Input
                                    type="text"
                                    name="chassisnumber"
                                    value={props.currentInsuranceapplication?.chassisnumber || ""}
                                    onChange={e => {
                                        props.setCurrentInsuranceapplication({
                                            ...props.currentInsuranceapplication || new InsuranceapplicationModel(),
                                            chassisnumber: e.target.value as any,
                                        });
                                    }}
                                    {...inputProps}
                                />
                            )}
                        </InputGroup>
                    {/* : null } */}
                    
                </>
            : null }
            { auth.currentInsuranceapplication?.category != "CAMPINGVOGN" ?
                <InputGroup
                    label="Kilometerstand"
                    tooltip="Antall kilometer som objektet har kjørt"
                    //fieldMessage={showErrors ? 'Ugyldig telefonnummer' : null}
                >
                    {inputProps => (
                        <InputWithUsability
                            useseparatorformatter
                            prefix="km"                            
                            type="number"
                            value={props.currentInsuranceapplication?.mileage || ""}
                            name="mileage"
                            min={0}
                            onChange={e => {
                                props.setCurrentInsuranceapplication({
                                    ...props.currentInsuranceapplication || new InsuranceapplicationModel(),
                                    mileage: e.target.value as any
                                });
                            }}
                            {...inputProps}
                        />
                    )}
                </InputGroup>
            : null }
        </ContentCard>
    );
}

interface InsuranceObjectCardProps {
    disabled: boolean;
    open: boolean;
    currentInsuranceapplication: InsuranceapplicationModel;
    setCurrentInsuranceapplication: (Insuranceapplication: InsuranceapplicationModel) => void;
    selectedAgreement: any;
    selectedDealer: any;
    foundVehicle: string;
    setFoundVehicle: (foundVehicle: string) => void;
}

export default InsuranceObjectCard;