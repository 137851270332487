import React from "react";
import ContentCard from "../../../../Shared/ContentCard/ContentCard";
import StatisticsCompetitionTable from "../../../../Shared/StatisticsCompetitionTable/StatisticsCompetitionTable";

function PointsTableDynamic(props: PointsTableDynamicProps) {
    return (
        <div>
            <ContentCard
                title={props.title}
                open={true}
                disableClick={true}
            >
                <StatisticsCompetitionTable
                    columns={props.columns}
                    data={props.data}
                />
            </ContentCard>
        </div>
    );
}

interface PointsTableDynamicProps {
    title: string;
    columns: Array<string>;
    data: any;
}

export default PointsTableDynamic