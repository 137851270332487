import React from "react";

import styles from './Progressbar.module.scss';

function Progressbar(props: ProgressbarProps) {
    return (
        <div className={styles.progressbar}>
            <div className={styles.bar}>
                <div className={styles.background}>
                    <div className={styles.minlabel}>
                        {props.data.minLabel}
                    </div>
                    <div className={styles.fill} style={{width: ((props.data.value / props.data.max) * 100) + "%"}}></div>
                    <div className={styles.valuemarker} style={{left: ((props.data.value / props.data.max) * 100) + "%"}}></div>
                    <div className={styles.label} style={{left: ((props.data.value / props.data.max) * 100) + "%", marginLeft: props.data.labeloffset + "px"}}>{props.data.label}</div>
                    {props.data.markers.map((marker, index) => {
                        return (
                            <div className={styles.marker} key={index} style={{left: ((marker.value / props.data.max) * 100) + "%"}}>
                                <div className={styles.markerline}></div>
                                <div className={styles.markerlabel} style={{marginLeft: marker.labeloffset + "px"}}>{marker.label}</div>
                            </div>
                        )
                    })}
                    <div className={styles.maxlabel}>
                        {props.data.maxLabel}
                    </div>
                </div>
            </div>
        </div>
    );
}

interface ProgressbarProps {
    data: {
        markers: Array<{
            value: number;
            label: string;
            labeloffset: number;
        }>;
        value: number;
        label: string;
        labeloffset: number;
        min: number;
        max: number;
        minLabel?: string;
        maxLabel?: string;
    }
}

export default Progressbar;