import React from "react";

import styles from './PopUp.module.scss';
import { useAuth } from "../../../Auth";

function PopUp() {
    const auth = useAuth();

    return (
        <div 
            className={styles.popupcontainer+ " " + (auth.infoDisplay != undefined ? "" : styles.hidden)}
        >
            <span
                onClick={() => {
                    auth.setInfoDisplay(undefined);
                }}
                className={"material-symbols-outlined" + " " + styles.closebtn}
            >close</span>
            <div className={styles.infocontainer}>
                <h2>{auth.infoDisplay?.title}</h2>
                <p>{auth.infoDisplay?.content}</p>
                <a
                    href={auth.infoDisplay?.linktarget}
                    target="_blank"
                    rel="noreferrer"
                >
                    {auth.infoDisplay?.linktext}
                </a>
            </div>
        </div>
    );
}

export default PopUp;