import React from 'react';
import { useAuth } from '../../../Auth';

import styles from './FullpageHelp.module.scss';

function FullpageHelp() {
    const auth = useAuth();

    return (
        <div
            onClick={(e) => {
                if ((e.target as HTMLElement).classList.contains(styles.fullpagehelp)) {
                    auth.setHelpDisplay(undefined);
                }
            }}
            className={styles.fullpagehelp + " " + (auth.helpDisplay != undefined ? "" : styles.hidden)}
        >
            <div className={styles.helppage}>
                <div className={styles.helppageheader}>
                    <h1>{auth.helpDisplay?.title}</h1>
                    <span
                        onClick={() => {
                            auth.setHelpDisplay(undefined);
                        }}
                        className={"material-symbols-outlined" + " " + styles.closebtn}
                    >close</span>
                    <hr/>
                </div>
                <div className={styles.helppagecontent} dangerouslySetInnerHTML={{ __html: auth.helpDisplay?.content }}>
                </div>
            </div>
        </div>
    );
}

export default FullpageHelp;
