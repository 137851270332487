import React from "react";

import styles from './FileList.module.scss';

function FileList(props: FileListProps) {
    return (
        <div className={styles.attachmentscontainer}>
            <h3 className={styles.attachmentheadline}>
                Vedlegg på saken
            </h3>
            {props.documents.map((item,index) => {
                return (
                    <div className={styles.attachments}
                        key= {index}
                        onClick={() => {
                            props.onDocumentClick?.(item);
                        }}
                    >
                        <span className="material-symbols-outlined">attachment</span>
                        <div>
                            <p>{item.name}</p>
                            <p>{item.datetime != undefined && item.datetime != "" ? new Intl.DateTimeFormat('nb-NO', {dateStyle: 'short', timeStyle:"short"}).format(new Date(item.datetime || '')) : ""}</p>
                        </div>
                    </div>
                )
            })}
        </div>
    );
}

class File{
    name!: string;
    value!: number;
    datetime?: string;
}
class FileListProps{
    documents!:Array<File>
    onDocumentClick?: (file:File) => void;
}

export {FileList,File};