const customerFunctions = {
    searchPerson: (toast: any, axios: any, auth: any, socialsecuritynum: string) => {
        return new Promise((resolve, reject) => {
            toast("Søker etter person ..", {
                autoClose: false
            });
            axios.single({
                url: "/api/persons/" + socialsecuritynum,
                method: "GET",
                headers: {
                    "Content-Type": "application/json",
                    "UserAuthorization": "Bearer " + (JSON.parse(localStorage.getItem("logindata") ?? "")["JWT"])
                }
            }).then((res: any) => {
                resolve(res.data);
                toast.dismiss();
            }).catch((err: any) => {
                toast.dismiss();
                console.log(err);
                toast.error("Søk på personnummer feilet! Vennligst prøv igjen");
                reject(err);
            });
        });
    },
    searchCompany: (toast: any, axios: any, auth: any, orgnum: string) => {
        return new Promise((resolve, reject) => {
            toast("Søker etter bedrift ..", {
                autoClose: false
            });
            axios.single({
                url: "/api/companies/" + orgnum,
                method: "GET",
                headers: {
                    "Content-Type": "application/json",
                    "UserAuthorization": "Bearer " + (JSON.parse(localStorage.getItem("logindata") ?? "")["JWT"])
                }
            }).then((res: any) => {
                resolve(res.data);
                toast.dismiss();
            }).catch((err: any) => {
                toast.dismiss();
                console.log(err);
                toast.error("Søk på organisasjonsnummer feilet! Vennligst prøv igjen");
                reject(err);
            });
        });
    }
};

export default customerFunctions;