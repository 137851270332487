import React, { useEffect, useState } from "react";

import { Tab, TabGroup } from '@sb1/ffe-tabs-react';
import Tooltip from '@mui/material/Tooltip';

import styles from './CustomerSegmentSelector.module.scss';

function CustomerSegmentSelector(props: CustomerSegmentSelectorProps) {
    const [selected1, setSelected1] = useState(props.selectedSegment || "");
    const [selected2, setSelected2] = useState(props.selectedType || "");

    let tooltipClasses = "disabledAgreementTooltip" + " " + localStorage.getItem("theme");

    useEffect(() => {
        if (props.segments?.length == 1 && props.types?.length != 1) {
            setSelected1(props.segments[0].value);
            props?.onChangeSegment?.(JSON.parse(JSON.stringify(props.segments[0].value)));
        }
        if (props.types?.length == 1 && props.segments?.length != 1) {
            setSelected2(props.types[0].value);
            props?.onChangeType?.(props.types[0].value);
        }
        if (props.types?.length == 1 && props.segments?.length == 1) {
            setSelected1(props.segments[0].value);
            setSelected2(props.types[0].value);
            props?.onChangeBoth?.(props.segments[0].value, props.types[0].value);
        }
    }, [props.segments, props.types]);

    useEffect(() => {
        if (props.selectedSegment) {
            setSelected1(props.selectedSegment);
        } else {
            setSelected1("");
        }
        if (props.selectedType) {
            setSelected2(props.selectedType);
        } else {
            setSelected2("");
        }
    }, [props.selectedSegment, props.selectedType]);

    return (
        <div className={styles.customersegmentselector}>
            <div className={styles.tabgroup}>
                <TabGroup>
                    { props.segments?.map((segment, index) => 
                        <Tooltip
                            disableHoverListener={props?.isPossibleAgreement?.(segment.value, selected2) || (props.segments?.length ?? 0) <= 1}
                            disableTouchListener={props?.isPossibleAgreement?.(segment.value, selected2) || (props.segments?.length ?? 0) <= 1}
                            leaveDelay={0}
                            TransitionProps={{ 
                                timeout: 0
                            }}
                            title={"Ingen avtale er mulig"}
                            classes={{ tooltip: tooltipClasses }}
                            key={index}
                        >
                            <div key={index}>
                                <Tab
                                    selected={selected1 === segment.value}
                                    onClick={() => {
                                        setSelected1(segment.value);
                                        props?.onChangeSegment?.(segment.value);
                                    }}
                                    aria-controls={segment.name}
                                    disabled={!props?.isPossibleAgreement?.(segment.value, selected2) && (props.segments?.length ?? 0) > 1}
                                >
                                    {segment.name}
                                </Tab>
                            </div>
                        </Tooltip>
                    )}
                </TabGroup>
            </div>
            <div className={styles.tabgroup}>
                <TabGroup>
                    { props.types?.map((type, index) =>
                        <Tooltip
                            disableHoverListener={props?.isPossibleAgreement?.(selected1, type.value) || (props.types?.length ?? 0) <= 1}
                            disableTouchListener={props?.isPossibleAgreement?.(selected1, type.value) || (props.types?.length ?? 0) <= 1}
                            leaveDelay={0}
                            TransitionProps={{ 
                                timeout: 0
                            }}
                            title={"Ingen avtale er mulig"}
                            placement="bottom"
                            classes={{ tooltip: tooltipClasses }}
                            key={index}
                        >
                            <div key={index}>
                                <Tab
                                    selected={selected2 === type.value}
                                    onClick={() => {
                                        setSelected2(type.value);
                                        props?.onChangeType?.(type.value);
                                    }}
                                    aria-controls={type.name}
                                    //disabled={!props?.isPossibleAgreement?.(selected1, (selected2 != undefined ? selected2 == "Lån" ? "Leasing" : "Lån" : undefined))}
                                    disabled={!props?.isPossibleAgreement?.(selected1, type.value) && (props.types?.length ?? 0) > 1}
                                >
                                    {type.name}
                                </Tab>
                            </div>
                        </Tooltip>
                    )}
                </TabGroup>
            </div>
        </div>
    );
}

class CustomerSegmentSelectorProps {
    segments?: Array<any>;
    types?: Array<any>;
    selectedSegment?: string;
    selectedType?: string;
    onChangeSegment?: Function = (segment: string) => { };
    onChangeType?: Function = (type: string) => { };
    onChangeBoth?: Function = (segment: string, type: string) => { };
    isPossibleAgreement?: Function = (segment: string, type: string) => { }; 
}

export default CustomerSegmentSelector;