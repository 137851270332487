import React from "react";
import { Tab, TabGroup } from '@sb1/ffe-tabs-react';
import styles from './TabSelector.module.scss';

function TabSelector(props: TabSelectorProps) {
    return (
        <div className={styles.tabSelector}>
            <TabGroup>
                { props.tabs?.map((type: any, index: number) => 
                    <div key={index}>
                        <Tab
                            selected={props.selected == type.value}
                            onClick={props.onClick ? () => props.onClick?.(type.value) : () => {}}
                            aria-controls={type.name}
                            disabled={props.isPossibleTab ? !props.isPossibleTab(type.value) : false}
                        >
                            {type.name}
                        </Tab>
                    </div>
                )}
            </TabGroup>
        </div>
    );
}

class TabSelectorProps {
    public tabs!: [{name: string, value: string}];
    public selected?: string;
    public onClick?: (value: string) => void;
    public isPossibleTab?: Function = (value: string) => { };
}

export default TabSelector;