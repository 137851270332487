import React from 'react';

import './App.module.scss'
import styles from './components/Pages/Login/Login.module.scss';
import DefaultLogo from './components/Shared/DefaultLogo/DefaultLogo';
import { Grid, GridCol, GridRow } from "@sb1/ffe-grid-react";
import { ActionButton } from '@sb1/ffe-buttons-react';
import { ReactComponent as UnknownError } from '../src/Assets/Images/unknown_error.svg';

const axios = require('axios').default;

class ErrorBoundary extends React.Component {
    constructor(props: any) {
        super(props);
        this.state = { hasError: false };
    }
  
    static getDerivedStateFromError(error: any) {
        return { hasError: true };
    }
  
    componentDidCatch(error: any, errorInfo: any) {
        console.log(error);
        console.log(errorInfo);

        let logindata = localStorage.getItem('logindata');
        if (logindata != null) {
            let login = JSON.parse(logindata);
            if (login.jwt != undefined) {
                axios.put('/api/logging', {
                    level: 'http',
                    message: error + " | " + window.location.href,
                }, {
                    headers: {
                        'Content-Type': 'application/json',
                        'UserAuthorization': 'Bearer ' + login.jwt
                    }
                });
            }
        }
    }

    reloadPage() {
        window.location.reload();
    }

        render() {
        if ((this.state as any).hasError) {
            return <div className={styles.login}>
            <div className={styles.logo}>
                <DefaultLogo /> 
            </div>
            <Grid className={styles.grid}>
                <GridRow>
                    <GridCol lg="6" md="6" sm="12">
                        <h1 className={styles.mobiletitle}>Oisann, her er det noe som ikke stemmer!</h1>
                        <div className={styles.mobileparagraph}>En rapport har blitt sendt til server.</div>
                        <div className={styles.welcomeimage}>
                            <UnknownError />
                        </div>
                    </GridCol>
                    <GridCol lg="6" md="6" sm="12">
                        <div className={`${styles.logincontainer} ${styles.errorcontainer}`}>
                            <h1 className={styles.desktoptitle}>Oisann, her er det noe som ikke stemmer!</h1>
                            <div className={styles.desktopparagraph}>En rapport har blitt sendt til server.</div>
                            <div className={styles.logintext}>
                                
                                <ActionButton
                                    tabIndex={3}
                                    onClick={() => {
                                        this.reloadPage()
                                    }}
                                >
                                    Last inn siden på nytt
                                </ActionButton>
                            </div>
                        </div>
                    </GridCol>
                </GridRow>
            </Grid>
        </div>
        }
  
        return (this.props as any).children; 
    }
}

export default ErrorBoundary;